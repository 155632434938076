import { SVGProps } from "react";

interface Props extends SVGProps<SVGSVGElement> {
  color?: string; // Optional color prop
  height?: string | number; // Optional height prop
  width?: string | number; // Optional width prop
}

function EditableIcon({
  color = "white",
  height = "18px",
  width = "18px",
  ...props
}: Props) {
  return (
    <svg
      {...props}
      height={height} // Use the height prop
      width={width} // Use the width prop
      viewBox="0 0 500 500"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M31.949,403.452c0,35.164,28.435,63.599,63.599,63.599c17.529,0,33.891-6.637,45.426-18.172l115.93-116.018l31.072,31.071c-4.182,15.265-0.181,32.345,11.905,44.518l45.063,45.063c17.985,17.901,46.782,18.08,64.504,0.362c17.719-17.721,17.539-46.511-0.366-64.504l-45.063-45.063c-12.174-12.085-29.254-16.078-44.514-11.898l-31.072-30.991l19.983-19.983c42.516,10.722,89.397-0.459,122.563-32.888c22.803-22.35,35.352-50.698,37.07-80.04v-1.543c0-9.91-8.181-17.905-18.26-17.905c-5.001,0-9.544,2.006-12.907,5.181l-5.353,5.271l-18.356,17.985c-11.543,11.268-27.984,17.896-45.701,17.896c-17.622,0-33.165-7.718-44.701-19.076c-11.355-11.533-19.166-27.167-19.166-44.788c0-17.717,6.63-34.159,17.897-45.702l17.985-18.358l5.269-5.351c3.18-3.363,5.181-7.906,5.181-12.91c0-10.079-7.992-18.26-17.904-18.26h-1.544c-29.34,1.721-57.687,14.268-80.038,37.071c-32.43,33.165-43.52,80.047-32.802,122.477l-20.069,20.069l-83.863-83.943v-22.988L59.205,76.376l-27.256,27.256l27.256,54.513h22.891l83.951,83.863L50.119,358.026C38.586,369.561,31.949,385.923,31.949,403.452z M77.375,398.909c0-12.536,10.177-22.711,22.714-22.711c12.538,0,22.713,10.175,22.713,22.711s-10.175,22.716-22.713,22.716C87.553,421.625,77.375,411.445,77.375,398.909z"
        fill={color} // Use the color prop
      />
    </svg>
  );
}

export default EditableIcon;
