import React from "react";

interface BudgetInputProps {
  name: string;
  value: number | undefined;
  onChange: (value: number | string, index: number) => void;
  onSave: () => void;
  index: number;
}

const BudgetInput: React.FC<BudgetInputProps> = ({
  name,
  value,
  onChange,
  onSave,
  index,
}) => (
  <div className="flex items-center rounded-lg border border-gray-600 py-1 shadow-md px-1">
    <input
      className="w-full border-none bg-transparent font-medium focus:border-transparent focus:outline-none focus:ring-0"
      name={name}
      value={value === undefined ? "" : value}
      type="number"
      onClick={(e) => e.stopPropagation()}
      onChange={(e) => onChange(e.target.value, index)}
    />
    <div>
      <button
        className="text-white bg-gray-800 px-2 py-1 rounded-lg"
        onClick={(e) => {
          e.stopPropagation();
          onSave();
        }}
      >
        Save
      </button>
    </div>
  </div>
);

export default BudgetInput;
