import { SVGProps } from "react";

interface Props extends SVGProps<SVGSVGElement> {
  color?: string; // Optional color prop
  height?: string | number; // Optional height prop
  width?: string | number; // Optional width prop
}

function EditIconImg({ width = 21, height = 20, color = "#CD335B" }: Props) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="10.9062" cy="10" r="10" fill={color} fillOpacity="0.12" />
      <path
        d="M7.11523 13.2499H9.4119C9.48319 13.2503 9.55385 13.2366 9.61985 13.2097C9.68585 13.1827 9.74587 13.143 9.79648 13.0928L13.5448 9.33905L15.0831 7.83322C15.1339 7.78286 15.1742 7.72296 15.2017 7.65695C15.2292 7.59094 15.2434 7.52014 15.2434 7.44864C15.2434 7.37713 15.2292 7.30633 15.2017 7.24032C15.1742 7.17432 15.1339 7.11441 15.0831 7.06405L12.7865 4.7403C12.7361 4.68953 12.6762 4.64924 12.6102 4.62174C12.5442 4.59424 12.4734 4.58008 12.4019 4.58008C12.3304 4.58008 12.2596 4.59424 12.1936 4.62174C12.1276 4.64924 12.0677 4.68953 12.0173 4.7403L10.4898 6.27322L6.73065 10.027C6.68045 10.0776 6.64073 10.1376 6.61377 10.2036C6.58682 10.2696 6.57316 10.3403 6.57357 10.4116V12.7082C6.57357 12.8519 6.63064 12.9897 6.73222 13.0912C6.8338 13.1928 6.97158 13.2499 7.11523 13.2499ZM12.4019 5.88864L13.9348 7.42155L13.1657 8.19072L11.6327 6.6578L12.4019 5.88864ZM7.6569 10.6336L10.869 7.42155L12.4019 8.95447L9.18982 12.1666H7.6569V10.6336ZM15.7819 14.3332H6.0319C5.88824 14.3332 5.75047 14.3903 5.64888 14.4919C5.5473 14.5935 5.49023 14.7312 5.49023 14.8749C5.49023 15.0185 5.5473 15.1563 5.64888 15.2579C5.75047 15.3595 5.88824 15.4166 6.0319 15.4166H15.7819C15.9256 15.4166 16.0633 15.3595 16.1649 15.2579C16.2665 15.1563 16.3236 15.0185 16.3236 14.8749C16.3236 14.7312 16.2665 14.5935 16.1649 14.4919C16.0633 14.3903 15.9256 14.3332 15.7819 14.3332Z"
        fill={color}
      />
    </svg>
  );
}

export default EditIconImg;
