import { jwtDecode } from "jwt-decode";

export const generateRandomNumber = () => {
  const array = new Uint32Array(1);
  const value = crypto.getRandomValues(array);
  const result = value[0] % 0.9;

  return result;
};

export const getUserName = (user: any, chars: number) => {
  const { userName } = user;

  if (userName === null || userName === undefined || userName === "") return "";

  if (userName?.length > chars) return `${userName.slice(0, chars)}...`;

  return `${userName}`;
};

export const jwtDecoder = (jwtToken: string) => {
  try {
    const tokenObject: any = jwtDecode(jwtToken);
    return tokenObject;
  } catch (error) {
    return "InvalidTokenError";
  }
};

export const storeUserSessionData = (accessToken: string, data: any) => {
  localStorage.setItem("accessToken", accessToken);
  localStorage.setItem("refreshToken", data?.refreshToken);
  const tokenObject: any = jwtDecoder(accessToken);
  localStorage.setItem("userName", tokenObject.email);
  localStorage.setItem("role", tokenObject.role);
  localStorage.setItem("tenantId", tokenObject.tenantId || "");
};
