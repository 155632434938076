type updateCurrentConditionTypes = {
  getSegmentOptionsByFilter: any;
  propsData: any;
  handleConditionChange: any;
};

export const updateCurrentConditionData = async (
  filterName: string,
  index: number,
  data: updateCurrentConditionTypes,
) => {
  const { getSegmentOptionsByFilter, handleConditionChange, propsData } = data;
  try {
    const filteredOptions = await getSegmentOptionsByFilter(
      filterName,
      propsData?.clientId,
      propsData.feedDays === null || propsData.feedDays === ""
        ? ""
        : propsData.feedDays,
    );
    handleConditionChange(
      "conditionList",
      filteredOptions?.data?.conditions,
      index,
    );
    handleConditionChange(
      "condition",
      filteredOptions?.data?.conditions?.[0]?.condition,
      index,
    );
    handleConditionChange(
      "conditionContentList",
      filteredOptions?.data?.possibleOptions,
      index,
    );
    handleConditionChange(
      "conditionContent",
      filteredOptions?.data?.possibleOptions?.[0],
      index,
    );
    handleConditionChange(
      "conditionLabel",
      {
        value: filteredOptions?.data?.conditions?.[0]?.condition,
        label: filteredOptions?.data?.conditions?.[0]?.conditionText,
      },
      index,
    );
  } catch (error: any) {
    console.log(error);
  }
};
