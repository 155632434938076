import { useState } from "react";

const useFormErrors = () => {
  const [errors, setErrors] = useState<Record<string, string[]>>({});

  const setFormErrors = (errorResponse: any) => {
    setErrors(errorResponse);
  };

  const removeFormError = (key: string) => {
    setErrors((currentErrors) => {
      const updatedErrors = { ...currentErrors };
      delete updatedErrors[key];
      return updatedErrors;
    });
  };

  return { errors, setFormErrors, removeFormError };
};

export default useFormErrors;
