import { ReactNode } from "react";

export const FormItem: React.FC<{
  children: ReactNode;
  error?: string;
  label: string;
}> = (Props) => {
  const { children, error, label } = Props;
  return (
    <div className="flex flex-col w-full">
      <label className="text-[#42526E] text-sm font-semibold mb-2">
        {label}
      </label>
      {children}
      <span className="text-red-500">{error}</span>
    </div>
  );
};
