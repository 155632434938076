import { useTranslation } from "react-i18next";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { useNavigate } from "react-router-dom";

import { routeNames } from "../../../router/routeNames";
import { Channels_Columns, channels_data } from "../../../services/channels";
import Table from "../../Table/Table";
import usePagination from "../../UsePagination/UsePagination";

import styles from "./ChannelsTable.module.css";

function ChannelsTable() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const recordsPerPage = 20;
  const {
    totPages,
    numbers,
    currentPage,
    prePage,
    nextPage,
    pageChange,
    lastIndex,
    firstIndex,
  } = usePagination(channels_data, recordsPerPage);

  return (
    <>
      <div className="flex flex-col sm:flex-row justify-between sm:mt-16">
        <h2 className="font-bold text-2xl text-black">{t("channels")}</h2>
      </div>
      <div className={styles.tableWrapper}>
        <Table
          columns={Channels_Columns}
          data={channels_data}
          onRowClick={(item: any) =>
            navigate(`${routeNames.CHANNELS}/${item.id}/channels`)
          }
          RowisClickable={true}
          order={true}
        />
      </div>

      <div className=" flex flex-col-reverse sm:flex-row justify-between  mb-3 items-center mt-4">
        <span className=" text-[#404040] text-sm font-medium">
          Showing {firstIndex === 0 ? firstIndex + 1 : firstIndex} to{" "}
          {lastIndex > channels_data?.length
            ? channels_data?.length
            : lastIndex}{" "}
          of {channels_data?.length} results
        </span>
        <nav className=" bg-white">
          <ul className="flex">
            <li
              className={
                "py-2 px-4 border border-[#D4D4D4] rounded-l-md cursor-pointer flex justify-center items-center"
              }
            >
              <button onClick={prePage}>
                <IoIosArrowBack />
              </button>
            </li>
            {numbers
              .map((item, index) => {
                if (
                  (item >= currentPage - 2 && item <= currentPage + 2) ||
                  item === 1 ||
                  item === totPages
                ) {
                  return (
                    <li key={`${"" + item}`}>
                      <button
                        className={`${
                          item === currentPage
                            ? "py-2 px-4 border border-[#CD335B] text-[#CD335B] bg-[#ff335b14] cursor-pointer"
                            : "py-2 px-4 border border-[#D4D4D4] cursor-pointer "
                        }`}
                        onClick={() => {
                          pageChange(item);
                        }}
                      >
                        {item}
                      </button>
                    </li>
                  );
                } else if (
                  (index === 0 && item > 2) ||
                  (index === numbers.length - 1 && item < totPages - 1)
                ) {
                  return (
                    <li
                      key={item}
                      className="py-2 px-4 border border-[#D4D4D4]"
                    >
                      ...
                    </li>
                  );
                }
                return null;
              })
              .slice(1)}

            <li className="py-1 border px-3 border-[#D4D4D4] rounded-r-md cursor-pointer flex justify-center items-center">
              <button onClick={nextPage}>
                <IoIosArrowForward />
              </button>
            </li>
          </ul>
        </nav>
      </div>
    </>
  );
}

export default ChannelsTable;
