import ActiveIcon from "./ActiveIcon";
import AddIcon from "./AddIcon";
import ArrowDownIcon from "./ArrowDownIcon";
import ArrowRightIcon from "./ArrowRightIcon";
import BoardsIcon from "./BoardsIcon";
import BudgetPlanningIcon from "./BudgetPlanningIcon";
import CampaignsIcon from "./CampaignsIcon";
import CircleIcon from "./CircleIcon";
import CirclePlusIcon from "./CirclePlusIcon";
import CloseIcon from "./CloseIcon";
import CloudIcon from "./CloudIcon";
import EditableIcon from "./EditableIcon";
import EditIcon from "./EditIcon";
import EditIconImg from "./EditIconImg";
import EllipseIcon from "./EllipseIcon";
import EyeCloseIcon from "./EyeCloseIcon";
import EyeOpenIcon from "./EyeOpenIcon";
import GoogleIcon from "./GoogleIcon";
import HomeIcon from "./HomeIcon";
import InActiveIcon from "./InActiveIcon";
import IndeedIcon from "./IndeedIcon";
import InfoIcon from "./InfoIcon";
import JobBoardsIcon from "./JobBoardsIcon";
import LinkApplyIcon from "./LinkApplyIcon";
import LinkedInIcon from "./LinkedInIcon";
import LinkIcon from "./LinkIcon";
import LockIcon from "./LockIcon";
import LogoutIcon from "./LogoutIcon";
import MenuIcon from "./MenuIcon";
import MetaIcon from "./MetaIcon";
import ModelCloseIcon from "./ModelCloseIcon";
import PauseIcon from "./PauseIcon";
import PlayIcon from "./PlayIcon";
import RefreshIcon from "./RefreshIcon";
import SearchIcon from "./SearchIcon";
import StatusActiveIcon from "./StatusActiveIcon";
import StatusUnactiveIcon from "./StatusUnActiveIcon";
import SyncVacanciesIcon from "./SyncVacanciesIcon";
import TrashIcon from "./TrashIcon";
import UpDownArrowIcon from "./UpDownArrowIcon";
import UserCircleIcon from "./UserCircleIcon";
import UserIcon from "./UserIcon";
import VacanciesIcon from "./VacanciesIcon";
import ViewIcon from "./ViewIcon";
import WarningIcon from "./WarningIcon";

const Icons = {
  AddIcon: AddIcon,
  CircleIcon: CircleIcon,
  CirclePlusIcon: CirclePlusIcon,
  EditIcon: EditIcon,
  EditableIcon: EditableIcon,
  LogoutIcon: LogoutIcon,
  RefreshIcon: RefreshIcon,
  SearchIcon: SearchIcon,
  TrashIcon: TrashIcon,
  UserCircleIcon: UserCircleIcon,
  UserIcon: UserIcon,
  BoardsIcon: BoardsIcon,
  GoogleIcon: GoogleIcon,
  IndeedIcon: IndeedIcon,
  LinkedInIcon: LinkedInIcon,
  MetaIcon: MetaIcon,
  BudgetPlanningIcon: BudgetPlanningIcon,
  CampaignsIcon: CampaignsIcon,
  JobBoardsIcon: JobBoardsIcon,
  MenuIcon: MenuIcon,
  SyncVacanciesIcon: SyncVacanciesIcon,
  VacanciesIcon: VacanciesIcon,
  CloudIcon: CloudIcon,
  InfoIcon: InfoIcon,
  ArrowDownIcon: ArrowDownIcon,
  HomeIcon: HomeIcon,
  LinkApplyIcon: LinkApplyIcon,
  LinkIcon: LinkIcon,
  EyeCloseIcon: EyeCloseIcon,
  EyeOpenIcon: EyeOpenIcon,
  LockIcon: LockIcon,
  StatusActiveIcon: StatusActiveIcon,
  StatusUnactiveIcon: StatusUnactiveIcon,
  WarningIcon: WarningIcon,
  ModelCloseIcon: ModelCloseIcon,
  UpDownArrowIcon: UpDownArrowIcon,
  PlayIcon: PlayIcon,
  PauseIcon: PauseIcon,
  ActiveIcon: ActiveIcon,
  InActiveIcon: InActiveIcon,
  CloseIcon: CloseIcon,
  EditIconImg: EditIconImg,
  ViewIcon: ViewIcon,
  EllipseIcon: EllipseIcon,
  ArrowRightIcon: ArrowRightIcon,
};

export default Icons;
