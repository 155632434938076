import { useNavigate } from "react-router-dom";

import Icons from "../../assets/icons/Icons";
import { Logos } from "../../assets/logos/Logos";
import { routeNames } from "../../router/routeNames";
import { getProperClicentName } from "../../services/common";

function ClientCard(props: any) {
  const { client } = props;
  const navigate = useNavigate();
  const defaultClientLogo = Logos.DefaultClientLogo;

  return (
    <button
      className="group  bg-white rounded-lg flex flex-col  h-[120px] w-72 justify-center px-6 relative cursor-pointer"
      style={{
        boxShadow:
          "0px 1px 2px 0px rgba(0, 0, 0, 0.06), 0px 1px 3px 0px rgba(0, 0, 0, 0.10)",
      }}
      onClick={() => navigate(`${routeNames.CLIENTDASHBOARD}/${client?.id}`)}
    >
      <div
        className={`transition-transform duration-500 group-hover:transform group-hover:-translate-y-6`}
      >
        <div className=" flex items-center gap-4">
          <img
            className="w-14 h-14 mb-3 rounded-full shadow"
            src={client?.imagePath || defaultClientLogo}
            alt={client?.name}
          />
          <span className="group/text text-base font-semibold text-[#171717]">
            {getProperClicentName(client?.name)?.slice(0, 50)}
            <div className="hidden group-hover/text:block absolute top-0 p-2 rounded bg-gray-900 backdrop-filter opacity-90 font-normal text-sm text-gray-50 mt-2 z-40">
              {client.name}
            </div>
          </span>
        </div>
      </div>
      <div className=" hidden absolute z-30 group-hover:flex justify-center w-full pt-2 bottom-4 bg-white  items-center gap-3 border-t border-[#E5E5E5] transition-opacity duration-300 left-0">
        <span className={`text-[#CD335B] text-sm font-medium`}>Open</span>
        <Icons.ArrowRightIcon />
      </div>
    </button>
  );
}

export default ClientCard;
