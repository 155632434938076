import { SVGProps } from "react";

interface SyncVacanciesIconProps extends SVGProps<SVGSVGElement> {
  color?: string;
  width?: string | number;
  height?: string | number;
}

function SyncVacanciesIcon({
  color = "currentColor",
  width = "21",
  height = "21",
  ...props
}: SyncVacanciesIconProps) {
  return (
    <svg
      {...props}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.4962 4.65756C19.6462 4.65756 19.7944 4.59943 19.905 4.48131L20.4806 3.87193C20.6944 3.64693 20.685 3.29068 20.4581 3.07693C20.2331 2.86318 19.8769 2.87256 19.6631 3.09943L19.0875 3.70881C18.8737 3.93381 18.8831 4.29006 19.11 4.50381C19.2187 4.60693 19.3575 4.65756 19.4962 4.65756Z"
        fill={color}
      />
      <path
        d="M19.155 9.40882C19.4794 10.2976 19.7231 11.1938 19.8825 12.0732C19.9313 12.3451 20.1675 12.5363 20.4356 12.5363C20.4694 12.5363 20.5031 12.5326 20.5369 12.5269C20.8425 12.4726 21.045 12.1801 20.9906 11.8744C20.8519 11.1076 20.6513 10.3313 20.3963 9.55695L22.9444 8.62882C23.2369 8.52195 23.3869 8.19945 23.28 7.90695C23.1731 7.61445 22.8506 7.46445 22.5581 7.57132C22.5581 7.57132 19.5038 8.6832 19.4925 8.68695C19.2 8.79382 19.05 9.11632 19.155 9.40882Z"
        fill={color}
      />
      <path
        d="M16.7138 8.1807C16.0331 7.86383 15.27 7.83008 14.5631 8.08695C14.5331 8.0982 14.505 8.11133 14.4769 8.12633C14.4469 8.13195 14.4169 8.13945 14.3869 8.1507C14.1057 8.25383 13.62 8.56508 13.5281 9.50445C13.4738 10.0557 13.5731 10.7138 13.8056 11.3551C14.2856 12.6713 15.1313 13.5038 15.9281 13.5038C16.0575 13.5038 16.1869 13.4813 16.3106 13.4363L16.4869 13.3726C17.1938 13.1157 17.7563 12.6001 18.0731 11.9176C18.39 11.2351 18.4238 10.4738 18.1669 9.76695C17.9119 9.06195 17.3944 8.49758 16.7138 8.1807Z"
        fill={color}
      />
      <path
        d="M23.1281 14.6813L19.4756 13.4176C19.1812 13.3163 18.8625 13.4719 18.7594 13.7644C18.6581 14.0588 18.8137 14.3776 19.1062 14.4807L20.0756 14.8163C19.98 16.5094 19.4287 17.6888 18.57 18.0019C17.7787 18.2888 16.7044 17.8463 15.6187 16.7851C14.4131 15.6076 13.3406 13.8338 12.5981 11.7938C11.8556 9.75381 11.5369 7.70631 11.7037 6.03006C11.8519 4.52068 12.3919 3.48943 13.1812 3.20068C14.5687 2.69631 16.6875 4.42318 18.2194 7.30506C18.3656 7.57881 18.7069 7.68381 18.9806 7.53756C19.2544 7.39131 19.3594 7.05193 19.2131 6.77631C17.3569 3.28318 14.7787 1.42131 12.7969 2.14131C11.9119 2.46381 11.2575 3.24943 10.8862 4.40256L3.70687 12.3769C2.99437 13.1663 2.78812 14.2351 3.15187 15.2326C3.51562 16.2319 4.36125 16.9163 5.41312 17.0644L6 17.1469V22.3126C6 23.1394 6.67312 23.8126 7.5 23.8126H11.25C12.0769 23.8126 12.75 23.1394 12.75 22.3126V19.5732C12.75 19.2619 12.4987 19.0107 12.1875 19.0107C11.8762 19.0107 11.625 19.2619 11.625 19.5732V22.3126C11.625 22.5188 11.4562 22.6876 11.25 22.6876H7.5C7.29375 22.6876 7.125 22.5188 7.125 22.3126V17.3044L16.0369 18.5532C16.7306 18.9901 17.415 19.2151 18.0581 19.2151C18.3675 19.2151 18.6675 19.1626 18.9562 19.0594C20.205 18.6057 20.9944 17.2126 21.1762 15.1969L22.7606 15.7463C22.8225 15.7669 22.8844 15.7782 22.9444 15.7782C23.1769 15.7782 23.3962 15.6319 23.475 15.3994C23.5781 15.1032 23.4225 14.7826 23.1281 14.6813ZM7.125 16.1682L6 16.0107V14.2501C6 13.9388 6.25125 13.6876 6.5625 13.6876C6.87375 13.6876 7.125 13.9388 7.125 14.2501V16.1682Z"
        fill={color}
      />
    </svg>
  );
}

export default SyncVacanciesIcon;
