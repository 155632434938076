import { SVGProps } from "react";

function LinkedInIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props} // Spread any additional props
    >
      <g clipPath="url(#clip0_597_9889)">
        <path
          d="M22.2235 0H1.77146C0.794129 0 0 0.774633 0 1.7298V22.2682C0 23.2242 0.794129 24 1.77146 24H22.2235C23.2025 24 24 23.2242 24 22.2682V1.7298C24 0.774633 23.2025 0 22.2235 0Z"
          fill="#0977B6"
        ></path>
        <path
          d="M5.33908 3.3042C6.47701 3.3042 7.4018 4.22919 7.4018 5.36753C7.4018 6.50689 6.47701 7.43199 5.33908 7.43199C4.19815 7.43199 3.27466 6.50689 3.27466 5.36753C3.27466 4.22919 4.19815 3.3042 5.33908 3.3042ZM3.5583 8.99776H7.11816V20.4512H3.5583V8.99776Z"
          fill="white"
        ></path>
        <path
          d="M9.35059 8.99751H12.7653V10.5624H12.8126C13.2875 9.66193 14.4487 8.7124 16.1806 8.7124C19.7852 8.7124 20.4506 11.0847 20.4506 14.1684V20.451H16.8934V14.8812C16.8934 13.5523 16.869 11.8439 15.0437 11.8439C13.1913 11.8439 12.9076 13.291 12.9076 14.785V20.451H9.35059L9.35059 8.99751Z"
          fill="white"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_597_9889">
          <rect width="24" height="24" fill="white"></rect>
        </clipPath>
      </defs>
    </svg>
  );
}

export default LinkedInIcon;
