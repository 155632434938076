import api from "./api";

export const VACANCIES_COLUMN = [
  {
    label: "Job Title",
    translationKey: "Job Title",
    accessor: (row: any) => row?.title,
  },
  {
    label: "Area",
    translationKey: "Area",
    accessor: (row: any) => row?.vacancyLoc?.country,
  },
  {
    label: "Location",
    translationKey: "Location",
    accessor: (row: any) => row?.vacancyLoc?.city,
  },
  {
    label: "Publication Date",
    translationKey: "Publication Date",
    accessor: (row: any) => row?.actual,
    type: "DateTime",
  },
  {
    label: "Functinal Group",
    translationKey: "Functinal Group",
    accessor: (row: any) => row?.group,
  },
];

export const getClientVacancies = async (
  id: any,
  PageNumber: number,
  PageSize: number,
) => {
  const response = await api.get(
    `v2/Vacancies?page=${PageNumber}&PageSize=${PageSize}&clientId=${id}`,
  );
  return response;
};

export const getCampaignVacancies = async (
  campaignId: string | number,
  pageNumber: any,
  pageSize: any,
) => {
  const PageNumber = pageNumber ? `&PageNumber=${pageNumber}` : "&PageNumber=1";
  const PageSize = pageSize ? `&PageSize=${pageSize}` : "&PageSize=25";
  const response = await api.get(
    `JobVacancy/GetByCampaignId?campaignId=${campaignId}${PageNumber}${PageSize}`,
  );
  return response;
};

export const InsertClientFeed = (clientId: string) => {
  const response = api.post(
    `JobVacancy/InsertClientFeed?clientId=${clientId}`,
    {},
  );
  return response;
};

export const syncRecruitNowPublications = (clientId: string) => {
  const response = api.post(`v2/Sync/${clientId}/RecruitNow/Publications`, {});
  return response;
};

export const syncIndeedVacancies = (clientId: string) => {
  const response = api.post(`v2/Sync/${clientId}/vacancies/indeed`, {});
  return response;
};

export const getFormatedVacanciesForTable = (data: any) => {
  const formatedData = data?.map((vacancy: any) => {
    return {
      ...vacancy,
      location: vacancy?.vacancyLocation?.[0]?.["city"],
      area: vacancy?.vacancyLocation?.[0]?.["country"],
    };
  });
  return formatedData;
};

export const getVacancyByIdV2 = async (
  clientId: string | number,
  vacancyId: string | number,
) => {
  const response: any = await api.get(
    `V2/Vacancies/${vacancyId}?clientId=${clientId}`,
  );
  return response;
};
