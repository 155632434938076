import { SVGProps } from "react";

interface Props extends SVGProps<SVGSVGElement> {
  color?: string; // Optional color prop
  height?: string | number; // Optional height prop
  width?: string | number; // Optional width prop
}

function ArrowRight({
  color = "#CD335B",
  height = "20",
  width = "20",
  ...props
}: Props) {
  return (
    <svg
      {...props}
      width={width} // Set width based on props
      height={height} // Set height based on props
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.1667 6.66667L17.5 10M17.5 10L14.1667 13.3333M17.5 10L2.5 10"
        stroke={color} // Use the color prop for stroke color
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default ArrowRight;
