import React from "react";

interface SvgProps {
  width?: string;
  height?: string;
  color?: string;
  className?: string;
  onClick?: () => void;
}

function PauseIcon({
  width = "16px",
  height,
  color = "red",
  className,
  onClick,
}: SvgProps) {
  return (
    <svg
      viewBox="0 0 512 512"
      xmlns="http://www.w3.org/2000/svg"
      fill={color}
      width={width}
      height={height}
      className={className}
      onClick={onClick}
    >
      <title>Pause</title>
      <path d="M224,432H144V80h80Z" />
      <path d="M368,432H288V80h80Z" />
    </svg>
  );
}

export default PauseIcon;
