import { ExportToCsv } from "export-to-csv";
import React, { useState, useEffect } from "react";
import { GrFormPrevious } from "react-icons/gr";
import { MdNavigateNext } from "react-icons/md";
import { useMutation, useQuery } from "react-query";
import { useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router";
import Select from "react-select";

import BudgetSpModal from "../../components/BudgetSpModal/BudgetSpModal";
import { openSnackbar } from "../../redux/snackbar";
import { routeNames } from "../../router/routeNames";
import { spIsHigherThanFCWarning } from "../../services/budget";
import {
  displayErrorSnackbar,
  displaySuccessSnackbar,
} from "../../services/common";
import {
  JOBBOARD_CAMPAIGN_COLUMNS,
  jobBoardById,
  updateJobBoardSpAmount,
  getClientJobBoardCampaigns,
} from "../../services/jobBoard";
import { Button } from "../Button/Button";
import Spinner from "../Spinner/Spinner";
import Table from "../Table/Table";

import styles from "./JobBoardCampaigns.module.css";

const JobBoardCampaigns = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [jobBoard, setJobBoard] = useState<any>([]);
  const [csvData, setCsvData] = useState<any>([]);
  const [csvDataSet, setCsvDataSet] = useState<boolean>(false);
  const currentDate = new Date();
  const currentMonthValue = currentDate.getMonth() + 1;
  const currentYearValue = currentDate.getFullYear();
  const currentMonthLabel = currentDate.toLocaleString("default", {
    month: "long",
  });
  const [currentMonth, setCurrentMonth] = useState<any>();
  const [currentYear, setCurrentYear] = useState<any>();

  const [openBudgetSpWarningModal, setOpenBudgetSpWarningModal] =
    useState<boolean>(false);
  const [actualSpendEnter, setActualSpendEnter] = useState<number>(0);

  useEffect(() => {
    setCurrentYear(currentYearValue);
  }, []);

  useEffect(() => {
    const selectdMonth = localStorage.getItem(`monthSaved${id}`);
    if (selectdMonth) {
      setCurrentMonth(JSON.parse(selectdMonth));
    } else {
      setCurrentMonth({
        value: currentMonthValue,
        label: currentMonthLabel,
      });
    }
  }, []);

  const [spWarning, setSpWarning] = useState<any>({
    info: "",
    channel: "",
    campaign: "",
    clientId: 0,
  });

  const [jobBoardCampaignsData, setJobBoardCampaignsData] = useState<any>([]);

  const {
    data: jobBoardCampaigns,
    isLoading,
    isError,
    refetch,
  } = useQuery(
    ["jobBoardCampaignss", id],
    () => getClientJobBoardCampaigns(id, currentMonth?.value, currentYear),
    {
      enabled: false,
      onSuccess(data: any) {
        setJobBoardCampaignsData(data?.data);
      },
    },
  );

  const handleChangeBudgetYear = (direction: string) => {
    if (direction === "next") {
      setCurrentYear(currentYear + 1);
    } else {
      setCurrentYear(currentYear - 1);
    }
  };

  useEffect(() => {
    setJobBoardCampaignsData([]);
    if (currentMonth && currentYear) {
      refetch();
    }
  }, [currentMonth, currentYear]);

  const {
    mutate,
    isLoading: loadingExMargin,
    error,
    data,
    reset,
  } = useMutation(
    (data: any) => updateJobBoardSpAmount(data.jobboardAmountId, data.amountSp),
    {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      onSuccess(data, variables, context) {
        refetch();
      },
    },
  );

  useEffect(() => {
    if (!loadingExMargin && error) {
      dispatch(
        openSnackbar({
          message: "Error while updating SP (EX MARGIN)",
          type: "error",
        }),
      );
      reset();
    }
  }, [error]);

  useEffect(() => {
    if (!isError && data) {
      dispatch(
        openSnackbar({
          message: "SP (EX MARGIN) Updated",
        }),
      );
      reset();
    }
  }, [data]);

  const { data: singleJobBoardData, error: jobBoardError } = useQuery(
    ["jobBoard", id],
    () => jobBoardById(id),
  );

  useEffect(() => {
    if (jobBoardError) {
      dispatch(
        openSnackbar({
          message: "Something went wrong!",
          type: "error",
        }),
      );
    }
  }, [jobBoardError]);

  useEffect(() => {
    if (singleJobBoardData) {
      setJobBoard(singleJobBoardData.data.contactPersons);
    }
  }, [singleJobBoardData]);

  const dummyFunction = () => {
    return 0;
  };

  useEffect(() => {
    dummyFunction();
  }, [jobBoard]);

  const { mutate: sendSpBudgetWarning } = useMutation(
    () =>
      spIsHigherThanFCWarning(
        spWarning?.info,
        spWarning?.clientId,
        spWarning?.channel,
        currentMonth?.value,
        currentYear,
        spWarning?.campaign,
      ),
    {
      onSuccess(data: any) {
        displaySuccessSnackbar(data?.data?.message);
        setSpWarning({
          info: "",
          clientId: spWarning?.clientId,
          channel: "",
          campaign: "",
        });
        setOpenBudgetSpWarningModal(false);
        setActualSpendEnter(0);
      },
    },
  );

  const handleCancelCall = () => {
    setSpWarning({
      info: "",
      channel: "",
      campaign: "",
      clientId: spWarning?.clientId,
    });
  };

  const handleSubmitCall = () => {
    sendSpBudgetWarning();
  };

  const options = {
    fieldSeparator: ",",
    filename: `JobBoard_${singleJobBoardData?.data?.name}`,
    quoteStrings: '"',
    decimalSeparator: ".",
    showLabels: true,
    showTitle: true,
    title: `JobBoardName : ${singleJobBoardData?.data?.name}`,
    useTextFile: false,
    useBom: true,
    useKeysAsHeaders: true,
  };
  const csvExporter = new ExportToCsv(options);
  useEffect(() => {
    if (isError) {
      dispatch(
        openSnackbar({
          message: "Something went wrong!",
          type: "error",
        }),
      );
    }
  }, [isError]);

  useEffect(() => {
    if (jobBoardCampaigns) {
      setJobBoard(jobBoardCampaigns.data);
    }
  }, [jobBoardCampaigns]);
  useEffect(() => {
    if (isError) {
      displayErrorSnackbar("Error loading jobBoard Campaigns");
    }
  }, [isError]);

  useEffect(() => {
    if (jobBoardCampaigns?.data) {
      const CSVRequiredData = jobBoardCampaigns?.data.map((item: any) => ({
        Client: item.clientName,
        Campaign: item.name,
        "Budget(Ex. Margin)": item.budgetMarginExcluded,
        "Vacancies Included": item.vacanciesCount,
        // Add more properties if needed
      }));
      setCsvData(CSVRequiredData);
      setCsvDataSet(true);
    }
  }, [jobBoardCampaigns?.data]);

  const handleExportCSVFile = () => {
    csvExporter.generateCsv(csvData);
  };
  const onSelectMonthChange = (e: any) => {
    localStorage.setItem(`monthSaved${id}`, JSON.stringify(e));
    setCurrentMonth(e);
  };

  const handleParentChange = (value: any, index: number) => {
    const updatedData = [...jobBoardCampaigns.data];
    updatedData[index].budgetSp = value;
    setJobBoardCampaignsData(updatedData);
  };

  const handleParentSaved = (item: any) => {
    if (parseFloat(item.budgetSp) > parseFloat(item.budgetMarginExcluded)) {
      setOpenBudgetSpWarningModal(true);
      setActualSpendEnter(item.budgetSp);
      setSpWarning({
        ...spWarning,
        clientId: item.clientID,
        channel: item.jobBoardName,
        campaign: item.name,
      });
    }
    mutate({
      jobboardAmountId: item.jobboardAmountId,
      amountSp: item.budgetSp,
    });
  };

  return (
    <div className=" my-12">
      <div className="flex justify-between items-center flex-wrap gap-4">
        <div className={`${styles.selectmonth} flex gap-4`}>
          <Select
            styles={{
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              control: (baseStyles, state) => ({
                ...baseStyles,
                borderRadius: "5px",
                minWidth: "282px",
                padding: "2.5px",
              }),
            }}
            theme={(theme) => ({
              ...theme,
              borderRadius: 0,
              colors: {
                ...theme.colors,
                primary25: "#CDCDCD",
                primary: "#CDCDCD",
              },
            })}
            value={{ value: currentMonth?.value, label: currentMonth?.label }}
            options={[
              { value: 1, label: "January" },
              { value: 2, label: "February" },
              { value: 3, label: "March" },
              { value: 4, label: "April" },
              { value: 5, label: "May" },
              { value: 6, label: "June" },
              { value: 7, label: "July" },
              { value: 8, label: "August" },
              { value: 9, label: "September" },
              { value: 10, label: "October" },
              { value: 11, label: "November" },
              { value: 12, label: "December" },
            ]}
            onChange={onSelectMonthChange}
            placeholder={"Select Month"}
          />
          <div className=" flex h-10 justify-between items-center border ml-4 px-5 rounded-2xl gap-3">
            <GrFormPrevious
              onClick={() => handleChangeBudgetYear("prev")}
              className=" w-5 h-6"
            />
            <span>{currentYear}</span>
            <MdNavigateNext
              onClick={() => handleChangeBudgetYear("next")}
              className=" w-5 h-6"
            />
          </div>
        </div>
        {csvDataSet ? (
          <Button
            color="secondary"
            onClick={handleExportCSVFile}
            disabled={jobBoardCampaigns.data.length === 0}
            size="medium"
          >
            Export to CSV
          </Button>
        ) : (
          <div className={styles.addFeedBtnWrapback}>
            <div>
              <span>Export File Not Ready yet</span>
            </div>
          </div>
        )}
      </div>
      {isLoading ? (
        <Spinner />
      ) : (
        !!jobBoardCampaigns?.data && (
          <Table
            columns={JOBBOARD_CAMPAIGN_COLUMNS}
            data={jobBoardCampaignsData || []}
            handleChange={handleParentChange}
            handleSaved={handleParentSaved}
            clickableItems={["vacanciesCount", "name"]}
            onItemClick={({ accessKey, item }: any) => {
              if (accessKey === "vacanciesCount") {
                // Handle navigation for vacanciesCount
                navigate(
                  `${routeNames.CLIENT}/${item.client.id2}/vacancies?campaignId=${item?.id}`,
                );
              } else if (accessKey === "name") {
                // Handle navigation for name
                navigate(
                  `${routeNames.CLIENT}/${item.client.id2}/campaigns/${item.id}`,
                );
              } else {
                // Handle other cases if needed
                return;
              }
            }}
            onCampaignClick={(campaign: any) => {
              navigate(
                `${routeNames.CLIENT}/${campaign.client.id2}?campaignId=${campaign.id}`,
              );
            }}
          />
        )
      )}

      {openBudgetSpWarningModal && (
        <BudgetSpModal
          isOpen={!!openBudgetSpWarningModal}
          onClose={() => {
            setOpenBudgetSpWarningModal(false);
            setActualSpendEnter(0);
          }}
          handleCancelCall={handleCancelCall}
          handleSubmitCall={handleSubmitCall}
          spWarning={spWarning}
          setSpWarning={setSpWarning}
          actualBudget={actualSpendEnter}
        />
      )}
    </div>
  );
};
export default JobBoardCampaigns;
