import { useEffect, useRef, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";

import Icons from "../../assets/icons/Icons";
import { Logos } from "../../assets/logos/Logos";
import { Button } from "../../components/Button/Button";
import CreateCampaign from "../../components/CreateCampaign/CreateCampaign";
import { setClientInfo } from "../../redux/clientCountInfo";
import { startLoading, stopLoading } from "../../redux/vacancieupdate";
import { routeNames } from "../../router/routeNames";
import { getClient } from "../../services/clients";
import {
  displaySuccessSnackbar,
  isContainsLongWord,
} from "../../services/common";
import { InsertClientFeed } from "../../services/vacancies";

const Sidebar = () => {
  const defaultClientLogo = Logos.DefaultClientLogo;
  const sidebarRef = useRef<any>();

  const { id } = useParams();
  if (id === undefined) throw Error("Invalid client");

  const clientId = +id;
  const location = useLocation();
  const navigate = useNavigate();

  const [openModal, setOpenModal] = useState<boolean>(false);

  const dispatch = useDispatch();

  const isLoadingVacancie = useSelector(
    (state: any) => state.vacancie.isLoading,
  );

  const { data: client, isLoading } = useQuery(
    ["client", clientId],
    () => getClient(clientId),
    {
      refetchOnWindowFocus: false,
      onSuccess: (resp) => {
        const { inCampaignCount, openCount, vacanciesCount } = resp.data;
        dispatch(
          setClientInfo({
            inCampaignCount,
            openCount,
            vacanciesCount,
          }),
        );
      },
    },
  );

  const { mutate } = useMutation(() => InsertClientFeed(clientId), {
    onSuccess(data) {
      displaySuccessSnackbar(data.data);
      dispatch(stopLoading());
    },
    onError(error) {
      console.log(error);
      dispatch(stopLoading());
    },
  });

  const sideBarItems = [
    {
      label: "Vacancies",
      to: `/client/${clientId}/vacancies`,
      icon: <Icons.VacanciesIcon color="white" />,
      number: client?.data?.vacanciesCount,
    },
    {
      label: "Campaigns",
      to: `${routeNames?.CLIENT}/${clientId}`,
      icon: <Icons.CampaignsIcon />,
      number: client?.data?.inCampaignCount,
    },
    {
      label: "Job Boards",
      to: `/client/${clientId}/client-jobboards`,
      icon: <Icons.JobBoardsIcon />,
    },
    {
      label: "Budget Planning",
      to: `${routeNames?.BUDGET_PLAN}/${clientId}`,
      icon: <Icons.BudgetPlanningIcon />,
    },
    {
      label: "Edit Client",
      to: `/${routeNames?.EDIT_CLIENT}/${clientId}`,
      icon: <Icons.EditIcon color="white" />,
    },
  ];

  const handleToggleSideBar = () => {
    sidebarRef.current.classList.toggle("hidden");
  };

  const handleOutsideClick = (event: MouseEvent) => {
    if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
      sidebarRef.current.classList.add("hidden");
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  return (
    <>
      <button
        className="md:hidden absolute top-20 left-5"
        onClick={handleToggleSideBar}
      >
        <Icons.MenuIcon />
      </button>
      <aside
        className={`sidebar hidden sm:block  w-64 md:shadow transform md:translate-x-0 transition-transform duration-150 ease-in bg-[#245ED2]
          absolute sm:fixed z-20 top-20 h-full overflow-y-auto`}
        ref={sidebarRef}
      >
        <button
          className=" flex gap-4 items-center pt-10 cursor-pointer"
          onClick={() => {
            navigate(`${routeNames.CLIENTDASHBOARD}/${clientId}`);
          }}
        >
          {!isLoading && (
            <>
              <div className=" w-16 h-16 bg-white rounded-full ml-6 flex justify-center items-center">
                <img
                  src={client?.data?.imagePath || defaultClientLogo}
                  alt="brand logo"
                  className=" w-full  rounded-full "
                />
              </div>
              <div className="group text-white text-sm font-medium">
                {client?.data?.name !== null &&
                isContainsLongWord(client?.data?.name)
                  ? client?.data?.name?.slice(0, 15) + "..."
                  : client?.data?.name?.slice(0, 50)}
                <div className="hidden group-hover:block absolute p-2 rounded bg-gray-900 backdrop-filter opacity-90 font-normal text-sm text-gray-50 mt-2 z-40">
                  {client?.data?.name}
                </div>
              </div>
            </>
          )}
        </button>
        <div className="sidebar-content py-6">
          <ul className="flex flex-col w-full">
            {sideBarItems.map((item, index) => {
              return (
                <li className="my-px mb-8" key={`${"" + index}`}>
                  <NavLink
                    to={item.to}
                    key={`${"" + index}`}
                    className={() =>
                      location.pathname == item.to
                        ? `flex flex-row items-center h-10 pl-6 bg-[#ffffff1a]`
                        : `flex flex-row items-center h-10 pl-6 hover:bg-[#ffffff1a]`
                    }
                  >
                    {item.icon}
                    <span className="ml-3 text-white text-sm font-normal">
                      {item.label}
                    </span>
                  </NavLink>
                </li>
              );
            })}
          </ul>
          <div className=" flex justify-center items-center flex-col gap-4">
            <Button
              color="secondary"
              onClick={() => setOpenModal(true)}
              PrefixIcon={<Icons.AddIcon />}
            >
              Create Campaign
            </Button>

            <Button
              color="tertiary"
              onClick={() => {
                dispatch(startLoading());
                mutate();
              }}
              loading={isLoadingVacancie}
              disabled={isLoadingVacancie}
              PrefixIcon={<Icons.SyncVacanciesIcon />}
            >
              Update Vacancies
            </Button>
          </div>
        </div>
      </aside>
      {openModal && (
        <CreateCampaign
          isOpen={openModal}
          onClose={() => setOpenModal(false)}
        />
      )}
    </>
  );
};

export default Sidebar;
