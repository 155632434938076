import { SVGProps } from "react";

interface WarningIconProps extends SVGProps<SVGSVGElement> {
  height?: string | number;
  width?: string | number;
  color?: string; // Add a color prop
}

function WarningIcon({
  height = "24px",
  width = "25px",
  color = "#DC2626",
  ...props
}: WarningIconProps) {
  return (
    <svg
      {...props}
      width={width} // Use the width prop
      height={height} // Use the height prop
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.5 9V11M12.5 15H12.51M5.57183 19H19.4282C20.9678 19 21.9301 17.3333 21.1603 16L14.2321 4C13.4623 2.66667 11.5378 2.66667 10.768 4L3.83978 16C3.06998 17.3333 4.03223 19 5.57183 19Z"
        stroke={color} // Use the color prop for stroke
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default WarningIcon;
