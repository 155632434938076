// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ChannelsDataTable_date__-aTC3 {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 12px;
  width: 200px;
  border: 1px solid gray;
  border-radius: 12px;
}

.ChannelsDataTable_selectmonth__kNFJ7 {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 12px;
}

.ChannelsDataTable_dateInput__NGcqB {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  outline: none;
  border: none;
}

.ChannelsDataTable_addFeedBtnWrap__aPBfx {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  text-align: right;
}
`, "",{"version":3,"sources":["webpack://./src/components/ChannelsDataTable/ChannelsDataTable.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,6BAA6B;EAC7B,mBAAmB;EACnB,aAAa;EACb,YAAY;EACZ,sBAAsB;EACtB,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,6BAA6B;EAC7B,mBAAmB;EACnB,aAAa;AACf;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,yBAAyB;EACzB,mBAAmB;EACnB,aAAa;EACb,YAAY;AACd;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,yBAAyB;EACzB,iBAAiB;AACnB","sourcesContent":[".date {\n  display: flex;\n  justify-content: space-around;\n  align-items: center;\n  padding: 12px;\n  width: 200px;\n  border: 1px solid gray;\n  border-radius: 12px;\n}\n\n.selectmonth {\n  display: flex;\n  justify-content: space-around;\n  align-items: center;\n  padding: 12px;\n}\n\n.dateInput {\n  display: flex;\n  flex-direction: row;\n  justify-content: flex-end;\n  align-items: center;\n  outline: none;\n  border: none;\n}\n\n.addFeedBtnWrap {\n  display: flex;\n  flex-direction: row;\n  justify-content: flex-end;\n  text-align: right;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"date": `ChannelsDataTable_date__-aTC3`,
	"selectmonth": `ChannelsDataTable_selectmonth__kNFJ7`,
	"dateInput": `ChannelsDataTable_dateInput__NGcqB`,
	"addFeedBtnWrap": `ChannelsDataTable_addFeedBtnWrap__aPBfx`
};
export default ___CSS_LOADER_EXPORT___;
