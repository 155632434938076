import { useEffect, useState } from "react";
import { useMutation } from "react-query";
import { NavLink } from "react-router-dom";

import Icons from "../../assets/icons/Icons";
import { Button } from "../../components/Button/Button";
import { AuthInput } from "../../components/FormFields/FormFields";
import { useFieldError } from "../../hooks/useFieldError";
import useFormErrors from "../../hooks/useFormErrors";
import { routeNames } from "../../router/routeNames";
import { forgotPassword } from "../../services/auth";
import {
  displayErrorSnackbar,
  displaySuccessSnackbar,
} from "../../services/common";

const ForgotPassword = () => {
  const [email, setEmail] = useState<string>("");
  const [isEmailSent, setIsEmailSent] = useState<boolean>(false);
  const { removeFormError, setFormErrors } = useFormErrors();

  const { mutate, isLoading, data, error } = useMutation(
    (data: any) => forgotPassword(data),
    {
      onError(error: any) {
        if (error.response.status === 404) {
          displayErrorSnackbar(error?.response?.data);
        } else setFormErrors(error.response?.data);
      },
    },
  );

  useEffect(() => {
    if (!isLoading && data) {
      setIsEmailSent(true);
      displaySuccessSnackbar(
        "Password reset email has been sent successfully.",
      );
    }
  }, [data]);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    mutate({ email });
  };

  const { getFieldError } = useFieldError(error);

  return (
    <div className=" flex justify-center items-center h-[70vh]">
      {isEmailSent ? (
        <div className="my-[10rem] text-[1.2rem] font-bold text-gray-900">
          <span className="">
            Password reset email has been sent successfully. Please check your
            email!
          </span>
        </div>
      ) : (
        <form
          className=" flex flex-col gap-y-4 mt-5 max-w-md"
          onSubmit={handleSubmit}
        >
          <h2 className="text-3xl font-bold tracking-wide flex justify-center">
            Reset Password
          </h2>
          <AuthInput
            icon={<Icons.UserCircleIcon width={23} height={23} color="black" />}
            name="email"
            handleChange={(e: React.FormEvent<HTMLInputElement>) => {
              removeFormError("email");
              setEmail(e.currentTarget.value);
            }}
            placeholder="Email"
            type="email"
            value={email}
            error={getFieldError("email")}
          />
          <div className="flex justify-end">
            <NavLink to={routeNames.LOGIN} className="text-blue-600 underline ">
              Login?
            </NavLink>
          </div>

          <Button
            type="submit"
            color="primary"
            fullWidth
            size="large"
            disabled={isLoading}
            loading={isLoading}
          >
            Reset Password
          </Button>
        </form>
      )}
    </div>
  );
};

export default ForgotPassword;
