import { SVGProps } from "react";

interface CampaignsIconProps extends SVGProps<SVGSVGElement> {
  width?: number; // Optional width prop
  height?: number; // Optional height prop
  color?: string; // Optional color prop
}

function CampaignsIcon({
  width = 25,
  height = 25,
  color = "white",
  ...props
}: CampaignsIconProps) {
  return (
    <svg
      {...props}
      width={width} // Use the width prop or default to 25
      height={height} // Use the height prop or default to 24
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.19935 7.40182H6.81888L17.6913 2.50276C18.1148 2.27964 18.6366 2.58302 18.6366 3.07304V20.3393C18.6298 20.7993 18.1603 21.1207 17.7278 20.9259L6.81916 16.0105H6.30781V19.8358C6.30781 20.8352 5.50094 21.5717 4.51697 21.5717C3.56388 21.5717 2.78114 20.7892 2.78114 19.8358V16.0105H2.19935C1.84348 16.0105 1.55469 15.7218 1.55469 15.3659V8.04648C1.55469 7.69061 1.84348 7.40182 2.19935 7.40182ZM22.9227 15.9073C23.5219 16.5065 22.6103 17.418 22.0111 16.8188L20.0135 14.8211C19.4143 14.2219 20.3258 13.3104 20.925 13.9096L22.9227 15.9073ZM20.925 9.50222C20.3258 10.1014 19.4143 9.18985 20.0135 8.59066L22.0111 6.59298C22.6103 5.99407 23.5219 6.90535 22.9227 7.50454L20.925 9.50222ZM20.0556 12.3506C19.2077 12.3506 19.2077 11.0615 20.0556 11.0615H22.8809C23.6159 11.0615 23.9291 12.3405 22.8809 12.3506H20.0556ZM5.0185 16.0105H4.07017V19.8358C4.07017 20.0834 4.26916 20.2826 4.51697 20.2826C4.79341 20.2826 5.0185 20.1297 5.0185 19.8358V16.0105ZM7.53398 8.49075V14.9213L17.3475 19.343V4.06908L7.53398 8.49075ZM6.24495 8.69086H2.844V14.7212H6.24495V8.69086Z"
        fill={color} // Use the color prop for fill
      />
    </svg>
  );
}

export default CampaignsIcon;
