import { SVGProps } from "react";

interface InfoIconProps extends SVGProps<SVGSVGElement> {
  color?: string; // Optional color prop
  height?: string | number; // Height can be string or number
  width?: string | number; // Width can be string or number
}

function InfoIcon({
  color = "#DC2626",
  height = "40",
  width = "40",
  ...props
}: InfoIconProps) {
  return (
    <svg
      {...props}
      height={height} // Use the height prop
      width={width} // Use the width prop
      viewBox="0 0 40 40"
      fill="none"
    >
      <circle cx="20" cy="20" r="20" fill="#FEE2E2" />
      <path
        d="M18.5 13.25C18.5 13.6478 18.658 14.0294 18.9393 14.3107C19.2206 14.592 19.6022 14.75 20 14.75C20.3978 14.75 20.7794 14.592 21.0607 14.3107C21.342 14.0294 21.5 13.6478 21.5 13.25C21.5 12.8522 21.342 12.4706 21.0607 12.1893C20.7794 11.908 20.3978 11.75 20 11.75C19.6022 11.75 19.2206 11.908 18.9393 12.1893C18.658 12.4706 18.5 12.8522 18.5 13.25ZM20.75 17.1875H19.25C19.1469 17.1875 19.0625 17.2719 19.0625 17.375V28.25C19.0625 28.3531 19.1469 28.4375 19.25 28.4375H20.75C20.8531 28.4375 20.9375 28.3531 20.9375 28.25V17.375C20.9375 17.2719 20.8531 17.1875 20.75 17.1875Z"
        fill={color} // Use the color prop
      />
    </svg>
  );
}

export default InfoIcon;
