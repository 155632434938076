import { SVGProps } from "react";

interface ViewIconProps extends SVGProps<SVGSVGElement> {
  width?: number | string; // Optional width prop
  height?: number | string; // Optional height prop
  color?: string; // Optional color prop
}

function ViewIcon({
  width = 21,
  height = 20,
  color = "#CD335B",
  ...props // Spread additional props
}: ViewIconProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 21 20"
      fill={color} // You can use color here if needed
      xmlns="http://www.w3.org/2000/svg"
      {...props} // Spread any additional props
    >
      <circle cx="10.9062" cy="10" r="10" fill={color} fillOpacity="0.12" />
      <path
        d="M16.2802 9.78341C15.186 7.243 13.1277 5.66675 10.9068 5.66675C8.686 5.66675 6.62767 7.243 5.5335 9.78341C5.50368 9.85176 5.48828 9.92552 5.48828 10.0001C5.48828 10.0746 5.50368 10.1484 5.5335 10.2167C6.62767 12.7572 8.686 14.3334 10.9068 14.3334C13.1277 14.3334 15.186 12.7572 16.2802 10.2167C16.31 10.1484 16.3254 10.0746 16.3254 10.0001C16.3254 9.92552 16.31 9.85176 16.2802 9.78341ZM10.9068 13.2501C9.18434 13.2501 7.56475 12.0097 6.62767 10.0001C7.56475 7.9905 9.18434 6.75008 10.9068 6.75008C12.6293 6.75008 14.2489 7.9905 15.186 10.0001C14.2489 12.0097 12.6293 13.2501 10.9068 13.2501ZM10.9068 7.83341C10.4783 7.83341 10.0594 7.96049 9.7031 8.19856C9.34679 8.43664 9.06909 8.77503 8.9051 9.17093C8.74111 9.56684 8.6982 10.0025 8.7818 10.4228C8.8654 10.8431 9.07176 11.2291 9.37477 11.5321C9.67778 11.8352 10.0638 12.0415 10.4841 12.1251C10.9044 12.2087 11.3401 12.1658 11.736 12.0018C12.1319 11.8378 12.4703 11.5601 12.7084 11.2038C12.9464 10.8475 13.0735 10.4286 13.0735 10.0001C13.0735 9.42545 12.8452 8.87435 12.4389 8.46802C12.0326 8.06169 11.4815 7.83341 10.9068 7.83341ZM10.9068 11.0834C10.6926 11.0834 10.4831 11.0199 10.305 10.9008C10.1268 10.7818 9.98796 10.6126 9.90597 10.4147C9.82397 10.2167 9.80252 9.99888 9.84432 9.78873C9.88612 9.57859 9.9893 9.38556 10.1408 9.23405C10.2923 9.08254 10.4853 8.97936 10.6955 8.93756C10.9056 8.89576 11.1235 8.91722 11.3214 8.99921C11.5194 9.08121 11.6886 9.22006 11.8076 9.39821C11.9266 9.57637 11.9902 9.78582 11.9902 10.0001C11.9902 10.2874 11.876 10.5629 11.6729 10.7661C11.4697 10.9693 11.1942 11.0834 10.9068 11.0834Z"
        fill={color}
      />
    </svg>
  );
}

export default ViewIcon;
