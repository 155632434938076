// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.VacancyDetails_richText__zP-Sa ul {

    margin-left: 1rem;

    list-style-position: inside;

    list-style-type: disc
}

.VacancyDetails_richText__zP-Sa li {

    line-height: 1.5rem
}
`, "",{"version":3,"sources":["webpack://./src/components/VacanyDetail/VacancyDetails.module.css"],"names":[],"mappings":"AACE;;IAAA,iBAAiC;;IAAjC,2BAAiC;;IAAjC;AAAiC;;AAIjC;;IAAA;AAAgB","sourcesContent":[".richText ul {\n  @apply list-disc list-inside ml-4;\n}\n\n.richText li {\n  @apply leading-6;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"richText": `VacancyDetails_richText__zP-Sa`
};
export default ___CSS_LOADER_EXPORT___;
