import { AxiosError } from "axios";
import { FC, ReactElement, ReactNode } from "react";
import { useQuery, UseQueryResult } from "react-query";

import { fetchTenantId } from "../../services/tenant";
import { GetTenantsResponse } from "../../types/client/response/GetTenantsResponse";

import { TenantContext } from "./TenantContext";

interface TenantProviderProps {
  children: ReactNode;
  role: string | null;
}

export const TenantProvider: FC<TenantProviderProps> = ({
  children,
  role,
}): ReactElement<TenantProviderProps> => {
  const {
    data: tenants,
    isLoading: tenantsLoading,
    isFetching: tenantsFetching,
  }: UseQueryResult<GetTenantsResponse, AxiosError> = useQuery({
    queryKey: ["tenants"],
    queryFn: () => fetchTenantId,
    refetchOnReconnect: false,
    staleTime: 20 * 60 * 1000,
    enabled: role === "SuperAdmin",
  });

  return (
    <TenantContext.Provider
      value={{
        tenants,
        tenantsFetching,
        tenantsLoading,
      }}
    >
      {children}
    </TenantContext.Provider>
  );
};
