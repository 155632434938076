import { SVGProps } from "react";

interface UserIconProps extends SVGProps<SVGSVGElement> {
  height?: string | number;
  width?: string | number;
  color?: string; // Add a color prop
}

function UserIcon({
  height = "20px",
  width = "20px",
  color = "grey",
  ...props
}: UserIconProps) {
  return (
    <svg
      {...props}
      width={width} // Use the width prop
      height={height} // Use the height prop
      fill={color} // Use the color prop for the fill
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12" cy="8" r="4" fill={color} />{" "}
      {/* Use color for the circle */}
      <path
        d="M12,14c-6.1,0-8,4-8,4v2h16v-2C20,18,18.1,14,12,14z"
        fill={color}
      />{" "}
      {/* Use color for the path */}
    </svg>
  );
}

export default UserIcon;
