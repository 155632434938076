import { SVGProps } from "react";

interface SearchIconProps extends SVGProps<SVGSVGElement> {
  height?: string | number;
  width?: string | number;
  color?: string; // Add a color prop
}

function SearchIcon({
  height = "16",
  width = "16",
  color = "currentColor",
  ...props
}: SearchIconProps) {
  return (
    <svg
      {...props}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 4a6 6 0 1 0 0 12 6 6 0 0 0 0-12zm-8 6a8 8 0 1 1 14.32 4.906l5.387 5.387a1 1 0 0 1-1.414 1.414l-5.387-5.387A8 8 0 0 1 2 10z"
        fill={color} // Use the color prop for the fill
      />
    </svg>
  );
}

export default SearchIcon;
