import { useCallback } from "react";
import { useTranslation } from "react-i18next";

type ValidationResponse = {
  propertyName: string;
  errorMessage: string;
  formattedMessagePlaceholderValues?: Record<string, string | number>;
};

export const useFieldError = (mutateErrorResponse: any) => {
  const { t } = useTranslation();

  const formatFieldName = (field: string) => {
    return field
      .replace(/([a-z])([A-Z])/g, "$1 $2")
      .replace(/_/g, " ")
      .replace(/\b\w/g, (char) => char.toUpperCase());
  };

  const getFieldError = useCallback(
    (field: string) => {
      const errorData = mutateErrorResponse?.response?.data;
      if (!errorData || errorData?.code === "INVALID_CREDENTIALS") {
        return undefined;
      }

      const error = (errorData as ValidationResponse[]).find(
        (e) => e.propertyName === field,
      );

      if (!error) return undefined;

      const errorKey = error.errorMessage.toLowerCase().replace(/\s+/g, "_");

      const fieldName = formatFieldName(field);

      const translationValues: Record<string, string | number> = {
        fieldName,
        ...(error.formattedMessagePlaceholderValues || {}),
      };

      return t(`errors.${errorKey}`, translationValues);
    },
    [mutateErrorResponse, t],
  );

  return { getFieldError };
};
