import { useState } from "react";
import Modal from "react-modal";
import { useMutation, useQuery } from "react-query";

import { cloneMonth, getCloneMonths, MONTH_NAMES } from "../../services/budget";
import {
  displayErrorSnackbar,
  displaySuccessSnackbar,
} from "../../services/common";
import { Button } from "../Button/Button";
import Spinner from "../Spinner/Spinner";

import styles from "./CloneMonthsModal.module.css";

type cloneParamsType = {
  year: number;
  month: number;
  clientId: any;
};

type CloneMonthsModalProps = {
  cloneParams: cloneParamsType;
  onClose?: any;
  onCloneComplete?: any;
  isOpen: boolean;
};

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    maxheight: "80vh",
  },
};

function CloneMonthsModal(props: Readonly<CloneMonthsModalProps>) {
  const { cloneParams, onClose, onCloneComplete, isOpen } = props;

  const [cloneMonths, setCloneMonths] = useState<any>();
  const [selectedMonth, setSelectedMonth] = useState<any>({});

  const { isLoading } = useQuery(
    ["cloneMonths", cloneParams],
    () =>
      getCloneMonths(
        cloneParams?.year,
        cloneParams?.month,
        cloneParams?.clientId,
      ),
    {
      onSuccess(data) {
        const monthsWithData: any = [];
        data?.data?.forEach((month: any) => {
          if (month?.pl) {
            monthsWithData?.push(month);
          }
        });
        setCloneMonths(monthsWithData);
      },
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      onError(err) {
        displayErrorSnackbar("Error getting clone months");
      },
    },
  );

  const { mutate, isLoading: cloningMonth } = useMutation(
    (BudgetPlanningId: number) =>
      cloneMonth(cloneParams?.year, cloneParams?.month, BudgetPlanningId),
    {
      onSuccess() {
        displaySuccessSnackbar("Month cloned successfully");
        onCloneComplete({ year: cloneParams?.year, month: cloneParams?.month });
      },
    },
  );

  const handleClone = () => {
    if (selectedMonth?.id) {
      mutate(selectedMonth?.id);
    }
  };

  return (
    <Modal
      style={customStyles}
      isOpen={isOpen}
      className={styles.afterOpen}
      ariaHideApp={false}
    >
      <section className={styles.modalContent}>
        <h3 className="text-sm font-semibold leading-6 text-[#000]">
          Clone To Month
        </h3>

        <div className="mt-2">
          <p className="text-xs font-normal text-[#999CA0] font-plus-jakarta">
            Select one months to paste values.
          </p>
        </div>

        <div className="mt-6">
          <h2 className="text-sm font-semibold leading-6 text-[#000]">From</h2>
        </div>

        {isLoading && (
          <div className={styles.spinnerWrap}>
            <Spinner size={"50px"} />
          </div>
        )}

        {!isLoading && cloneMonths?.length ? (
          <div className={`${styles.contentContainer} `}>
            {cloneMonths?.map((data: any) => {
              return (
                <Button
                  key={data.month}
                  color={
                    selectedMonth?.id === data?.id ? "primary" : `tertiary`
                  }
                  onClick={() => setSelectedMonth(data)}
                >
                  <span className="text-white">
                    {MONTH_NAMES?.[`${data?.month}`]} {data?.year}
                  </span>
                </Button>
              );
            })}
          </div>
        ) : (
          <p>No prev months data available</p>
        )}

        <div className={styles.footer}>
          <Button color="tertiary" variant="outline" onClick={onClose}>
            Cancel
          </Button>

          <Button
            color="primary"
            onClick={handleClone}
            loading={cloningMonth}
            disabled={!selectedMonth?.id}
          >
            {`Clone ${MONTH_NAMES[selectedMonth?.month] || ""}`}
          </Button>
        </div>
      </section>
    </Modal>
  );
}

export default CloneMonthsModal;
