import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "react-query";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";

import Icons from "../../assets/icons/Icons";
import { Button } from "../../components/Button/Button";
import NewMappingVacancyModal from "../../components/MappingVacanciesModal/MappingVacanciesModal";
import Pagination from "../../components/Pagination/Pagination";
import Spinner from "../../components/Spinner/Spinner";
import Tooltip from "../../components/Tooltip/Tooltip";
import { displaySuccessSnackbar } from "../../services/common";
import {
  getFieldPositionMapping,
  getFieldTypeWithMapping,
  updateFieldPositionMapping,
} from "../../services/mappings";

import styles from "./VacancyMapping.module.css";

function VacancyMapping() {
  const { t } = useTranslation();
  const [mappingData, setMappingData] = useState<any>([]);
  const [pageDetails, setPageDetails] = useState<any>({
    pageNumber: 1,
    pageSize: 25,
    fieldName: "",
  });
  const [pageStart, setPageStart] = useState<number>(0);
  const [categoryMappings, setCategoryMappings] = useState<any>([]);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [currentMappingId, setCurrentMappingId] = useState<any>("");
  const [selectedRow, setSelectedRow] = useState<any>(null);
  const [rowClicked, setRowClicked] = useState<boolean>(false);
  const [searchIdInput, setSearchIdInput] = useState<string>("");
  const [searchTextFiledInput, setSearchTextFiledInput] = useState<string>("");
  const [filterMapping, setFilterMapping] = useState([]);

  const { data, isLoading, refetch } = useQuery(
    ["mappings", pageDetails?.pageNumber, pageDetails?.pageSize],
    () =>
      getFieldPositionMapping(pageDetails?.pageNumber, pageDetails?.pageSize),
    {
      enabled: false,
    },
  );

  const { data: fieldMappingData } = useQuery("fieldMappings", () =>
    getFieldTypeWithMapping(),
  );

  const {
    mutate: UpdateMapping,
    data: UpdateResponse,
    isLoading: Updating,
    reset: ResetUpdateResponse,
  } = useMutation((data: any) => updateFieldPositionMapping(data));

  const filterClients = () => {
    const idFilterdData = mappingData.filter((data: any) =>
      parseInt(data.id).toString().includes(searchIdInput),
    );

    const filedNameFilteredData = idFilterdData.filter((data: any) =>
      data.fieldName.includes(searchTextFiledInput),
    );

    setFilterMapping(filedNameFilteredData);
  };

  useEffect(() => {
    setPageDetails({
      pageNumber: 1,
      pageSize: 25,
      fieldName: "",
    });
    filterClients();
  }, [searchIdInput, searchTextFiledInput]);

  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    refetch();
  }, [pageDetails]);

  useEffect(() => {
    if (data?.data) {
      setMappingData(
        data?.data?.data?.map((record: any) => {
          const fieldMappingList = record?.fieldMappingList?.map(
            (fieldMap: any) => {
              return {
                value: fieldMap?.position,
                label: fieldMap?.position,
              };
            },
          );
          return {
            ...record,
            fieldMappingList,
            currentMappingList: fieldMappingList,
            fieldMapping: record?.fieldMapping || fieldMappingList?.[0]?.value,
            isFieldSaved: !!record?.fieldMapping,
            positionMapping: record?.positionMapping || record?.positionName,
            isPositionSaved: !!record?.positionMapping,
          };
        }),
      );
      setFilterMapping(
        data?.data?.data?.map((record: any) => {
          const fieldMappingList = record?.fieldMappingList?.map(
            (fieldMap: any) => {
              return {
                value: fieldMap?.position,
                label: fieldMap?.position,
              };
            },
          );
          return {
            ...record,
            fieldMappingList,
            currentMappingList: fieldMappingList,
            fieldMapping: record?.fieldMapping || fieldMappingList?.[0]?.value,
            isFieldSaved: !!record?.fieldMapping,
            positionMapping: record?.positionMapping || record?.positionName,
            isPositionSaved: !!record?.positionMapping,
          };
        }),
      );
      setPageStart(
        data?.data?.pageNumber * data?.data?.pageSize - data?.data?.pageSize,
      );
    }
  }, [data]);

  useEffect(() => {
    if (fieldMappingData) {
      setCategoryMappings(
        fieldMappingData?.data?.map((data: any) => {
          return {
            ...data,
            value: data?.fieldType,
            label: data?.fieldType,
          };
        }),
      );
    }
  }, [fieldMappingData]);

  useEffect(() => {
    if (UpdateResponse) {
      displaySuccessSnackbar("Mapping updated successfully");
      ResetUpdateResponse();
      refetch();
    }
  }, [UpdateResponse]);

  const updateMappedValue = (key: string, value: any, index: number) => {
    const prevData: any = [...mappingData];
    prevData[index][key] = value;
    setMappingData(prevData);
  };

  const handleUpdate = () => {
    if (Updating) return;
    setSelectedRow(null);

    UpdateMapping(mappingData);
    setRowClicked(false);
  };

  const handleCancel = () => {
    setSelectedRow(null);
    setRowClicked(false);
  };

  const handleRowClick = (index: any) => {
    setSelectedRow(index);
    setRowClicked(true);
  };

  return (
    <section className={styles.mappingPageWrap}>
      <div className="flex justify-between items-start flex-col sm:flex-row mt-11">
        <h2 className="font-bold text-2xl">{t("Data Mapping")}</h2>
        <div className="flex justify-end gap-4 flex-col sm:flex-row">
          <div className="relative">
            <input
              type="text"
              placeholder="Search ID"
              className="w-full sm:w-[259px] border border-solid border-gray-300 rounded-md pl-8 pr-5 h-[38px] shadow-sm"
              value={searchIdInput}
              onChange={(e) => setSearchIdInput(e.target.value)}
            />
            <Icons.SearchIcon className="absolute left-3 top-3 transform h-4 w-4" />
          </div>

          <div className="relative">
            <input
              type="text"
              placeholder="Field Name"
              className="w-full sm:w-[259px] border border-solid border-gray-300 rounded-md pl-8 pr-5 h-[38px] shadow-sm"
              value={searchTextFiledInput}
              onChange={(e) => setSearchTextFiledInput(e.target.value)}
            />
            <Icons.SearchIcon className="absolute left-3 top-3 transform h-4 w-4" />
          </div>
        </div>
      </div>

      {isLoading ? (
        <div className=" flex w-full justify-center items-center min-h-[50vh]">
          <Spinner size={"50px"} />
        </div>
      ) : (
        <div>
          <div className="md:hidden">
            {filterMapping?.map((data: any, index) => {
              return (
                <div
                  className="grid grid-cols-2 px-2 py-6 rounded gap-4 bg-gray-100 shadow-md shadow-gray-300 m-4 "
                  key={`${"" + index}`}
                >
                  <div className="flex flex-col">
                    <div className="w-full text-start">S.No</div>
                    <input
                      className="p-[0.7rem] rounded border border-gray-300 h-full bg-white"
                      type="text"
                      disabled
                      value={(pageStart || 0) + (index + 1)}
                    />
                  </div>
                  <div className="flex flex-col">
                    <div className="w-full text-start">Field Name</div>
                    <input
                      className="p-1 rounded border border-gray-300 h-full bg-white"
                      type="text"
                      disabled
                      value={data.fieldName}
                    />
                  </div>

                  <div className="flex flex-col">
                    <div className="w-full text-start">Category</div>
                    <div className="column text-left  md:w-[calc(25%-13px)] font-medium text-sm text-[#737373] font-plus-jakarta w-full">
                      <Select
                        styles={{
                          control: (baseStyles, state) => ({
                            ...baseStyles,
                            borderColor: state.isFocused ? "grey" : "red",
                            borderRadius: "6px",
                            border: "1px solid #D4D4D4",
                            outline: "none",
                          }),
                          menu: (baseStyles) => ({
                            ...baseStyles,
                            borderColor: "black", // Set the border color of the menu (when open) to black
                          }),
                        }}
                        isClearable
                        options={categoryMappings}
                        value={
                          data?.fieldType
                            ? { value: data?.fieldType, label: data?.fieldType }
                            : null
                        }
                        onChange={(value: any) => {
                          if (!value) {
                            updateMappedValue("fieldType", null, index);
                            updateMappedValue(
                              "currentMappingList",
                              data?.fieldMappingList,
                              index,
                            );
                            updateMappedValue(
                              "fieldMapping",
                              data?.fieldMappingList?.[0]?.value,
                              index,
                            );
                            return;
                          }

                          const fieldMappings = value?.fieldMappings?.map(
                            (fieldMap: any) => ({
                              value: fieldMap?.position,
                              label: fieldMap?.position,
                            }),
                          );

                          updateMappedValue("fieldType", value?.value, index);
                          updateMappedValue(
                            "currentMappingList",
                            fieldMappings,
                            index,
                          );
                          updateMappedValue(
                            "fieldMapping",
                            fieldMappings[0]?.value,
                            index,
                          );
                        }}
                      />
                    </div>
                  </div>

                  <div className="flex flex-col">
                    <div className="w-full text-start">Field Mapping</div>
                    <div className="column text-left w-full md:w-[calc(25%-13px)] font-medium text-sm text-[#737373] font-plus-jakarta">
                      <CreatableSelect
                        createOptionPosition={"first"}
                        isClearable
                        styles={{
                          control: (baseStyles, state) => ({
                            ...baseStyles,
                            borderColor: state.isFocused ? "grey" : "black",
                            borderRadius: "6px",

                            border: "1px solid #D4D4D4",
                          }),
                        }}
                        value={
                          data?.fieldMapping
                            ? {
                                value: data?.fieldMapping,
                                label: data?.fieldMapping,
                              }
                            : data?.currentMappingList[0]
                        }
                        options={data?.currentMappingList}
                        onChange={(value: any) =>
                          updateMappedValue("fieldMapping", value?.value, index)
                        }
                      />
                    </div>
                  </div>

                  <div className="flex flex-col">
                    <div className="w-full text-start">Position Name</div>
                    <input
                      className="p-1 rounded border border-gray-300 h-full bg-white"
                      type="text"
                      disabled
                      value={data.positionName}
                    />
                  </div>

                  <div className="flex flex-col">
                    <div className="w-full text-start">Position Mapping</div>
                    <input
                      className="p-[0.7rem] rounded border border-gray-300 h-full bg-white"
                      type="text"
                      disabled
                      value={data.positionMapping}
                    />
                  </div>

                  <div className="flex items-end justify-center gap-6 col-span-2 ">
                    <button
                      onClick={() => {
                        setCurrentMappingId(data?.id);
                        setOpenModal(true);
                      }}
                    >
                      <Icons.ViewIcon />
                    </button>

                    <button onClick={() => handleRowClick(index)}>
                      <Icons.EditIconImg />
                    </button>
                  </div>
                </div>
              );
            })}
          </div>
          <section className="mappingContainer hidden md:flex flex-col justify-start items-start mb-5 mt-11 border-2 border-solid  border-gray-nutral-200 rounded-lg">
            <div className="header flex justify-start gap-5 py-3 px-4 w-full bg-[#FAFAFA] border-b-2">
              <div className="srNumber text-left w-12 font-bold text-sm text-[#737373] font-plus-jakarta">
                SR.
              </div>
              <div className="column text-left w-1/4 md:w-[calc(25%-13px)] font-bold text-sm text-[#737373] font-plus-jakarta">
                {t("Field Name")}
              </div>
              <div className="column text-left w-1/4 md:w-[calc(25%-13px)] font-bold text-sm text-[#737373] font-plus-jakarta">
                {t("Category")}
              </div>
              <div className="column text-left w-1/4 md:w-[calc(25%-13px)] font-bold text-sm text-[#737373] font-plus-jakarta">
                {t("Field Mapping")}
              </div>
              <div className="column text-left w-1/4 md:w-[calc(25%-13px)] font-bold text-sm text-[#737373] font-plus-jakarta">
                {t("Position Name")}
              </div>
              <div className="column text-left w-1/4 md:w-[calc(25%-13px)] font-bold text-sm text-[#737373] font-plus-jakarta">
                {t("Position Mapping")}
              </div>
              <div className="srNumber text-left w-12 font-bold text-sm">
                Actions
              </div>
            </div>

            <div className="flex flex-col gap-[2px] py-2 w-full ">
              {filterMapping?.map((data: any, index: number) => {
                let rowStyle = styles.greyRow;
                if (index === selectedRow) rowStyle = styles.blueRow;
                if (index !== selectedRow && index % 2 === 0)
                  rowStyle = styles.whiteRow;
                return (
                  <div
                    key={`${"" + index}`}
                    className={`${styles.row} ${rowStyle}`}
                    style={{
                      pointerEvents:
                        selectedRow !== null && index !== selectedRow
                          ? "none"
                          : "auto",
                      opacity:
                        selectedRow !== null && index !== selectedRow ? 0.5 : 1,
                    }}
                  >
                    <MappingDataComponent
                      categoryMappings={categoryMappings}
                      data={data}
                      handleRowClick={handleRowClick}
                      index={index}
                      pageStart={pageStart}
                      setCurrentMappingId={setCurrentMappingId}
                      setOpenModal={setOpenModal}
                      updateMappedValue={updateMappedValue}
                      Tooltip={Tooltip}
                    />
                  </div>
                );
              })}
            </div>

            {rowClicked && (
              <div className={styles.mappingFooter}>
                <Button
                  color="tertiary"
                  variant="outline"
                  size="small"
                  onClick={handleCancel}
                >
                  Cancle
                </Button>
                <Button
                  color="secondary"
                  disabled={!!Updating}
                  onClick={handleUpdate}
                  size="small"
                  loading={Updating}
                >
                  Save
                </Button>
              </div>
            )}
          </section>

          <div className="md:hidden w-full h-full  grid grid-cols"></div>
        </div>
      )}

      {data?.data && (
        <Pagination
          totalPages={data?.data?.totalPages}
          page={data?.data?.pageNumber}
          pageSize={data?.data?.pageSize}
          totalRecords={data?.data?.totalRecords}
          onChange={(newDetails: any) => {
            setPageDetails((prev: any) => ({
              ...prev,
              ...newDetails,
            }));
          }}
          sendParentSelectedRow={setSelectedRow}
          sendParentRowClicked={setRowClicked}
        />
      )}
      {openModal && (
        <NewMappingVacancyModal
          mappingId={currentMappingId}
          isOpen={openModal}
          onClose={() => {
            setOpenModal(false);
            setCurrentMappingId("");
          }}
        />
      )}
    </section>
  );
}

export default VacancyMapping;

type MappingDataComponentProps = {
  pageStart: any;
  index: any;
  data: any;
  categoryMappings: any;
  updateMappedValue: any;
  setCurrentMappingId: any;
  setOpenModal: any;
  handleRowClick: any;
  Tooltip: any;
};
const MappingDataComponent = (props: Readonly<MappingDataComponentProps>) => {
  const {
    pageStart,
    index,
    data,
    categoryMappings,
    updateMappedValue,
    setCurrentMappingId,
    setOpenModal,
    handleRowClick,
  } = props;
  return (
    <>
      <div className="srNumber text-left w-12 font-medium text-sm text-[#737373] font-plus-jakarta">
        {(pageStart || 0) + (index + 1)}
      </div>
      <div className="column text-left w-1/4 md:w-[calc(25%-13px)] font-medium text-sm text-[#737373] font-plus-jakarta">
        {data?.fieldName}
      </div>
      <div className="column text-left w-1/4 md:w-[calc(25%-13px)] font-medium text-sm text-[#737373] font-plus-jakarta">
        <Select
          styles={{
            control: (baseStyles, state) => ({
              ...baseStyles,
              borderColor: state.isFocused ? "grey" : "red",
              borderRadius: "6px",
              border: "1px solid #D4D4D4",
              outline: "none",
            }),
            menu: (baseStyles) => ({
              ...baseStyles,
              borderColor: "black", // Set the border color of the menu (when open) to black
            }),
          }}
          isClearable
          options={categoryMappings}
          value={
            data?.fieldType
              ? { value: data?.fieldType, label: data?.fieldType }
              : null
          }
          onChange={(value: any) => {
            if (!value) {
              updateMappedValue("fieldType", null, index);
              updateMappedValue(
                "currentMappingList",
                data?.fieldMappingList,
                index,
              );
              updateMappedValue(
                "fieldMapping",
                data?.fieldMappingList?.[0]?.value,
                index,
              );
              return;
            }

            const fieldMappings = value?.fieldMappings?.map(
              (fieldMap: any) => ({
                value: fieldMap?.position,
                label: fieldMap?.position,
              }),
            );

            updateMappedValue("fieldType", value?.value, index);
            updateMappedValue("currentMappingList", fieldMappings, index);
            updateMappedValue("fieldMapping", fieldMappings[0]?.value, index);
          }}
        />
      </div>
      <div className="column text-left w-1/4 md:w-[calc(25%-13px)] font-medium text-sm text-[#737373] font-plus-jakarta">
        <CreatableSelect
          createOptionPosition={"first"}
          isClearable
          styles={{
            control: (baseStyles, state) => ({
              ...baseStyles,
              borderColor: state.isFocused ? "grey" : "black",
              borderRadius: "6px",

              border: "1px solid #D4D4D4",
            }),
          }}
          value={
            data?.fieldMapping
              ? {
                  value: data?.fieldMapping,
                  label: data?.fieldMapping,
                }
              : data?.currentMappingList[0]
          }
          options={data?.currentMappingList}
          onChange={(value: any) =>
            updateMappedValue("fieldMapping", value?.value, index)
          }
        />
      </div>
      <div className="column text-left w-1/4 md:w-[calc(25%-13px)] font-medium text-sm text-[#737373] font-plus-jakarta">
        {data?.positionName}
      </div>
      <input
        className={`text-left w-1/4 md:w-[calc(25%-13px)] font-medium text-sm rounded-md border border-gray-300 p-3 text-[#737373] font-plus-jakarta ${
          !data?.isPositionSaved ? styles?.greenBorder : ""
        }`}
        value={data?.positionMapping}
        name={"positionMapping"}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          updateMappedValue("positionMapping", e.target.value, index)
        }
      />

      <div className={`${styles.srNumber} `}>
        <button
          data-type={"Open vacancy data"}
          onClick={() => {
            setCurrentMappingId(data?.id);
            setOpenModal(true);
          }}
        >
          <Icons.ViewIcon />
        </button>

        <button data-type={"Edit row"} onClick={() => handleRowClick(index)}>
          <Icons.EditIconImg />
        </button>
      </div>
    </>
  );
};
