import { SVGProps } from "react";

interface Props extends SVGProps<SVGSVGElement> {
  color?: string;
  height?: string | number; // Allow for string or number values
  width?: string | number; // Allow for string or number values
}

function ArrowDownIcon({
  color = "currentColor",
  height = "48",
  width = "48",
  ...props
}: Props) {
  return (
    <svg
      {...props}
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      height={height} // Set height based on props
      width={width} // Set width based on props
    >
      <g id="Shopicon">
        <polygon
          points="24,29.172 9.414,14.586 6.586,17.414 24,34.828 41.414,17.414 38.586,14.586"
          fill={color} // Use the color prop for fill
        />
      </g>
    </svg>
  );
}

export default ArrowDownIcon;
