import api from "./api";

export const Channels_Columns = [
  { label: "Name", translationKey: "Name", accessor: (row: any) => row?.name },
];

export const channels_data = [
  {
    id: 1,
    name: "Google",
  },
  {
    id: 2,
    name: "LinkedIn",
  },
  {
    id: 3,
    name: "Meta",
  },
  {
    id: 4,
    name: "Indeed",
  },
  {
    id: 5,
    name: "Display",
  },
];

export const getListofClientsforChannelSpend = (currentChannel: string) => [
  {
    label: "Client",
    translationKey: "client",
    accessor: (row: any) => row?.clientName,
  },
  {
    label: `${currentChannel} Budget`,
    translationKey: "channel budget",
    accessor: (row: any) => row?.budgetWithoutMargin,
  },
  {
    label: `${currentChannel} Spend`,
    translationKey: "ex margin",
    accessor: (row: any) => row?.spendWithoutMargin,
    accessKey: "channel-spend-ex-margin",
  },
];

export const getChannelTypeById = (id: string): string | undefined => {
  const channel = channels_data.find((channel) => String(channel.id) === id);
  return channel ? channel.name : undefined;
};

export const getBudgetByChannelYearMonth = async (
  channelType: any,
  year: number,
  month: number,
) => {
  const response = await api.get(
    `v2/BudgetPlanning/${channelType}/${year}/${month}`,
  );
  return response;
};

export const updateBudgetByChannelYearMonth = async (
  channelType: any,
  year: number,
  month: number,
  clientId: number,
  data: any,
) => {
  const response = await api.put(
    `v2/BudgetPlanning/${channelType}/${year}/${month}/${clientId}`,
    data,
  );
  return response;
};
