// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ChannelsTable_tableWrapper__xA5e7 {
  margin: 30px 0 0 0;
}
`, "",{"version":3,"sources":["webpack://./src/components/Channels/ChannelsTable/ChannelsTable.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB","sourcesContent":[".tableWrapper {\n  margin: 30px 0 0 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tableWrapper": `ChannelsTable_tableWrapper__xA5e7`
};
export default ___CSS_LOADER_EXPORT___;
