import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import Select from "react-select";

import { openSnackbar } from "../../../redux/snackbar";
import { getVacanciesCountForSegment } from "../../../services/campaign";
import { displayErrorSnackbar, isValidInput } from "../../../services/common";
import { getVacanciesbySegment } from "../../../services/segments";
import {
  getSegmentById,
  getSegmentFilters,
  getSegmentOptionsByFilter,
  updateSegment,
} from "../../../services/vacancySegment";
import SegmentButton from "../../SegmentButton/SegmentButton";
import SegmentVacanciesModal from "../../SegmentVacanciesModal/SegmentVacanciesModal";
import Spinner from "../../Spinner/Spinner";
import SegmentConditions from "../SegmentConditions";

import styles from "./EditSegment.module.css";

function EditSegment(props: any) {
  const {
    onEditSegment = () => {
      // Place Holder Comment
    },
    selectedSegments,
    vacanciesIds,
    openVacanciesDetailModal,
    setOpenVacanciesDetailModal,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const [loadingConditions, setLoadingConditions] = useState<boolean>(false);
  const params = useParams();
  const clientId = params.id;
  if (clientId === undefined) throw Error("Invalid client");

  const [segment, setSegment] = useState<any>({
    name: "",
    status: true,
    segmentGroup: "Any",
  });

  const { mutate } = useMutation(["segmentVacancies"], (data) =>
    getVacanciesbySegment(
      clientId,
      data,
      props.feedDays === null || props.feedDays === "" ? "" : props.feedDays,
    ),
  );

  // Tabs (New Code)
  const [currSegmentId, setCurrSegmentId] = useState(
    props?.selectedSegments[0]?.value,
  );

  useEffect(() => {
    if (selectedSegments?.length < 1) {
      setCurrSegmentId(null);
    } else {
      setCurrSegmentId(selectedSegments[0]?.value);
      selectedSegments.map((item: any) => {
        mutate(item.value);
      });
    }
  }, [selectedSegments]);

  useEffect(() => {
    if (currSegmentId === undefined) {
      setCurrSegmentId(selectedSegments[0]?.value);
    }
  }, [selectedSegments]);

  const [segmentConditions, setSegmentConditions] = useState<any>([
    {
      conditionName: "",
      conditionList: [],
      condition: "",
      conditionContentList: [],
      conditionContent: "",
    },
  ]);

  const [completeConditions, setCompleteConditions] = useState<any>([]);

  const [availableVariables, setAvailableVariables] = useState<any>([]);

  const [vacanciesCountonBuildSegment, setVacanciesCountonBuildSegment] =
    useState<any>("");

  const { refetch: getSelectedSegment, isLoading: loadingSegmentData } =
    useQuery(["segment", currSegmentId], () => getSegmentById(currSegmentId), {
      enabled: false,
      async onSuccess(data) {
        setLoadingConditions(true);
        const segmentData = data?.data;
        setSegment(segmentData);

        const existingConditions: any = [];

        for await (const condition of segmentData?.segmentConditions || []) {
          try {
            const filteredOptions = await getSegmentOptionsByFilter(
              condition?.conditionName,
              clientId,
              props.feedDays === null || props.feedDays === ""
                ? ""
                : props.feedDays,
            );
            const currentLabel = filteredOptions?.data?.conditions?.filter(
              (filteredCondtion: any) =>
                condition?.condition === filteredCondtion?.condition,
            )?.[0];

            existingConditions?.push({
              ...condition,
              conditionList: filteredOptions?.data?.conditions,
              conditionContentList: filteredOptions?.data?.possibleOptions,
              conditionLabel: {
                value: condition?.condition,
                label: currentLabel?.conditionText || condition?.condition,
              },
            });
          } catch (error: any) {
            console.log(error);
          }
        }
        setSegmentConditions(existingConditions);
        setLoadingConditions(false);
      },
    });

  const [segmentUpdated, setSegmentUpdated] = useState(false);

  const {
    mutate: updateCurrentSegment,
    data: UpdateSegmentRes,
    error: UpdateSegmentError,
    isLoading: updatingSegment,
    reset: ResetUpdateSegment,
  } = useMutation((data: any) => updateSegment(data), {
    onSuccess(data) {
      getSegmentVacanciesCount();
      onEditSegment(data?.data?.data);

      setSegmentUpdated(!segmentUpdated);
    },
  });

  //this
  const {
    isError: segmentVacanciesCountError,
    isLoading: segmentVacanciesCountSpinner,
    isFetching,
    refetch: getSegmentVacanciesCount,
  } = useQuery(
    ["segmentVacanciesCount"],
    () =>
      getVacanciesCountForSegment(
        clientId,
        segment?.segmentGroup,
        completeConditions,
        props.feedDays === null || undefined ? "" : props.feedDays,
      ),
    {
      enabled: false,
      onSuccess(data) {
        setVacanciesCountonBuildSegment(data?.data?.vacancyCount);
      },
    },
  );

  //this
  useEffect(() => {
    if (completeConditions?.length && props.feedDays) {
      getSegmentVacanciesCount();
    }
  }, [
    completeConditions,
    segment?.segmentGroup,
    props.feedDays,
    currSegmentId,
  ]);

  useQuery("segmentFilters", () => getSegmentFilters(clientId), {
    onSuccess(data) {
      setAvailableVariables(data?.data);
    },
  });

  useEffect(() => {
    if (currSegmentId) {
      getSelectedSegment();
    }
  }, [currSegmentId]);

  useEffect(() => {
    const filteredConditions: any = [];
    segmentConditions?.forEach((condition: any) => {
      if (
        isValidInput(condition?.conditionName) &&
        isValidInput(condition?.condition) &&
        isValidInput(condition?.conditionContent)
      ) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { conditionList, conditionContentList, ...rest } = condition;
        filteredConditions?.push(rest);
      }
    });
    setCompleteConditions(filteredConditions);
  }, [segmentConditions]);

  useEffect(() => {
    if (UpdateSegmentRes) {
      dispatch(
        openSnackbar({
          message: "Segment updated successfully",
          type: "success",
        }),
      );
      ResetUpdateSegment();
      queryClient.refetchQueries("segments");
    }
  }, [UpdateSegmentRes]);

  useEffect(() => {
    if (UpdateSegmentError) {
      dispatch(
        openSnackbar({
          message: "Error while updating the segment",
          type: "error",
        }),
      );
    }
  }, [UpdateSegmentError]);

  useEffect(() => {
    if (completeConditions?.length) {
      getSegmentVacanciesCount();
    }
  }, [completeConditions?.length]);

  const handleConditionChange = (key: string, value: any, index: number) => {
    const conditions = [...segmentConditions];
    conditions[index][key] = value;
    setSegmentConditions(conditions);
  };

  const handleUpdateSegment = async () => {
    if (updatingSegment) return;
    const { valid, message } = validateSegmentData();

    if (!valid) {
      displayErrorSnackbar(message);
      return;
    }
    updateCurrentSegment({
      ...segment,
      segmentConditions: completeConditions,
    });
  };

  const validateSegmentData = () => {
    if (!segment?.name?.trim()) {
      return {
        valid: false,
        message: "Segment name is required",
      };
    }

    for (const segmentCondition of segmentConditions) {
      const { conditionName, condition, conditionContent } = segmentCondition;
      if (
        !conditionName?.trim() ||
        !condition?.trim() ||
        !conditionContent?.trim()
      ) {
        return { valid: false, message: "Missing condition variables" };
      }
    }

    return { valid: true, message: "" };
  };

  const handleRefresh = () => {
    getSegmentVacanciesCount();
  };

  const handleOpenModal = () => {
    setOpenVacanciesDetailModal(true);
  };

  return (
    <div>
      <div className="w-full   pb-4">
        <div className="flex w-full justify-between items-center">
          <div className="flex gap-4 flex-wrap">
            {segment
              ? selectedSegments?.map((item: any) => {
                  return (
                    <SegmentButton
                      setCompleteConditions={setCompleteConditions}
                      setCurrSegmentId={setCurrSegmentId}
                      currSegmentId={currSegmentId}
                      item={item}
                      segment={segment}
                      loadingSegmentData={loadingSegmentData}
                      feedDays={props?.feedDays}
                      segmentUpdated={segmentUpdated}
                      setVacanciesCountonBuildSegment={
                        setVacanciesCountonBuildSegment
                      }
                      key={item.label + item.value}
                    />
                  );
                })
              : ""}
          </div>

          {selectedSegments.length > 0 && (
            <div className="text-gray-500">Total: {vacanciesIds?.length}</div>
          )}
        </div>
      </div>
      {currSegmentId && (
        <div className="parent border-solid border-2 mb-5  w-full">
          {loadingSegmentData ? (
            <div className={styles.spinnerWrap}>
              <Spinner />
            </div>
          ) : (
            <>
              <div
                className={`${styles.conditionGroupWrap} border-solid border-b-2`}
              >
                <span className={styles.conditionGroupText}>
                  {t("segmentWillFollow")}
                </span>
                <Select
                  styles={{
                    control: (baseStyles) => ({
                      ...baseStyles,
                      borderColor: "gray",
                      borderRadius: "5px",
                      minWidth: "133px",
                      padding: "2.5px",
                    }),
                  }}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                      ...theme.colors,
                      primary25: "#CDCDCD",
                      primary: "#CDCDCD",
                    },
                  })}
                  defaultValue={{ value: "Any", label: "Any" }}
                  value={{
                    value: segment?.segmentGroup,
                    label: segment?.segmentGroup,
                  }}
                  options={[
                    { value: "Any", label: "Any" },
                    { value: "All", label: "All" },
                  ]}
                  onChange={(value: any) =>
                    setSegment({
                      ...segment,
                      segmentGroup: value?.value,
                    })
                  }
                />
                <span>{t("followingConditions")}</span>
              </div>
              <div className={styles.createSegmentVacancies}>
                <button
                  className={`font-bold ${
                    vacanciesCountonBuildSegment ? styles.color : ""
                  }`}
                  onClick={handleOpenModal}
                >
                  {(!segmentVacanciesCountError &&
                    vacanciesCountonBuildSegment) ||
                    0}
                </button>
                Vacancies In Selection
                <svg
                  viewBox="0 0 30.50039 30.5"
                  width={"15px"}
                  height={"15px"}
                  xmlns="http://www.w3.org/2000/svg"
                  onClick={handleRefresh}
                  className={`${segmentVacanciesCountSpinner || (isFetching && " animate-spin cursor-not-allowed")} `}
                >
                  <path d="M3.2312,10.5H9.25a1.25,1.25,0,0,0,0-2.5H5.36743A13.20678,13.20678,0,0,1,16.415,2.5a12.75654,12.75654,0,0,1,11.68945,7.65039,1.25027,1.25027,0,0,0,2.291-1.00195A15.25661,15.25661,0,0,0,16.415,0,15.47292,15.47292,0,0,0,2.5,7.93555V1.25a1.25,1.25,0,0,0-2.5,0v8A1.25085,1.25085,0,0,0,1.25,10.5Z" />
                  <path d="M29.25,20h-8a1.25,1.25,0,0,0,0,2.5h3.94965A11.00927,11.00927,0,0,1,15.25,28,12.75654,12.75654,0,0,1,3.56055,20.34961a1.25027,1.25027,0,0,0-2.291,1.002A15.25661,15.25661,0,0,0,15.25,30.5,13.625,13.625,0,0,0,28,22.58423V29.25a1.25,1.25,0,0,0,2.5,0v-8A1.25022,1.25022,0,0,0,29.25,20Z" />
                </svg>
              </div>

              <SegmentConditions
                availableVariables={availableVariables}
                getSegmentOptionsByFilter={getSegmentOptionsByFilter}
                handleConditionChange={handleConditionChange}
                segmentConditions={segmentConditions}
                setSegmentConditions={setSegmentConditions}
                styles={styles}
                loadingConditions={loadingConditions}
                propsData={{
                  props,
                  clientId: clientId,
                }}
              />

              <div className="child-container border-solid border-t-2 bg-[#E1DFEC] px-4">
                <button
                  onClick={() => {
                    const conditions = [...segmentConditions];
                    setSegmentConditions([
                      ...conditions,
                      {
                        conditionName: "",
                        condition: "",
                        conditionContent: "",
                      },
                    ]);
                  }}
                  className={styles.addBtnWrap}
                >
                  <span className="text-[#245ED2] font-plus-jakarta text-xs font-semibold ">
                    +
                  </span>
                  <span className="text-[#245ED2] font-plus-jakarta text-xs font-bold">
                    {t("addNewVariable")}
                  </span>
                </button>

                <div className={styles.stepFooter}>
                  <button
                    className={
                      " w-full text-[#245ED2] text-sm font-semibold bg-[#245ed247] py-2 rounded-sm  flex items-center justify-center"
                    }
                    onClick={handleUpdateSegment}
                  >
                    {updatingSegment ? (
                      <Spinner size={"24px"} />
                    ) : (
                      t("Update Segment")
                    )}
                  </button>
                </div>
              </div>

              {openVacanciesDetailModal && (
                <SegmentVacanciesModal
                  isOpen={openVacanciesDetailModal}
                  onClose={() => setOpenVacanciesDetailModal(false)}
                  clientId={clientId}
                  feedDays={
                    props.feedDays === null || props.feedDays === ""
                      ? ""
                      : props.feedDays
                  }
                  segmentGroup={segment.segmentGroup}
                  completeConditions={completeConditions}
                />
              )}
            </>
          )}
        </div>
      )}
    </div>
  );
}

export default EditSegment;
