import api from "./api";

export const createSegment = async (data: any) => {
  const response: any = await api.post(
    "VacancySegment/AddVacancySegment",
    data,
  );
  return response;
};

export const getAllSegments = async (pageNumber?: any, pageSize?: any) => {
  const PageNumber = pageNumber ? `&PageNumber=${pageNumber}` : "&PageNumber=1";
  const PageSize = pageNumber ? `&PageSize=${pageSize}` : "&PageSize=25";
  const response: any = await api.get(
    `VacancySegment/GetAllVacancySegments?${PageNumber}${PageSize}`,
  );
  return response;
};

export const getSegmentById = async (id: number | string) => {
  const response: any = await api.get(`VacancySegment/${id}`);
  return response;
};

export const getSegmentFilters = async (clientId: any) => {
  const response: any = await api.get(
    `VacancySegment/GetAllVacancySegmentFilters?clientId=${clientId}`,
  );
  return response;
};

export const getSegmentOptionsByFilter = async (
  filterName: string,
  ClientId: number | string,
  Days: number,
) => {
  const response: any = await api.get(
    `VacancySegment/GetOptionsByFilter?filterName=${filterName}&ClientId=${ClientId}&Days=${Days}`,
  );
  return response;
};

export const getSegmentByName = async (inputValue?: string) => {
  const response: any = await api.get(
    `VacancySegment/GetVacancySegmentByName?name=${inputValue}`,
  );
  return response;
};

export const updateSegment = async (data: any) => {
  const response: any = await api.put(
    "VacancySegment/UpdateVacancySegment",
    data,
  );
  return response;
};
