import { AxiosError } from "axios";
import { ChangeEvent, FormEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";

import Icons from "../../assets/icons/Icons";
import { Button } from "../../components/Button/Button";
import { AuthInput } from "../../components/FormFields/FormFields";
import useFormErrors from "../../hooks/useFormErrors";
import { createUser } from "../../services/auth";
import {
  displayErrorSnackbar,
  displaySuccessSnackbar,
} from "../../services/common";

import styles from "./createUser.module.css";

function CreateUser() {
  const { t } = useTranslation();
  const { errors, removeFormError, setFormErrors } = useFormErrors();

  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    const key = e.target.name;
    removeFormError(key);
    setFormData({
      ...formData,
      [key]: e.target.value,
    });
  };

  const { mutate, isLoading } = useMutation((data: any) => createUser(data), {
    onError: (error: AxiosError) => {
      if (
        error.response?.data ===
        `Username '${formData.email}' is already taken.`
      ) {
        displayErrorSnackbar(error.response?.data);
      } else if (error.response?.data) setFormErrors(error.response?.data);
    },
    onSuccess: () => {
      displaySuccessSnackbar("User created successfully!");
    },
  });

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    mutate(formData);
  };

  return (
    <div className={styles.authCardContainer}>
      <div className={styles.authCard}>
        <h2 className="text-3xl font-bold tracking-wide flex justify-center">
          {t("registerUser", "Register User")}{" "}
        </h2>
        <form
          onSubmit={handleSubmit}
          className=" flex flex-col gap-y-4 mt-5 max-w-md"
        >
          <AuthInput
            icon={<Icons.UserCircleIcon width={23} height={23} color="black" />}
            name="email"
            handleChange={handleOnChange}
            placeholder="Email"
            type="email"
            value={formData?.email}
            errors={errors["email"]}
          />

          <AuthInput
            icon={<Icons.LockIcon width={23} height={23} color="black" />}
            name="password"
            handleChange={handleOnChange}
            placeholder="Password"
            type={"password"}
            value={formData?.password}
            errors={errors["password"]}
          />
          <Button color="primary" type="submit" loading={isLoading}>
            {t("registerUser", "Register User")}
          </Button>
        </form>
      </div>
    </div>
  );
}

export default CreateUser;
