import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import Icons from "../../assets/icons/Icons";
import Spinner from "../Spinner/Spinner";

import BudgetInput from "./SpendInput";
import styles from "./Table.module.css";

type TableProps = {
  status?: boolean;
  columns: any[];
  data?: any[];
  editable?: boolean;
  deleteable?: boolean;
  link?: boolean;
  RowisClickable?: boolean;
  onRowClick?: any;
  onCampaignClick?: any;
  onItemClick?: any;
  onDelete?: any;
  deleteTooltip?: string;
  viewTooltip?: string;
  onLinkClick?: any;
  linkTooltip?: string;
  clickableItems?: string[];
  onEdit?: any;
  editTooltip?: string;
  allowOrderUpdate?: boolean;
  onMoveUp?: any;
  onMoveDown?: any;
  moveUpTooltip?: string;
  moveDownTooltip?: string;
  updatingPriority?: boolean;
  sortable?: boolean;
  order?: boolean;
  handleChange?: any;
  handleSaved?: any;
  showStatus?: boolean;
};

function Table(props: Readonly<TableProps>) {
  const {
    status = true,
    showStatus = true,
    columns,
    data = [],
    editable = false,
    deleteable = false,
    link = false,
    RowisClickable = false,
    onRowClick = () => {
      // Place Holder Comment
    },
    onCampaignClick = () => {
      // Place Holder Comment
    },
    onItemClick = () => {
      // Place Holder Comment
    },
    onDelete = () => {
      // Place Holder Comment
    },
    deleteTooltip = undefined,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    viewTooltip = undefined,
    onLinkClick = () => {
      // Place Holder Comment
    },
    linkTooltip = undefined,
    clickableItems = [],
    editTooltip = undefined,
    onEdit = () => {
      // Place Holder Comment
    },
    allowOrderUpdate = false,
    onMoveUp = () => {
      // Place Holder Comment
    },
    moveUpTooltip = undefined,
    onMoveDown = () => {
      // Place Holder Comment
    },
    moveDownTooltip = undefined,
    updatingPriority = false,
    sortable = true,
    order = false,
    handleChange = () => {
      // Place Holder Comment
    },
    handleSaved = () => {
      // Place Holder Comment
    },
  } = props;

  const { t } = useTranslation();
  const [tableData, setTableData] = useState<any>([]);

  const [sortDetails, setSortDetails] = useState<any>({
    index: 0,
    order: "",
  });

  useEffect(() => {
    const originalData = data.map((item, index) => ({
      ...item,
      originalIndex: index,
    }));
    if (order) {
      const sorteddata = sortAsc(0, originalData);
      setTableData(sorteddata);
    } else if (sortDetails.order === "asc") {
      const sorteddata = sortAsc(sortDetails.index, originalData);
      setTableData(sorteddata);
    } else if (sortDetails.order === "desc") {
      const sorteddata = sortDesc(sortDetails.index, originalData);
      setTableData(sorteddata);
    } else {
      setTableData(originalData);
    }
  }, [data]);

  const sort = (index: number) => {
    const column = columns[index];
    if (column?.sortable === false) {
      return;
    }
    let originalData: any = data.map((item, index) => ({
      ...item,
      originalIndex: index,
    }));
    if (sortDetails?.index === index) {
      originalData =
        sortDetails?.order === "asc"
          ? sortDesc(index, originalData)
          : sortAsc(index, originalData);
    } else {
      originalData = sortAsc(index, originalData);
    }
    setTableData(originalData);
  };

  const sortAsc = (index: number, data: any) => {
    setSortDetails({
      index,
      order: "asc",
    });
    return data.sort((a: any, b: any) => {
      return columns?.[index]?.accessor(a) < columns?.[index]?.accessor(b)
        ? -1
        : 1;
    });
  };

  const sortDesc = (index: number, data: any) => {
    setSortDetails({
      index,
      order: "desc",
    });
    return data.sort((a: any, b: any) => {
      return columns?.[index]?.accessor(a) > columns?.[index]?.accessor(b)
        ? -1
        : 1;
    });
  };

  const handleOnDelete = (e: any, item: any) => {
    e.stopPropagation();
    onDelete(item);
  };

  const handleLinkClick = (e: any, item: any) => {
    e.stopPropagation();
    onLinkClick(item);
  };

  const handleEditClick = (e: any, item: any) => {
    e.stopPropagation();
    onEdit(item);
  };

  const handleItemClick = (e: any, accessKey: any, item: any) => {
    e.stopPropagation();
    onItemClick({ accessKey, item });
  };

  const handleMoveUp = (e: any, item: any) => {
    e.stopPropagation();
    onMoveUp(item);
  };

  const handleMoveDown = (e: any, item: any) => {
    e.stopPropagation();
    onMoveDown(item);
  };

  const getDateValue = (data: any) => {
    const date = data?.split("T")?.[0];
    return date;
  };

  return (
    <div className="border-2 rounded-lg overflow-hidden">
      <table className={styles.table}>
        <thead className={styles.mainHeader}>
          <tr className={`${styles.tableHeader} ${styles.tableTop}`}>
            {showStatus && (
              <td
                className={`${styles.tableHeaderItem} ${styles.columnItemHeaderStatus} ${styles.firstColumn}`}
              >
                Status
              </td>
            )}

            {columns?.map((header: any, index: any) => (
              <td
                key={`${"" + index}`}
                className={`${styles.tableHeaderItem} ${styles.columnItemHeader} ${styles.sortHeader}`}
                onClick={() => sortable && sort(index)}
              >
                {t(`${header?.translationKey}`, `${header?.label}`)}

                {sortable &&
                  header?.sortable !== false &&
                  (sortDetails?.index === index &&
                  (sortDetails?.order === "asc" ||
                    sortDetails?.order === "desc") ? (
                    <>
                      {sortDetails?.order === "desc" && (
                        <svg
                          height={"16px"}
                          viewBox="0 0 320 512"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M311.9 335.1l-132.4 136.8C174.1 477.3 167.1 480 160 480c-7.055 0-14.12-2.702-19.47-8.109l-132.4-136.8C-9.229 317.8 3.055 288 27.66 288h264.7C316.9 288 329.2 317.8 311.9 335.1z" />
                        </svg>
                      )}
                      {sortDetails?.order === "asc" && (
                        <svg
                          height={"16px"}
                          viewBox="0 0 320 512"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M27.66 224h264.7c24.6 0 36.89-29.78 19.54-47.12l-132.3-136.8c-5.406-5.406-12.47-8.107-19.53-8.107c-7.055 0-14.09 2.701-19.45 8.107L8.119 176.9C-9.229 194.2 3.055 224 27.66 224z" />
                        </svg>
                      )}
                    </>
                  ) : (
                    <svg
                      height={"16px"}
                      fill={"grey"}
                      viewBox="0 0 320 512"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M27.66 224h264.7c24.6 0 36.89-29.78 19.54-47.12l-132.3-136.8c-5.406-5.406-12.47-8.107-19.53-8.107c-7.055 0-14.09 2.701-19.45 8.107L8.119 176.9C-9.229 194.2 3.055 224 27.66 224zM292.3 288H27.66c-24.6 0-36.89 29.77-19.54 47.12l132.5 136.8C145.9 477.3 152.1 480 160 480c7.053 0 14.12-2.703 19.53-8.109l132.3-136.8C329.2 317.8 316.9 288 292.3 288z" />
                    </svg>
                  ))}
              </td>
            ))}

            {allowOrderUpdate && (
              <td
                className={`${styles.tableHeaderItem} ${styles.columnItemHeader} ${styles.orderColumn}`}
              />
            )}

            {(deleteable || link || editable) && (
              <td
                className={`${styles.tableHeaderItem} ${styles.columnItemHeader} ${styles.lastColumn}`}
              >
                Actions
              </td>
            )}
          </tr>
        </thead>
        <tbody>
          {!!tableData.length &&
            tableData?.map((item: any, index: any) => (
              <tr
                key={`${"" + index}`}
                className={`${styles.tableHeader} ${styles.tableRow} 
                            ${
                              index % 2 === 0 ? styles.whiteRow : styles.greyRow
                            }
                            ${RowisClickable ? styles.RowClickAble : ""}`}
                onClick={() => onRowClick(item)}
              >
                {showStatus && (
                  <td
                    className={`${styles.tableHeaderItem} ${styles.columnItem} ${styles.firstColumn}`}
                  >
                    {(item.status ?? status) ? (
                      <Icons.ActiveIcon />
                    ) : (
                      <Icons.InActiveIcon />
                    )}
                  </td>
                )}

                <Columns
                  key={`${"" + index}`}
                  clickableItems={clickableItems}
                  columns={columns}
                  getDateValue={getDateValue}
                  handleChange={handleChange}
                  handleSaved={handleSaved}
                  index={item.originalIndex}
                  item={item}
                  handleItemClick={handleItemClick}
                  onCampaignClick={onCampaignClick}
                />

                {allowOrderUpdate && (
                  <td
                    className={`${styles.tableHeaderItem} ${styles.columnItem} ${styles.orderColumn}`}
                  >
                    {updatingPriority ? (
                      <Spinner size={"16px"} />
                    ) : (
                      <>
                        {index !== tableData.length - 1 && (
                          <div
                            className={`${styles.iconWrap} ${
                              moveDownTooltip ? "tooltip" : ""
                            }`}
                            data-type={moveDownTooltip}
                          >
                            <svg
                              onClick={(e: any) => handleMoveDown(e, item)}
                              height="18px"
                              width="18px"
                              version="1.1"
                              viewBox="0 0 512 512"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path d="M383.6,322.7L278.6,423c-5.8,6-13.7,9-22.4,9c-8.7,0-16.5-3-22.4-9L128.4,322.7c-12.5-11.9-12.5-31.3,0-43.2  c12.5-11.9,32.7-11.9,45.2,0l50.4,48.2v-217c0-16.9,14.3-30.6,32-30.6c17.7,0,32,13.7,32,30.6v217l50.4-48.2  c12.5-11.9,32.7-11.9,45.2,0C396.1,291.4,396.1,310.7,383.6,322.7z" />
                            </svg>
                          </div>
                        )}
                        {index !== 0 && (
                          <div
                            className={`${styles.iconWrap} ${
                              moveUpTooltip ? "tooltip" : ""
                            }`}
                            data-type={moveUpTooltip}
                          >
                            <svg
                              onClick={(e: any) => handleMoveUp(e, item)}
                              height="18px"
                              width="18px"
                              version="1.1"
                              viewBox="0 0 512 512"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path d="M128.4,189.3L233.4,89c5.8-6,13.7-9,22.4-9c8.7,0,16.5,3,22.4,9l105.4,100.3c12.5,11.9,12.5,31.3,0,43.2  c-12.5,11.9-32.7,11.9-45.2,0L288,184.4v217c0,16.9-14.3,30.6-32,30.6c-17.7,0-32-13.7-32-30.6v-217l-50.4,48.2  c-12.5,11.9-32.7,11.9-45.2,0C115.9,220.6,115.9,201.3,128.4,189.3z" />
                            </svg>
                          </div>
                        )}
                      </>
                    )}
                  </td>
                )}

                {(deleteable || link || editable) && (
                  <td
                    className={`${styles.tableHeaderItem} ${styles.columnItem} ${styles.lastColumn}`}
                  >
                    {link && (
                      <button
                        className={`${styles.iconWrap} ${
                          linkTooltip ? "tooltip" : ""
                        } `}
                        data-type={linkTooltip}
                        onClick={(e: any) => handleLinkClick(e, item)}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={20}
                          height={20}
                          viewBox="0 0 20 20"
                          fill="none"
                        >
                          <circle
                            cx={10}
                            cy={10}
                            r={10}
                            fill="#CD335B"
                            fillOpacity="0.12"
                          />
                        </svg>

                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={14}
                          height={14}
                          viewBox="0 0 14 14"
                          fill="none"
                          style={{ position: "absolute" }}
                        >
                          <path
                            d="M8.72258 3.39024C9.24328 2.86954 10.0875 2.86954 10.6082 3.39024C11.1289 3.91094 11.1289 4.75516 10.6082 5.27586L8.6082 7.27586C8.0875 7.79656 7.24328 7.79656 6.72258 7.27586C6.46223 7.01551 6.04012 7.01551 5.77977 7.27586C5.51942 7.53621 5.51942 7.95832 5.77977 8.21867C6.82117 9.26006 8.50961 9.26006 9.55101 8.21867L11.551 6.21867C12.5924 5.17727 12.5924 3.48883 11.551 2.44743C10.5096 1.40603 8.82117 1.40603 7.77977 2.44743L6.77977 3.44743C6.51942 3.70778 6.51942 4.12989 6.77977 4.39024C7.04012 4.65059 7.46223 4.65059 7.72258 4.39024L8.72258 3.39024Z"
                            fill="#CD335B"
                          />
                          <path
                            d="M5.38926 6.72357C5.90996 6.20287 6.75418 6.20287 7.27488 6.72357C7.53523 6.98392 7.95734 6.98392 8.21769 6.72357C8.47804 6.46322 8.47804 6.04111 8.21769 5.78076C7.17629 4.73937 5.48785 4.73937 4.44645 5.78076L2.44645 7.78076C1.40506 8.82216 1.40506 10.5106 2.44645 11.552C3.48785 12.5934 5.17629 12.5934 6.21769 11.552L7.21769 10.552C7.47804 10.2916 7.47804 9.86954 7.21769 9.60919C6.95734 9.34884 6.53523 9.34884 6.27488 9.60919L5.27488 10.6092C4.75418 11.1299 3.90996 11.1299 3.38926 10.6092C2.86856 10.0885 2.86856 9.24427 3.38926 8.72357L5.38926 6.72357Z"
                            fill="#CD335B"
                          />
                        </svg>
                      </button>
                    )}
                    {editable && (
                      <div
                        className={`${styles.iconWrap} ${
                          editTooltip ? "tooltip" : ""
                        }`}
                        data-type={editTooltip}
                      >
                        <svg
                          onClick={(e: any) => handleEditClick(e, item)}
                          height="18px"
                          width="18px"
                          viewBox="0 0 500 500"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M31.949,403.452c0,35.164,28.435,63.599,63.599,63.599  c17.529,0,33.891-6.637,45.426-18.172l115.93-116.018l31.072,31.071c-4.182,15.265-0.181,32.345,11.905,44.518l45.063,45.063  c17.985,17.901,46.782,18.08,64.504,0.362c17.719-17.721,17.539-46.511-0.366-64.504l-45.063-45.063  c-12.174-12.085-29.254-16.078-44.514-11.898l-31.072-30.991l19.983-19.983c42.516,10.722,89.397-0.459,122.563-32.888  c22.803-22.35,35.352-50.698,37.07-80.04v-1.543c0-9.91-8.181-17.905-18.26-17.905c-5.001,0-9.544,2.006-12.907,5.181l-5.353,5.271  l-18.356,17.985c-11.543,11.268-27.984,17.896-45.701,17.896c-17.622,0-33.165-7.718-44.701-19.076  c-11.355-11.533-19.166-27.167-19.166-44.788c0-17.717,6.63-34.159,17.897-45.702l17.985-18.358l5.269-5.351  c3.18-3.363,5.181-7.906,5.181-12.91c0-10.079-7.992-18.26-17.904-18.26h-1.544c-29.34,1.721-57.687,14.268-80.038,37.071  c-32.43,33.165-43.52,80.047-32.802,122.477l-20.069,20.069l-83.863-83.943v-22.988L59.205,76.376l-27.256,27.256l27.256,54.513  h22.891l83.951,83.863L50.119,358.026C38.586,369.561,31.949,385.923,31.949,403.452z M77.375,398.909  c0-12.536,10.177-22.711,22.714-22.711c12.538,0,22.713,10.175,22.713,22.711s-10.175,22.716-22.713,22.716  C87.553,421.625,77.375,411.445,77.375,398.909z"
                            fill="#010101"
                          />
                        </svg>
                      </div>
                    )}
                    {deleteable && (
                      <button
                        className={`${styles.iconWrap} ${
                          deleteTooltip ? "tooltip" : ""
                        }`}
                        data-type={deleteTooltip}
                        onClick={(e: any) => handleOnDelete(e, item)}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={20}
                          height={20}
                          viewBox="0 0 20 20"
                          fill="none"
                        >
                          <circle
                            cx={10}
                            cy={10}
                            r={10}
                            fill="#737373"
                            fillOpacity="0.12"
                          />
                        </svg>

                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={14}
                          height={14}
                          viewBox="0 0 14 14"
                          fill="none"
                          style={{ position: "absolute" }}
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M6.29998 1.40002C6.03484 1.40002 5.79245 1.54983 5.67388 1.78697L5.16735 2.80002H2.79998C2.41338 2.80002 2.09998 3.11342 2.09998 3.50002C2.09998 3.88662 2.41338 4.20002 2.79998 4.20002L2.79998 11.2C2.79998 11.9732 3.42678 12.6 4.19998 12.6H9.79997C10.5732 12.6 11.2 11.9732 11.2 11.2V4.20002C11.5866 4.20002 11.9 3.88662 11.9 3.50002C11.9 3.11342 11.5866 2.80002 11.2 2.80002H8.8326L8.32607 1.78697C8.2075 1.54983 7.96512 1.40002 7.69998 1.40002H6.29998ZM4.89998 5.60002C4.89998 5.21342 5.21338 4.90002 5.59998 4.90002C5.98657 4.90002 6.29998 5.21342 6.29998 5.60002V9.80002C6.29998 10.1866 5.98657 10.5 5.59998 10.5C5.21338 10.5 4.89998 10.1866 4.89998 9.80002V5.60002ZM8.39997 4.90002C8.01338 4.90002 7.69998 5.21342 7.69998 5.60002V9.80002C7.69998 10.1866 8.01338 10.5 8.39997 10.5C8.78657 10.5 9.09997 10.1866 9.09997 9.80002V5.60002C9.09997 5.21342 8.78657 4.90002 8.39997 4.90002Z"
                            fill="#737373"
                          />
                        </svg>
                      </button>
                    )}
                  </td>
                )}
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
}

export default Table;

type ColumnsProps = {
  columns: any;
  index: any;
  item: any;
  getDateValue: any;
  clickableItems: any;
  handleChange: any;
  handleSaved: any;
  handleItemClick: any;
  onCampaignClick: any;
};

const Columns = (props: Readonly<ColumnsProps>) => {
  const {
    columns,
    index,
    item,
    getDateValue,
    clickableItems,
    handleChange,
    handleSaved,
    handleItemClick,
    onCampaignClick,
  } = props;
  return columns?.map(({ accessor, type, accessKey }: any) => {
    if (!accessor) {
      return (
        <td key={`${"" + index}`} className={`${styles.columnItem}`}>
          --
        </td>
      );
    }
    let data =
      accessor(item) !== null &&
      accessor(item) !== "" &&
      accessor(item) !== undefined
        ? accessor(item)
        : "--";
    data = type === "DateTime" ? getDateValue(data) : data;
    if (accessKey === "ex-margin") {
      return (
        <td
          key={accessor}
          className={`${styles.columnItem} ${clickableItems?.includes(accessKey) ? styles.clickable : ""}`}
        >
          <BudgetInput
            name="budgetSp"
            value={item?.budgetSp}
            onChange={handleChange}
            onSave={() => handleSaved(item)}
            index={index}
          />
        </td>
      );
    }

    if (accessKey === "channel-spend-ex-margin") {
      return (
        <td
          key={accessor}
          className={`${styles.columnItem} ${clickableItems?.includes(accessKey) ? styles.clickable : ""}`}
        >
          <BudgetInput
            name="spendWithoutMargin"
            value={item?.spendWithoutMargin}
            onChange={handleChange}
            onSave={() => handleSaved(item)}
            index={index}
          />
        </td>
      );
    }

    return (
      <td
        key={accessor}
        className={`${
          accessKey === "campaign" &&
          "text-[#0817FF] cursor-pointer hover:underline "
        } ${styles.columnItem} ${
          clickableItems?.includes(accessKey) ? styles.clickable : ""
        }`}
        onClick={(e: any) => {
          if (clickableItems?.includes(accessKey)) {
            handleItemClick(e, accessKey, item);
          }
          if (accessKey === "campaign") {
            onCampaignClick(item);
          }
        }}
      >
        {data}
      </td>
    );
  });
};
