import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IoTrashBin } from "react-icons/io5";

import Icons from "../../assets/icons/Icons";
import { Button } from "../Button/Button";
import Spinner from "../Spinner/Spinner";
import ToolTipNew from "../ToolTipNew/ToolTipNew";

import styles from "./CampaignTable.module.css";

type TableProps = {
  status?: boolean;
  columns: any[];
  data?: any[];
  editable?: boolean;
  deleteable?: boolean;
  link?: boolean;
  onRowClick?: any;
  onItemClick?: any;
  onDelete?: any;
  deleteTooltip?: string;
  viewTooltip?: string;
  onLinkClick?: any;
  linkTooltip?: string;
  clickableItems?: string[];
  onEdit?: any;
  editTooltip?: string;
  allowOrderUpdate?: boolean;
  onMoveUp?: any;
  onMoveDown?: any;
  moveUpTooltip?: string;
  moveDownTooltip?: string;
  updatingPriority?: boolean;
  sortable?: boolean;
  order?: boolean;
  sum?: any;
  handleParentPlayPause?: any;
  loadingCampaigns?: any;
};

function CampaignTable(props: Readonly<TableProps>) {
  const {
    status = true,
    columns,
    data = [],
    editable = false,
    deleteable = false,
    link = false,
    onRowClick = () => {
      // Place Holder Function
    },
    onItemClick = () => {
      // Place holder Funciton
    },
    onDelete = () => {
      // Place Holder Function
    },
    deleteTooltip = undefined,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    viewTooltip = undefined,
    onLinkClick = () => {
      // Place Holder Function
    },
    linkTooltip = undefined,
    clickableItems = [],
    allowOrderUpdate = false,
    onMoveUp = () => {
      // Place Holder Function
    },
    moveUpTooltip = undefined,
    onMoveDown = () => {
      // Place Holder Function
    },
    moveDownTooltip = undefined,
    updatingPriority = false,
    sortable = true,
    order = false,
    loadingCampaigns = false,

    handleParentPlayPause = () => {
      // Place Holder Function
    },
  } = props;

  const { t } = useTranslation();

  const [tableData, setTableData] = useState<any>([]);

  const [sortDetails, setSortDetails] = useState<any>({
    index: 0,
    order: "",
  });

  useEffect(() => {
    if (order) {
      const sorteddata = sortAsc(0, data);

      setTableData(sorteddata);
    } else {
      const tempData = data.map((item) => {
        let name = item?.name;
        if (item?.name?.length > 10) {
          name = `${name.slice(0, 10)}...`;
        }
        return {
          ...item,
          name: name,
        };
      });
      setTableData(tempData);
    }
  }, [data]);

  const sort = (index: number) => {
    let data: any = [...tableData];

    if (sortDetails?.index === index) {
      data =
        sortDetails?.order === "asc"
          ? sortDesc(index, data)
          : sortAsc(index, data);
    } else {
      data = sortAsc(index, data);
    }
    setTableData(data);
  };

  const sortAsc = (index: number, data: any) => {
    setSortDetails({
      index,
      order: "asc",
    });
    return data.sort((a: any, b: any) => {
      return columns?.[index]?.accessor(a) < columns?.[index]?.accessor(b)
        ? -1
        : 1;
    });
  };

  const sortDesc = (index: number, data: any) => {
    setSortDetails({
      index,
      order: "desc",
    });
    return data.sort((a: any, b: any) => {
      return columns?.[index]?.accessor(a) > columns?.[index]?.accessor(b)
        ? -1
        : 1;
    });
  };

  const handleOnDelete = (e: any, item: any) => {
    e.stopPropagation();
    onDelete(item);
  };

  const handleLinkClick = (e: any, item: any) => {
    e.stopPropagation();
    onLinkClick(item);
  };

  const handleItemClick = (e: any, accessKey: any, item: any) => {
    e.stopPropagation();
    onItemClick({ accessKey, item });
  };

  const handleMoveUp = (e: any, item: any) => {
    e.stopPropagation();
    onMoveUp(item);
  };

  const handleMoveDown = (e: any, item: any) => {
    e.stopPropagation();
    onMoveDown(item);
  };

  const getDateValue = (data: any) => {
    // Get date in 'yyyy-mm-dd' format
    const date = data?.split("T")?.[0];
    return date;
  };

  return (
    <div className={`${styles.tableContainer} border-2 rounded-lg`}>
      <table className={styles.table}>
        <thead className={styles.mainHeader}>
          <tr className={`${styles.tableHeader} ${styles.tableTop}`}>
            {}
            {status && (
              <td
                className={`${styles.tableHeaderItem} ${styles.columnItem} ${styles.firstColumn}`}
              />
            )}

            {columns?.map((header: any, index: any) => (
              <td
                key={`${"" + index}`}
                className={`${styles.tableHeaderItem} ${styles.columnItem} ${styles.sortHeader}`}
                onClick={() => sortable && sort(index)}
              >
                {t(`${header?.translationKey}`, `${header?.label}`)}

                {sortable &&
                  (sortDetails?.index === index &&
                  (sortDetails?.order === "asc" ||
                    sortDetails?.order === "desc") ? (
                    <>
                      {sortDetails?.order === "desc" && (
                        <Icons.UpDownArrowIcon />
                      )}
                      {sortDetails?.order === "asc" && (
                        <Icons.UpDownArrowIcon />
                      )}
                    </>
                  ) : (
                    <Icons.UpDownArrowIcon />
                  ))}
              </td>
            ))}

            {allowOrderUpdate && (
              <td
                className={`${styles.tableHeaderItem} ${styles.columnItem} ${styles.orderColumn}`}
              />
            )}

            {(deleteable || link || editable) && (
              <td
                className={`${styles.tableHeaderItem} ${styles.columnItemHeader} ${styles.lastColumn}`}
              >
                Actions
              </td>
            )}
          </tr>
        </thead>
        <tbody>
          {!!tableData.length &&
            tableData?.map((item: any, index: any) => {
              return (
                <tr
                  key={`${"" + index}`}
                  className={`${styles.tableHeader} ${styles.tableRow} 
                            ${
                              index % 2 === 0 ? styles.whiteRow : styles.greyRow
                            } relative`}
                  onClick={() => onRowClick(item)}
                >
                  {/* this  */}
                  {status && item.status ? (
                    <td
                      className={`${styles.tableHeaderItem} ${styles.columnItem} ${styles.firstColumn}`}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleParentPlayPause(item?.id);
                      }}
                    >
                      {item?.paused ? (
                        <Icons.PauseIcon color="red" />
                      ) : (
                        <Icons.PlayIcon color="green" />
                      )}
                    </td>
                  ) : (
                    <td
                      className={`${styles.tableHeaderItem} ${styles.columnItem} ${styles.firstColumn}  group`}
                      onClick={(e) => e.stopPropagation()}
                    >
                      <ToolTipNew message="Deleted Campaign!" />
                      <IoTrashBin />
                    </td>
                  )}

                  <Columns
                    key={`${"" + index}`}
                    clickableItems={clickableItems}
                    columns={columns}
                    getDateValue={getDateValue}
                    handleItemClick={handleItemClick}
                    index={index}
                    item={item}
                  />

                  {allowOrderUpdate && (
                    <td
                      className={`${styles.tableHeaderItem} ${styles.columnItem} ${styles.orderColumn}`}
                    >
                      {updatingPriority || loadingCampaigns ? (
                        <Spinner size={"16px"} />
                      ) : (
                        <>
                          {index !== tableData.length - 1 && (
                            <div
                              className={`${styles.iconWrap} ${
                                moveDownTooltip ? "tooltip" : ""
                              }`}
                              data-type={moveDownTooltip}
                            >
                              <Button
                                color="tertiary"
                                variant="text"
                                size="small"
                                onClick={(e: any) => handleMoveDown(e, item)}
                              >
                                <Icons.UpDownArrowIcon />
                              </Button>
                            </div>
                          )}
                          {index !== 0 && (
                            <div
                              className={`${styles.iconWrap} ${
                                moveUpTooltip ? "tooltip" : ""
                              }`}
                              data-type={moveUpTooltip}
                            >
                              <Button
                                color="tertiary"
                                variant="text"
                                size="small"
                                onClick={(e: any) => handleMoveUp(e, item)}
                              >
                                <Icons.UpDownArrowIcon />
                              </Button>
                            </div>
                          )}
                        </>
                      )}
                    </td>
                  )}

                  {(deleteable || link || editable) && (
                    <td
                      className={`${styles.tableHeaderItem} ${styles.columnItem} ${styles.lastColumn}`}
                    >
                      {link && (
                        <div
                          className={`${styles.iconWrap} ${
                            linkTooltip ? "tooltip" : ""
                          }`}
                          data-type={linkTooltip}
                        >
                          <Button
                            color="tertiary"
                            variant="text"
                            onClick={(e: any) => handleLinkClick(e, item)}
                          >
                            <Icons.LinkIcon />
                          </Button>
                        </div>
                      )}
                      {deleteable && (
                        <div
                          className={`${styles.iconWrap} ${
                            deleteTooltip ? "tooltip" : ""
                          }`}
                          data-type={deleteTooltip}
                        >
                          <Icons.TrashIcon
                            onClick={(e: any) => handleOnDelete(e, item)}
                            height={20}
                            width={20}
                          />
                        </div>
                      )}
                    </td>
                  )}
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
}

export default CampaignTable;

type ColumnsProps = {
  columns: any;
  index: any;
  item: any;
  getDateValue: any;
  clickableItems: any;
  handleItemClick: any;
};

const Columns = (props: Readonly<ColumnsProps>) => {
  const {
    columns,
    index,
    item,
    getDateValue,
    clickableItems,
    handleItemClick,
  } = props;
  return columns?.map(({ accessor, type, accessKey }: any) => {
    let data;

    if (!accessor) {
      return (
        <td key={`${"" + index}`} className={`${styles.columnItem}`}>
          --
        </td>
      );
    }
    if (accessKey === "budgetCount") {
      data = <ColumnData index={index} item={item} key={`${"" + index}`} />;
    } else {
      data =
        accessor(item) !== null &&
        accessor(item) !== "" &&
        accessor(item) !== undefined
          ? accessor(item)
          : "--";
      data = type === "DateTime" ? getDateValue(data) : data;
    }

    return (
      <td
        key={accessor}
        className={`${styles.columnItem} ${
          clickableItems?.includes(accessKey) ? styles.clickable : ""
        } font-medium text-sm font-plus-jakarta text-[#737373] `}
        onClick={(e: any) => {
          if (clickableItems?.includes(accessKey)) {
            handleItemClick(e, accessKey, item);
          }
        }}
      >
        {data}
      </td>
    );
  });
};

type ColumnDataProps = {
  index: any;
  item: any;
};
const ColumnData = (props: Readonly<ColumnDataProps>) => {
  const { index, item } = props;
  return (
    <div className="w-full  rounded-full h-4 bg-[#71707033]">
      <div
        className={`${
          index % 2 === 0 ? "bg-[#34A853]" : "bg-red-500"
        }  h-4 rounded-full flex justify-center items-center text-white font-bold`}
      >
        {item?.budget}
      </div>
    </div>
  );
};
