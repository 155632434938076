import React, { useState } from "react";
import Select from "react-select";

import Icons from "../../assets/icons/Icons";

import styles from "./KPISetting.module.css";

type kpiProps = {
  sendParentKpiState?: any;
};

function KPISettings(props: Readonly<kpiProps>) {
  const {
    sendParentKpiState = () => {
      // Place Holder comment
    },
  } = props;

  const [kpi, setKpi] = useState<any>([{ kpiType: "CPA", value: 0 }]);

  const [selectedOption, setSelectedOption] = useState<any>({});

  const handleKpiChange = (key: string, value: any, index: number) => {
    const data = [...kpi];
    data[index][`${key}`] = value;
    setKpi(data);
    sendParentKpiState(true);
  };

  const optionsData = [
    { id: 1, text: "Other similar campaigns have a KPI of 50 EURO" },
    { id: 2, text: "Raise budget to X per month to get conversions" },
    { id: 3, text: "Diversify job boards" },
  ];

  return (
    <div className={styles.kpiContainer}>
      <div className={styles.kpiMapping}>
        {kpi?.map((kpiData: any, index: number) => {
          return (
            <div
              className="grid grid-cols-1 sm:grid-cols-2 gap-5 my-8"
              key={`${"" + index}`}
            >
              <Select
                styles={{
                  // eslint-disable-next-line @typescript-eslint/no-unused-vars
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    borderRadius: "5px",
                    // minWidth: "282px",
                    padding: "2.5px",
                    height: "40px",
                  }),
                }}
                theme={(theme) => ({
                  ...theme,
                  borderRadius: 0,
                  colors: {
                    ...theme.colors,
                    primary25: "#CDCDCD",
                    primary: "#CDCDCD",
                  },
                })}
                defaultValue={{ value: "CPA", label: "CPA" }}
                options={[
                  { value: "CPA", label: "CPA" },
                  { value: "CPC", label: "CPC" },
                ]}
                value={{ value: kpiData?.kpiType, label: kpiData?.kpiType }}
                onChange={(selected: any) =>
                  handleKpiChange("kpiType", selected?.value, index)
                }
              />

              <div className="flex items-center relative">
                <input
                  className={` w-full h-10 rounded-md border-2 border-solid border-gray-300  `}
                  name={"value"}
                  value={kpiData?.value || ""}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleKpiChange("value", e.target.value, index)
                  }
                />
                <div className="absolute right-10 top-2 border-l-2 border-solid border-gray-300 pl-1">
                  EURO
                </div>

                <button
                  className="pl-2"
                  onClick={() => {
                    const data = [...kpi];
                    data?.splice(index, 1);
                    setKpi(data);
                    if (index === 0) {
                      sendParentKpiState(false);
                    }
                  }}
                >
                  <Icons.ModelCloseIcon width={25} />
                </button>
              </div>
            </div>
          );
        })}
      </div>

      <button
        className={styles.addKpiWrap}
        onClick={() => {
          const prevData = [...kpi];
          prevData?.push({ kpiType: "CPA", value: 0 });
          setKpi(prevData);
        }}
      >
        <span className="text-[#245ED2] font-plus-jakarta text-xs font-semibold ">
          +
        </span>
        <span className="text-[#245ED2] font-plus-jakarta text-xs font-bold">
          {"Add New KPI"}
        </span>
      </button>

      <div
        className={`${styles.kpiActionsContainer} w-full mb-5 border-solid border-2 mt-6 py-5 px-4 bg-[#E1DFEC]`}
      >
        <p className="mb-4 font-plus-jakarta text-sm font-normal text-[#2A263F]">
          Prediction of action to be taken
        </p>
        <div className={styles.optionsWrap}>
          {optionsData?.map((option: any) => {
            return (
              <label
                key={option?.id}
                className={`${styles.option}  font-plus-jakarta text-sm font-normal text-[#2A263F]`}
              >
                <input
                  type={"radio"}
                  checked={selectedOption?.id === option?.id}
                  onChange={() => setSelectedOption(option)}
                />
                {option?.text}
              </label>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default KPISettings;
