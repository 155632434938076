import {
  ClientType,
  ContactType,
  CreateContactType,
  CreateFeedDto,
  IndeedConfigurationType,
  UpdateFeedDto,
} from "../types/client/ClientTypes";

import api from "./api";
type clientIdType = number | string | undefined;

export const getClients = async () => {
  return await api.get("Clients/GetAll");
};

export const getPaginatedClients = async (
  PageNumber: number,
  PageSize: number,
  searchInput: string,
) => {
  const response = await api.get(
    `v2/Clients?PageNumber=${PageNumber}&PageSize=${PageSize}&searchString=${searchInput}`,
  );
  response.data.totalPages = Math.ceil(
    response.data.totalRecords / response.data.limit,
  );
  return response;
};

export const getClient = async (id: any) => {
  const response: any = await api.get(`v2/Clients/${id}`);
  return response;
};

export const deleteClient = async (id: string) => {
  const response: any = await api.delete(`Clients/${id}`, {});
  return response;
};

export const getUserList = async () => {
  const response: any = await api.get("User/GetUserList");
  return response;
};

/* Client GET API's */

export const getClientsV2 = async (PageNumber = 1, PageSize = 25) => {
  const response = await api.get(
    `v2/Clients?PageNumber=${PageNumber}&PageSize=${PageSize}`,
  );
  return response;
};

export const getIndeedConfigurationV2 = async (clientId: number) => {
  const response = await api.get(`v2/Clients/${clientId}/IndeedConfiguration`);
  return response;
};

export const getClientV2 = async (clientId: clientIdType) => {
  const response = await api.get(`v2/Clients/${clientId}`);
  return response;
};

export const getClientContactsV2 = async (clientId: clientIdType) => {
  const response = await api.get(`v2/Clients/${clientId}/contacts`);
  return response;
};

export const getClientFeedsV2 = async (clientId: clientIdType) => {
  const response = await api.get(`v2/Clients/${clientId}/feeds`);
  return response;
};

/* Client Add api */

export const addClientV2 = async (data: ClientType) => {
  const response = await api.post(`v2/Clients`, data);
  return response;
};

export const addNewContactV2 = async (
  data: CreateContactType,
  clientId: clientIdType,
) => {
  const response = await api.post(`v2/Clients/${clientId}/contacts`, data);
  return response;
};

export const addNewFeedV2 = async (
  feed: CreateFeedDto,
  clientId: clientIdType,
) => {
  const response = await api.post(`v2/Clients/${clientId}/feeds`, feed);
  return response;
};

/* Client Update API's */

export const updateClientV2 = async (
  data: ClientType,
  clientId: clientIdType,
) => {
  const response = await api.put(`v2/Clients/${clientId}`, data);
  return response;
};

export const updateIndeedConfigurationV2 = async (
  clientId: number,
  data: IndeedConfigurationType,
) => {
  const response = await api.put(
    `v2/Clients/${clientId}/IndeedConfiguration`,
    data,
  );
  return response;
};

export const updateClientContactsV2 = async (
  data: ContactType,
  clientId: clientIdType,
  contactId: number | string,
) => {
  const response = await api.put(
    `v2/Clients/${clientId}/contacts/${contactId}`,
    data,
  );
  return response;
};

export const updateClientFeedV2 = async (
  feed: UpdateFeedDto,
  clientId: clientIdType,
  feedId: number | string,
) => {
  const response = await api.put(
    `v2/Clients/${clientId}/feeds/${feedId}`,
    feed,
  );
  return response;
};

/* Client Delete API's */

export const deleteClientContactsV2 = async (
  clientId: clientIdType,
  contactId: number | string,
) => {
  const response = await api.delete(
    `v2/Clients/${clientId}/contacts/${contactId}`,
    {},
  );
  return response;
};

export const deleteClientFeedV2 = async (
  clientId: clientIdType,
  contactId: number | string,
) => {
  const response = await api.delete(
    `v2/Clients/${clientId}/feeds/${contactId}`,
    {},
  );
  return response;
};
