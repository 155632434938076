import Icons from "../../assets/icons/Icons";
import Dropdown from "../Dropdown/Dropdown";

import styles from "./Paginator.module.css";

function Paginator(props: any) {
  const {
    pageNumber = 1,
    pageSize = 25,
    totalPages = 1,
    totalRecords = 1,
    onChange = () => {
      // Place Holder Comment
    },
  } = props;

  return (
    <div className={styles.paginator}>
      <div className={styles.perPage}>
        <span>Rows per page</span>
        <Dropdown
          value={pageSize}
          options={[
            { value: 25, label: 25 },
            { value: 50, label: 50 },
            { value: 100, label: 100 },
          ]}
          handleOnChange={(value: any) => {
            return onChange({
              pageNumber,
              pageSize: value,
            });
          }}
        />
      </div>

      <div className={styles.pageNumbers}>
        <button
          className={`${styles.pageBtn}`}
          disabled={pageNumber <= 1}
          onClick={() =>
            onChange({
              pageSize,
              pageNumber: pageNumber - 1,
            })
          }
        >
          <Icons.UpDownArrowIcon />
        </button>

        <div className={styles.pageBtn}>{pageNumber}</div>

        <button
          className={`${styles.pageBtn}`}
          disabled={pageNumber >= totalPages}
          onClick={() =>
            onChange({
              pageSize,
              pageNumber: pageNumber + 1,
            })
          }
        >
          <Icons.UpDownArrowIcon />
        </button>
      </div>

      <div className={styles.perPage}>
        <span>Total pages : </span>
        <div className={styles.pageBtn}>{totalPages}</div>
      </div>

      <div className={styles.perPage}>
        <span>Total records : </span>
        <div className={styles.pageBtn}>{totalRecords}</div>
      </div>
    </div>
  );
}

export default Paginator;
