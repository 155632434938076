import { SVGProps } from "react";

type Props = SVGProps<SVGSVGElement>;

function VacanciesIcon(props: Props) {
  return (
    <svg
      {...props}
      width={props.width || "25"}
      height={props.height || "25"}
      viewBox="0 0 25 24"
      xmlns="http://www.w3.org/2000/svg"
      fill={props.color || "currentColor"}
      stroke={props.color || "currentColor"}
    >
      <path
        d="M4.86195 16.2025L4.86197 16.2025L5.75434 16.8518L5.69333 16.931C5.69339 16.9311 5.69345 16.9311 5.6935 16.9311C6.06703 17.2187 6.47014 17.4769 6.89223 17.6992L6.89233 17.6992C8.11121 18.3393 9.44802 18.6594 10.7853 18.6594C12.1225 18.6594 13.4599 18.3392 14.6791 17.699L14.6791 17.6989C15.1029 17.4759 15.506 17.2181 15.8769 16.9329C15.9771 16.856 16.0755 16.776 16.1724 16.6941L21.3655 21.8871C21.5076 22.0292 21.694 22.1003 21.8801 22.1003C22.0663 22.1003 22.2526 22.0292 22.3947 21.8871C22.6789 21.603 22.6789 21.1422 22.3946 20.8581C22.3946 20.858 22.3946 20.858 22.3946 20.858L17.2003 15.6637C18.4696 14.1569 19.1631 12.2711 19.1631 10.2783C19.1631 8.04068 18.2914 5.93652 16.7091 4.35414C15.1273 2.77175 13.0233 1.9 10.7854 1.9C8.54762 1.9 6.44357 2.77176 4.86181 4.35415C3.27944 5.93653 2.40781 8.04069 2.40781 10.2783C2.40781 12.5158 3.27945 14.62 4.86195 16.2025ZM6.579 15.7761L6.57865 15.7758C6.34115 15.5947 6.10974 15.3919 5.89108 15.1732C4.58335 13.8655 3.86324 12.1273 3.86324 10.2781C3.86324 8.42893 4.58323 6.69087 5.89108 5.38302L5.8911 5.383C7.19832 4.07529 8.93611 3.3553 10.7854 3.3553C12.6348 3.3553 14.3726 4.07529 15.6798 5.38313L15.6798 5.38314C16.9875 6.69088 17.7077 8.42894 17.7077 10.2781C17.7077 12.1273 16.9877 13.8654 15.6798 15.1732C15.4628 15.3903 15.2308 15.5939 14.9901 15.7788L14.99 15.7788C14.6847 16.0137 14.352 16.2264 14.0017 16.4108C11.9878 17.4684 9.58303 17.4685 7.5696 16.411C7.22139 16.2277 6.88878 16.0145 6.579 15.7761Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.2"
      />
      <path
        d="M10.783 11.1983C12.3788 11.1983 13.6769 9.90024 13.6769 8.3045C13.6769 6.70875 12.3788 5.41064 10.783 5.41064C9.18726 5.41064 7.88916 6.70875 7.88916 8.3045C7.88916 9.90024 9.18726 11.1983 10.783 11.1983ZM10.783 6.71115C11.6615 6.71115 12.3764 7.42601 12.3764 8.3045C12.3764 9.18298 11.6615 9.89784 10.783 9.89784C9.90452 9.89784 9.18967 9.18298 9.18967 8.3045C9.18967 7.42601 9.90452 6.71115 10.783 6.71115Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.178712"
      />
      <path
        d="M15.5442 13.9999L15.5442 13.9998C15.4076 13.1893 14.9801 12.4839 14.3103 11.9609L14.3103 11.9609C13.6281 11.4284 12.6882 11.3015 11.8552 11.6275L11.8549 11.6276C11.6793 11.697 11.4908 11.7583 11.2933 11.8099L11.2931 11.81C10.9599 11.8978 10.6096 11.8977 10.278 11.8089L10.2777 11.8088C10.0791 11.7564 9.89237 11.6955 9.71891 11.6265L9.71891 11.6265L9.71829 11.6263C8.88607 11.3024 7.94612 11.4293 7.26447 11.9613L7.26439 11.9614C6.59322 12.4865 6.16573 13.1931 6.03087 14.0035L6.03087 14.0035C5.97196 14.3577 6.21137 14.6927 6.56564 14.7516L6.56571 14.7517C6.60178 14.7576 6.63762 14.7605 6.67296 14.7605C6.98525 14.7605 7.26086 14.5351 7.3137 14.2168C7.39515 13.7273 7.64655 13.3136 8.06526 12.9859C8.38327 12.7375 8.83554 12.6785 9.24227 12.8365C9.46082 12.9234 9.69743 13.0006 9.9436 13.0657C10.4917 13.2123 11.0729 13.2129 11.6235 13.0677C11.8697 13.0033 12.1085 12.9258 12.3309 12.8377C12.7397 12.6778 13.1912 12.7371 13.5098 12.9859C13.9233 13.3088 14.1739 13.717 14.2591 14.2007C14.3037 14.5166 14.5751 14.7587 14.9029 14.7587L14.9033 14.7587C15.2576 14.6998 15.4969 14.3652 15.438 14.0109L15.5442 13.9999Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.1"
      />
    </svg>
  );
}

export default VacanciesIcon;
