import { SVGProps } from "react";

interface JobBoardsIconProps extends SVGProps<SVGSVGElement> {
  width?: string | number;
  height?: string | number;
  color?: string;
}

function JobBoardsIcon({
  width = "25",
  height = "25",
  color = "white",
  ...props
}: JobBoardsIconProps) {
  return (
    <svg
      {...props}
      width={width}
      height={height}
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.35547 3.52055C8.35547 3.04159 8.74374 2.65332 9.2227 2.65332H14.4858C14.9487 2.65332 15.324 3.0286 15.324 3.49154C15.324 3.74325 15.2225 3.93218 15.0889 4.03088C14.3157 4.60193 13.2369 5.11476 11.9331 5.14562C10.886 5.1704 9.65632 4.91678 8.57248 4.05115C8.44974 3.95311 8.35547 3.76911 8.35547 3.52055ZM6.8718 3.241C7.01011 2.06537 8.0099 1.15332 9.2227 1.15332H14.4858C15.6654 1.15332 16.641 2.02682 16.801 3.16228C17.0028 3.11318 17.2111 3.08789 17.4215 3.08789H19.1605C20.6102 3.08789 21.7855 4.26314 21.7855 5.71289V8.4917C21.7855 8.83688 21.5056 9.1167 21.1605 9.1167C20.8153 9.1167 20.5355 8.83688 20.5355 8.4917V5.71289C20.5355 4.9535 19.9198 4.33789 19.1605 4.33789H17.4215C17.1106 4.33789 16.8089 4.44323 16.5656 4.63672L16.4757 4.70824C16.348 4.90664 16.1837 5.08709 15.98 5.23748C15.0524 5.92257 13.7327 6.56721 12.1131 6.64023C11.7889 6.69363 11.4576 6.6857 11.1364 6.61685C10.218 6.53189 9.21708 6.25826 8.26322 5.66608C7.9972 5.55963 7.75039 5.41033 7.53259 5.22411L6.88197 4.66782C6.63294 4.45489 6.31606 4.33789 5.98841 4.33789H4.38672C3.62733 4.33789 3.01172 4.9535 3.01172 5.71289V18.4387C3.01172 19.1981 3.62733 19.8137 4.38672 19.8137H10.2463V16.3433C10.2463 14.8935 11.4215 13.7183 12.8713 13.7183H20.7208C22.1705 13.7183 23.3458 14.8935 23.3458 16.3433V20.0967C23.3458 21.5464 22.1705 22.7217 20.7208 22.7217H12.5292C11.4851 22.7217 10.6047 22.0207 10.3329 21.0637H4.38672C2.93697 21.0637 1.76172 19.8885 1.76172 18.4387V5.71289C1.76172 4.26314 2.93697 3.08789 4.38672 3.08789H5.98841C6.29186 3.08789 6.59047 3.14046 6.8718 3.241ZM11.4963 16.3433V20.4387C11.4963 21.0092 11.9587 21.4717 12.5292 21.4717H20.7208C21.4802 21.4717 22.0958 20.8561 22.0958 20.0967V16.3433C22.0958 15.5839 21.4802 14.9683 20.7208 14.9683H12.8713C12.1119 14.9683 11.4963 15.5839 11.4963 16.3433Z"
        fill={color}
      />
      <path
        d="M5.55469 8.58911H10.2363M5.55469 12.9779H8.52963M5.55469 17.2691H8.52963M16.7219 17.2691V19.1709"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M13.8789 13.7249V12.8C13.8789 12.2478 14.3266 11.8 14.8789 11.8H18.9834C19.5357 11.8 19.9834 12.2478 19.9834 12.8V13.7249"
        stroke={color}
        strokeWidth="1.25"
        strokeLinecap="round"
      />
      <path
        d="M11.5137 15.1504C12.6234 16.5896 14.5264 18.5799 16.9129 18.6073C18.5863 18.6265 20.6055 17.5725 22.292 15.1504"
        stroke={color}
        strokeWidth="1.25"
        strokeLinecap="round"
      />
    </svg>
  );
}

export default JobBoardsIcon;
