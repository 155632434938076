// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.WarningModalDesign_spinner__8WJrf {
  display: flex;
  justify-content: center;
  padding: 50px;
}
`, "",{"version":3,"sources":["webpack://./src/components/ConfirmationModal/WarningModalDesign.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,aAAa;AACf","sourcesContent":[".spinner {\n  display: flex;\n  justify-content: center;\n  padding: 50px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"spinner": `WarningModalDesign_spinner__8WJrf`
};
export default ___CSS_LOADER_EXPORT___;
