import { SVGProps } from "react";

interface Props extends SVGProps<SVGSVGElement> {
  color?: string; // Optional color prop
  height?: string | number; // Optional height prop
  width?: string | number; // Optional width prop
}

function CircleIcon({
  color = "currentColor",
  height = "20px",
  width = "20px",
  ...props
}: Props) {
  return (
    <svg
      {...props}
      height={height} // Set height based on props
      width={width} // Set width based on props
      version="1.1"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
      fill={color} // Use the color prop for fill color
    >
      <path
        d="M10,0 C4.5,0 0,4.5 0,10 C0,15.5 4.5,20 10,20 C15.5,20 20,15.5 20,10 C20,4.5 15.5,0 10,0 L10,0 Z M10,18 C5.6,18 2,14.4 2,10 C2,5.6 5.6,2 10,2 C14.4,2 18,5.6 18,10 C18,14.4 14.4,18 10,18 L10,18 Z"
        fill={color} // Use the color prop for the path fill
      />
    </svg>
  );
}

export default CircleIcon;
