import { SVGProps } from "react";

interface Props extends SVGProps<SVGSVGElement> {
  color?: string; // Optional color prop
  height?: string | number; // Optional height prop
  width?: string | number; // Optional width prop
}

function EllipseIcon({ width = 20, height = 20, color = "#245ED2" }: Props) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="10" cy="10" r="10" fill={color} />
    </svg>
  );
}

export default EllipseIcon;
