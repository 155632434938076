import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import Select from "react-select";

import Icons from "../../../assets/icons/Icons";
import { openSnackbar } from "../../../redux/snackbar";
import { getVacanciesCountForSegment } from "../../../services/campaign";
import { displayErrorSnackbar, isValidInput } from "../../../services/common";
import {
  createSegment,
  getSegmentFilters,
  getSegmentOptionsByFilter,
} from "../../../services/vacancySegment";
import { Button } from "../../Button/Button";
import SegmentConditions from "../SegmentConditions";

import styles from "./CreateSegment.module.css";

function CreateSegment(props: any) {
  const {
    onCreateSegment = () => {
      // Place Holder Comment
    },
    onDataFilledChange,
  } = props;

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const params = useParams();
  const clientId = params.id;
  if (clientId === undefined) throw Error("Invalid client");
  const [openSegmentBuilder, setOpenSegmentBuilder] = useState<boolean>(false);

  const [vacanciesCount, setVacanciesCount] = useState<number>(0);

  const [segment, setSegment] = useState<any>({
    name: "",
    status: true,
    segmentGroup: "Any",
  });

  const [segmentConditions, setSegmentConditions] = useState<any>([
    {
      conditionName: "",
      conditionList: [],
      condition: "",
      conditionContentList: [],
      conditionContent: "",
    },
  ]);
  const [completeConditions, setCompleteConditions] = useState<any>([]);

  const [availableVariables, setAvailableVariables] = useState<any>([]);

  const {
    mutate: createNewSegment,
    data: CreateSegmentRes,
    error: CreateSegmentError,
    isLoading: CreatingSegment,
    reset: ResetCreateSegment,
  } = useMutation((data: any) => createSegment(data), {
    onSuccess(data) {
      onCreateSegment(data?.data?.data);
      onDataFilledChange(false);
      setSegment({
        name: "",
        status: true,
        segmentGroup: "Any",
      });
      setSegmentConditions([]);
      setVacanciesCount(0);
    },
  });
  //this
  const {
    isError: segmentVacanciesCountError,
    refetch: getSegmentVacanciesCount,
  } = useQuery(
    ["segmentVacanciesCount", clientId, segment?.segmentGroup],
    () =>
      getVacanciesCountForSegment(
        clientId,
        segment?.segmentGroup,
        completeConditions,
        props.feedDays === null || props.feedDays === "" ? "" : props.feedDays,
      ),
    {
      enabled: false,
      onSuccess(data: any) {
        setVacanciesCount(data?.data?.vacancyCount);
      },
    },
  );

  useQuery("segmentFilters", () => getSegmentFilters(clientId), {
    onSuccess(data) {
      setAvailableVariables(data?.data);
    },
  });

  useEffect(() => {
    const filteredConditions: any = [];
    segmentConditions?.forEach((condition: any) => {
      if (
        isValidInput(condition?.conditionName) &&
        isValidInput(condition?.condition) &&
        isValidInput(condition?.conditionContent)
      ) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { conditionList, conditionContentList, ...rest } = condition;
        filteredConditions?.push(rest);
      }
    });
    setCompleteConditions(filteredConditions);
  }, [segmentConditions]);

  useEffect(() => {
    if (completeConditions?.length && clientId) {
      getSegmentVacanciesCount();
    }
  }, [completeConditions, clientId, segment?.segmentGroup]);

  useEffect(() => {
    if (CreateSegmentRes) {
      dispatch(
        openSnackbar({
          message: "Segment created successfully",
          type: "success",
        }),
      );
      setOpenSegmentBuilder(false);
      ResetCreateSegment();
      queryClient.refetchQueries("segments");
    }
  }, [CreateSegmentRes]);

  useEffect(() => {
    if (CreateSegmentError) {
      dispatch(
        openSnackbar({
          message: "Error while creating the segment",
          type: "error",
        }),
      );
    }
  }, [CreateSegmentError]);

  useEffect(() => {
    onDataFilledChange(false);
  }, [openSegmentBuilder]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSegment({
      ...segment,
      [e.target.name]: e.target.value,
    });
    onDataFilledChange(true);
  };

  const handleConditionChange = (key: string, value: any, index: number) => {
    const conditions = [...segmentConditions];
    conditions[index][key] = value;
    setSegmentConditions(conditions);
  };

  const handleCreateSegment = async () => {
    const { valid, message } = validateSegmentData();

    if (!valid) {
      displayErrorSnackbar(message);
      return;
    }

    createNewSegment({
      ...segment,
      segmentConditions: completeConditions,
    });
  };

  const validateSegmentData = () => {
    if (!segment?.name?.trim()) {
      return {
        valid: false,
        message: "Segment name is required",
      };
    }

    for (const segmentCondition of segmentConditions) {
      const { conditionName, condition, conditionContent } = segmentCondition;
      if (
        !conditionName?.trim() ||
        !condition?.trim() ||
        !conditionContent?.trim()
      ) {
        return { valid: false, message: "Missing condition variables" };
      }
    }

    return { valid: true, message: "" };
  };

  // const handleRefresh = () => {
  //   if (completeConditions?.length && clientId) {
  //     getSegmentVacanciesCount();
  //   }
  // };

  return (
    <>
      {!openSegmentBuilder ? (
        <div className=" my-3">
          <Button
            PrefixIcon={<Icons.AddIcon color="black" height={16} width={16} />}
            onClick={() => setOpenSegmentBuilder(true)}
            color="tertiary"
            variant="text"
          >
            {t("buildNewSegment")}
          </Button>
        </div>
      ) : (
        <div className="parent border-solid border-2 mb-5  w-full">
          <div className={`${styles.editorWrap} border-solid border-b-2`}>
            <span className={styles.label}>{t("segmentName")} *</span>
            <input
              name={"name"}
              type={"text"}
              className="w-[282px] border-2 rounded-[5px] h-10 "
              value={segment?.name}
              onChange={handleInputChange}
            />
          </div>

          <div className={`${styles.inputRow} ${styles.conditionGroupWrap}`}>
            <div className="flex items-center gap-2">
              <span className={styles.conditionGroupText}>
                {t("segmentWillFollow")}
              </span>
              <Select
                styles={{
                  // eslint-disable-next-line @typescript-eslint/no-unused-vars
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    borderColor: "grey",
                    borderRadius: "14px",
                    minWidth: "100px",
                    padding: "2.5px",
                  }),
                }}
                defaultValue={{ value: "Any", label: "Any" }}
                value={{
                  value: segment?.segmentGroup,
                  label: segment?.segmentGroup,
                }}
                options={[
                  { value: "Any", label: "Any" },
                  { value: "All", label: "All" },
                ]}
                onChange={(value: any) => {
                  setSegment({
                    ...segment,
                    segmentGroup: value?.value,
                  });
                }}
              />
              <span>{t("followingConditions")}</span>
            </div>
            {/* {segmentVacanciesCountSpinner || isFetching ? ( */}
            {/* <Spinner size={"24px"} /> */}
            {/* ) : ( */}
            <div className={styles.createSegmentVacancies}>
              <b>{(!segmentVacanciesCountError && vacanciesCount) || 0}</b>{" "}
              Vacancies in selection
              <Icons.CircleIcon />
            </div>
            {/* )} */}
          </div>

          <SegmentConditions
            availableVariables={availableVariables}
            getSegmentOptionsByFilter={getSegmentOptionsByFilter}
            handleConditionChange={handleConditionChange}
            segmentConditions={segmentConditions}
            setSegmentConditions={setSegmentConditions}
            styles={styles}
            propsData={{
              props,
              clientId: clientId,
            }}
          />

          <div className=" px-5">
            <Button
              onClick={() => {
                const conditions = [...segmentConditions];
                setSegmentConditions([
                  ...conditions,
                  {
                    conditionName: "",
                    condition: "",
                    conditionContent: "",
                  },
                ]);
              }}
              color="primary"
              variant="text"
            >
              <span className="font-semibold text-[#245ED2]">
                + &nbsp;{t("addNewVariable")}
              </span>
            </Button>
          </div>

          <div className={styles.stepFooterWrap}>
            <div className={styles.checkboxWrap}>
              <input
                className={styles.reusableInput}
                type={"checkbox"}
                name={"status"}
                checked={segment.status}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setSegment({ ...segment, status: e.target.checked })
                }
              />
              <label>Create reusable segment</label>
            </div>
            <div className={styles.stepFooter}>
              <Button
                color="primaryLight"
                onClick={handleCreateSegment}
                loading={CreatingSegment}
              >
                {t("save")}
              </Button>
              <Button
                color="tertiary"
                variant="outline"
                onClick={() => {
                  setOpenSegmentBuilder(false);
                  onDataFilledChange(false);
                }}
              >
                {t("cancel")}
              </Button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default CreateSegment;
