import React from "react";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";

import Icons from "../../../assets/icons/Icons";
import { updateCurrentConditionData } from "../../../handlers/segmenthandlers";
import { removeHtml } from "../../../services/common";
import { Button } from "../../Button/Button";

type SegmentConditionsProps = {
  styles: any;
  segmentConditions: any;
  loadingConditions?: boolean;
  availableVariables: any;
  handleConditionChange: any;
  getSegmentOptionsByFilter: any;
  setSegmentConditions: any;
  propsData: any;
};

const SegmentConditions = (props: Readonly<SegmentConditionsProps>) => {
  const {
    styles,
    segmentConditions,
    loadingConditions,
    availableVariables,
    handleConditionChange,
    getSegmentOptionsByFilter,
    setSegmentConditions,
    propsData,
  } = props;

  return (
    <div className={styles.segmentConditionsWrap}>
      {segmentConditions?.map((segmentCondition: any, index: number) => (
        <div
          className="flex flex-col  sm:flex-row gap-5 sm:gap-2 sm:items-center"
          key={`${"" + index}`}
        >
          <div className={"w-full sm:w-[37%] text-left"}>
            <Select
              styles={{
                control: (baseStyles) => ({
                  ...baseStyles,
                  borderColor: "gray",
                  borderRadius: "5px",
                  width: "full",
                  padding: "2.5px",
                }),
              }}
              theme={(theme) => ({
                ...theme,
                borderRadius: 0,
                colors: {
                  ...theme.colors,
                  primary25: "#CDCDCD",
                  primary: "#CDCDCD",
                },
              })}
              isLoading={loadingConditions}
              value={{
                value: segmentCondition?.conditionName,
                label: segmentCondition?.conditionName,
              }}
              options={availableVariables?.map((value: string) => ({
                value,
                label: value,
              }))}
              onChange={async (value: any) => {
                handleConditionChange("conditionName", value?.value, index);
                handleConditionChange("conditionContent", "", index);
                await updateCurrentConditionData(value?.value, index, {
                  getSegmentOptionsByFilter,
                  handleConditionChange,
                  propsData,
                });
              }}
            />
          </div>
          <div className={"w-full sm:w-[20%] text-left"}>
            <Select
              styles={{
                control: (baseStyles) => ({
                  ...baseStyles,
                  borderColor: "grey",
                  borderRadius: "60px",
                  padding: "2.5px",
                }),
              }}
              theme={(theme) => ({
                ...theme,
                borderRadius: 0,
                colors: {
                  ...theme.colors,
                  primary25: "#CDCDCD",
                  primary: "#CDCDCD",
                },
              })}
              isLoading={loadingConditions}
              value={segmentCondition?.conditionLabel}
              options={segmentCondition?.conditionList?.map((data: any) => ({
                value: data?.condition,
                label: data?.conditionText,
              }))}
              onChange={(value: any) => {
                handleConditionChange("condition", value?.value, index);
                handleConditionChange("conditionLabel", value, index);
              }}
            />
          </div>
          <div className="w-full sm:w-[37%] text-left">
            <CreatableSelect
              createOptionPosition={"first"}
              styles={{
                control: (baseStyles) => ({
                  ...baseStyles,
                  borderColor: "grey",
                  borderRadius: "5px",
                  padding: "2.5px",
                }),
              }}
              theme={(theme) => ({
                ...theme,
                borderRadius: 0,
                colors: {
                  ...theme.colors,
                  primary25: "#CDCDCD",
                  primary: "#CDCDCD",
                },
              })}
              isLoading={loadingConditions}
              value={{
                value: segmentCondition?.conditionContent,
                label: removeHtml(segmentCondition?.conditionContent),
              }}
              options={segmentCondition?.conditionContentList?.map(
                (value: string) => ({ value, label: removeHtml(value) }),
              )}
              onChange={(value: any) => {
                handleConditionChange("conditionContent", value?.value, index);
              }}
            />
          </div>
          {segmentConditions?.length > 1 && (
            <Button
              color="tertiary"
              variant="text"
              onClick={() => {
                const conditions = [...segmentConditions];
                conditions.splice(index, 1);
                setSegmentConditions(conditions);
              }}
            >
              <Icons.TrashIcon height={20} width={20} />
            </Button>
          )}
        </div>
      ))}
    </div>
  );
};

export default SegmentConditions;
