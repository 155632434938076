import { AxiosError } from "axios";
import { useState } from "react";
import { useMutation } from "react-query";
import Select from "react-select";

import { useFieldError } from "../../../hooks/useFieldError";
import useFormErrors from "../../../hooks/useFormErrors";
import {
  deleteClientFeedV2,
  updateClientFeedV2,
} from "../../../services/clients";
import { feedFormatOptions } from "../../../services/feeds";
import { FeedType, UpdateFeedDto } from "../../../types/client/ClientTypes";
import { Button } from "../../Button/Button";
import ConfirmationModal from "../../ConfirmationModal/ConfirmationModal";
import Spinner from "../../Spinner/Spinner";
import { FormItem } from "../FormItem";

interface EditClientFeedProps {
  clientId: string;
  feed: FeedType;
  onFeedDeleted: (id: number) => void;
  onFeedUpdated: (feed: FeedType) => void;
}

const EditClientFeed: React.FC<EditClientFeedProps> = ({
  clientId,
  feed,
  onFeedDeleted,
  onFeedUpdated,
}) => {
  const [isEditing, setIsEditing] = useState(feed.id === undefined);
  const { setFormErrors, removeFormError } = useFormErrors();
  const [formData, setFormData] = useState<UpdateFeedDto>({
    feedName: feed.feedName,
    id: +feed.id,
  });
  const [openModal, setOpenModal] = useState<boolean>(false);

  const {
    mutate: updateFeed,
    isLoading,
    error: updateFeedError,
  } = useMutation((id: number) => updateClientFeedV2(formData, clientId, id), {
    onSuccess(response) {
      setIsEditing(false);
      onFeedUpdated(response.data);
    },
    onError(e: AxiosError) {
      setFormErrors(e.response?.data);
    },
  });

  const { getFieldError } = useFieldError(updateFeedError);

  const { mutate: deleteFeed, isLoading: isdeletingFeed } = useMutation(
    (id: number) => deleteClientFeedV2(clientId, id),
    {
      onSuccess(response) {
        onFeedDeleted(response.data.id);
      },
    },
  );

  const handleInputChange = (key: string, value: any) => {
    removeFormError(key);
    setFormData({
      ...formData,
      [key]: value,
    });
  };

  const handleCancel = () => {
    setIsEditing(false);
    setFormData({
      feedName: feed.feedName,
      id: +feed.id,
    });
  };

  const handleSave = () => {
    updateFeed(+feed.id);
  };

  const handleDelete = () => {
    setOpenModal(true);
  };

  return (
    <div
      className={`bg-[#F8F7FC] border border-[#F8F7FC] rounded p-5 shadow-sm w-full relative`}
    >
      {isLoading && (
        <Spinner
          size={50}
          className=" absolute z-50 w-full h-full flex justify-center items-center  bg-opacity-50"
        />
      )}
      <div className="flex justify-between mb-3">
        <label className="text-black font-semibold text-sm uppercase">
          {formData.feedName}
        </label>
        <div className="flex items-center gap-4">
          {isEditing ? (
            <>
              <Button
                onClick={handleCancel}
                color="secondary"
                variant="text"
                size="small"
              >
                Cancel
              </Button>

              <Button
                onClick={handleSave}
                color="secondary"
                variant="text"
                size="small"
              >
                Save
              </Button>
            </>
          ) : (
            <Button
              color="secondary"
              variant="text"
              type="button"
              size="small"
              onClick={() => setIsEditing(true)}
            >
              Edit
            </Button>
          )}
          |
          <Button
            color="secondary"
            variant="text"
            onClick={handleDelete}
            type="button"
            size="small"
          >
            Delete
          </Button>
        </div>
      </div>
      <div className="flex flex-col lg:flex-row gap-5">
        <FormItem label="Name" error={getFieldError("feedName")}>
          <input
            type="text"
            name="feedName"
            value={formData.feedName}
            onChange={(e) => handleInputChange("feedName", e.target.value)}
            className={`disabled:bg-[#F2F2F2] border outline-none bg-white rounded p-2 text-[#2A263F] text-base`}
            disabled={!isEditing}
          />
        </FormItem>
      </div>
      <div className="flex flex-col lg:flex-row gap-5 mt-5">
        <FormItem label="Feed Url" error={getFieldError("feedUrl")}>
          <input
            type="url"
            name="feedUrl"
            value={feed.feedUrl}
            className={`disabled:bg-[#F2F2F2] border outline-none bg-white rounded p-2 text-[#2A263F] text-base`}
            disabled={true}
          />
        </FormItem>
        <FormItem label="Feed Format" error={getFieldError("feedFormat")}>
          <Select
            value={feedFormatOptions.find(
              (item) => item.value === feed.feedFormat,
            )}
            options={feedFormatOptions}
            styles={{
              control: (baseStyles) => ({
                ...baseStyles,
                borderColor: "#E5EAF0",
                borderRadius: "5px",
                outline: "none",
                fontSize: "16px",
              }),
            }}
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary25: "#CDCDCD",
                primary: "#CDCDCD",
              },
            })}
            isDisabled={true}
          />
        </FormItem>
      </div>
      {openModal && (
        <ConfirmationModal
          heading={"Delete Client Feed"}
          messageContent={
            <>
              All vacancies and all vacancies currently in campaign of this feed
              will be deleted
            </>
          }
          isOpen={openModal}
          onClose={() => {
            setOpenModal(false);
          }}
          handleClick={() => {
            deleteFeed(+feed.id);
          }}
          isLoading={isdeletingFeed}
          modalType="delete"
        />
      )}
    </div>
  );
};

export default EditClientFeed;
