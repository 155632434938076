import { AxiosError } from "axios";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { Button } from "../../components/Button/Button";
import {
  Checkbox,
  NumberInput,
  SelectInput,
  TextInput,
  UrlInput,
} from "../../components/FormFields/FormFields";
import useFormErrors from "../../hooks/useFormErrors";
import { setClient } from "../../redux/client";
import { routeNames } from "../../router/routeNames";
import { addClientV2 } from "../../services/clients";
import { ClientType } from "../../types/client/ClientTypes";

const ClientInfo = () => {
  const dispatch = useDispatch();

  const { t } = useTranslation();
  const navigate = useNavigate();
  const { errors, setFormErrors, removeFormError } = useFormErrors();
  const handleInputChange = (key: string, value: any) => {
    removeFormError(key);
    dispatch(setClient({ name: key, value: value }));
  };

  const clientData: ClientType = useSelector((state: any) => state.client);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    AddClientMutate();
  };

  const { mutate: AddClientMutate, isLoading: addClientLoader } = useMutation(
    () => {
      return addClientV2(clientData);
    },
    {
      onSuccess(data) {
        navigate(`/${routeNames.EDIT_CLIENT}/${data.data.id}`);
      },
      onError(e: AxiosError) {
        setFormErrors(e.response?.data);
      },
    },
  );

  return (
    <form className="mt-10 grid grid-cols-1 gap-5" onSubmit={handleSubmit}>
      <div className=" grid sm:grid-cols-2 gap-8">
        <TextInput
          label="Name"
          name="name"
          value={clientData.name}
          handleChange={(e) => handleInputChange("name", e.target.value)}
          errors={errors["name"]}
        />
        <SelectInput
          label="For"
          name="for"
          value={
            clientData.for
              ? {
                  value: clientData.for,
                  label: clientData.for,
                }
              : undefined
          }
          options={[
            { value: "Prepaid", label: "Prepaid" },
            { value: "Postpaid", label: "Postpaid" },
          ]}
          handleChange={(selectedOption: any) =>
            handleInputChange("for", selectedOption?.value)
          }
        />
      </div>
      <div className="grid sm:grid-cols-2 gap-8">
        <TextInput
          label={t("Logo URL")}
          name="imagePath"
          value={clientData.imagePath}
          handleChange={(e) => handleInputChange("imagePath", e.target.value)}
        />
      </div>
      <div className="grid sm:grid-cols-2 gap-8">
        <UrlInput
          label="RecruitNow URL"
          sublabel="(https://example.recruitnowcockpit.nl)"
          name="recruitNowUrl"
          value={clientData.recruitNowUrl}
          handleChange={(e) =>
            handleInputChange("recruitNowUrl", e.target.value)
          }
          errors={errors["recruitNowUrl"]}
        />
        <TextInput
          label="RecruitNow API Key"
          name="recruitNowApiKey"
          value={clientData.recruitNowApiKey}
          handleChange={(e) =>
            handleInputChange("recruitNowApiKey", e.target.value)
          }
          errors={errors["recruitNowApiKey"]}
        />
      </div>
      <div className="grid sm:grid-cols-2 gap-8">
        <Checkbox
          checked={clientData.marginIncluded}
          handleChange={(e) =>
            handleInputChange("marginIncluded", e.target.checked)
          }
          label="Include Margin"
          name="marginIncluded"
        />
        <Checkbox
          checked={clientData.invoiceOnlineResult}
          handleChange={(e) =>
            handleInputChange("invoiceOnlineResult", e.target.checked)
          }
          label="Invoice OnlineResults"
          name="invoiceOnlineResult"
        />
      </div>
      <div className="grid sm:grid-cols-2 gap-8">
        <Checkbox
          checked={clientData.webhookEnabled}
          handleChange={(e) =>
            handleInputChange("webhookEnabled", e.target.checked)
          }
          label="RecruitNow Webhooks Enabled"
          name="webhookEnabled"
        />
        <Checkbox
          handleChange={(e) =>
            handleInputChange("overspendAllowed", e.target.checked)
          }
          label="Overspend Allowed"
          checked={clientData.overspendAllowed}
          name="overspendAllowed"
        />
      </div>
      {clientData.overspendAllowed && (
        <div className="grid sm:grid-cols-2 gap-8">
          <NumberInput
            handleChange={(e) =>
              handleInputChange("percentage", +e.target.value)
            }
            label="Percentage"
            name="percentage"
            value={clientData.percentage}
          />
        </div>
      )}

      <Button
        color="primaryLight"
        fullWidth
        size="large"
        type="submit"
        loading={addClientLoader}
      >
        Save & Continue
      </Button>
    </form>
  );
};

export default ClientInfo;
