import { SVGProps } from "react";

interface EyeOpenIconProps extends SVGProps<SVGSVGElement> {
  color?: string; // Optional color prop
  height?: string | number; // Optional height prop
  width?: string | number; // Optional width prop
}

function EyeOpenIcon({
  color = "currentColor",
  height = "20",
  width = "20",
  ...props
}: EyeOpenIconProps) {
  return (
    <svg
      {...props}
      height={height} // Use the height prop
      width={width} // Use the width prop
      fill={color} // Use the color prop
      viewBox="0 0 16 16"
    >
      <path d="m10.79 12.912-1.614-1.615a3.5 3.5 0 0 1-4.474-4.474l-2.06-2.06C.938 6.278 0 8 0 8s3 5.5 8 5.5a7.029 7.029 0 0 0 2.79-.588zM5.21 3.088A7.028 7.028 0 0 1 8 2.5c5 0 8 5.5 8 5.5s-.939 1.721-2.641 3.238l-2.062-2.062a3.5 3.5 0 0 0-4.474-4.474L5.21 3.089z" />
      <path d="M5.525 7.646a2.5 2.5 0 0 0 2.829 2.829l-2.83-2.829zm4.95.708-2.829-2.83a2.5 2.5 0 0 1 2.829 2.829zm3.171 6-12-12 .708-.708 12 12-.708.708z" />
    </svg>
  );
}

export default EyeOpenIcon;
