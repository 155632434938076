import { SVGProps } from "react";

interface StatusUnactiveIconProps extends SVGProps<SVGSVGElement> {
  height?: string | number;
  width?: string | number;
  color?: string; // Add a color prop
}

function StatusUnactiveIcon({
  height = "16",
  width = "16",
  color = "red",
  ...props
}: StatusUnactiveIconProps) {
  return (
    <svg
      {...props}
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="8" cy="8" r="8" fill={color} />{" "}
      {/* Use the color prop for the fill */}
    </svg>
  );
}

export default StatusUnactiveIcon;
