import { Dialog, DialogPanel, DialogTitle } from "@headlessui/react";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Select from "react-select";

import Icons from "../../assets/icons/Icons";
import { openSnackbar } from "../../redux/snackbar";
import { routeNames } from "../../router/routeNames";
import { getBudgetPlanningType } from "../../services/budget";
import {
  createCampaign,
  getVacanciesCountByClientAndFeedDays,
} from "../../services/campaign";
import {
  displayErrorSnackbar,
  formatDateForDateInput,
  invalidInputMessage,
  isValidInput,
} from "../../services/common";
import { getPlannedJobboards } from "../../services/jobBoard";
import {
  getVacanciesbySegment,
  getVacanciesbySegments,
} from "../../services/segments";
import {
  getAllSegments,
  getSegmentByName,
} from "../../services/vacancySegment";
import BudgetCard from "../BudgetCard/BudgetCard";
import { Button } from "../Button/Button";
import KPISettingsNew from "../KPISettings/KPISettings";
import CreateSegmentNew from "../Segment/CreateSegment/CreateSegment";
import EditSegmentNew from "../Segment/EditSegment/EditSegment";

import styles from "./CreateCampaign.module.css";

export default function CreateCampaign(props: any) {
  const { isOpen, onClose, existingCampaignData, vacanciesCount } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { id: clientId } = useParams();
  if (clientId === undefined) throw Error("Invalid client");

  const [campaignData, setCampaignData] = useState<any>({
    clientID: clientId,
    segmentID: "",
    name: "",
    type: "CPC", // Default type
    budget: "",
    endDate: "",
    date: new Date(),
    status: true,
    feedDays: null,
    lmh: "",
    publish: true,
    campaignJobBoard: [],
    vacancyIds: [],
    paused: false,
    campaignSegment: [],
  });
  const [currentYear, setCurrentYear] = useState<number | null>(null);
  const [currentMonth, setCurrentMonth] = useState<number | null>(null);
  const [selectedSegment, setSelectedSegment] = useState<any>([]);
  const [segments, setSegments] = useState<any>([]);
  const [selectedMonth, setSelectedMonth] = useState<number | null>(null);
  const [selectedYear, setSelectedYear] = useState<number | null>(null);
  const [nonReusableSegments, setNonReusableSegments] = useState<any[]>([]);
  const [vacanciesIds, setVacanciesIds] = useState<Array<number>>([]);
  const [openVacanciesDetailModal, setOpenVacanciesDetailModal] =
    useState<boolean>(false);
  const campaignID = "";

  const [vacancies, setVacancies] = useState<any>({
    vacancyCount: 0,
    jobVacancy: [],
  });

  const [totNewVacancies, setTotNewVacancies] = useState<any>({
    vacancyCount: 0,
    jobVacancy: [],
  });

  const [jobBoards, setJobBoards] = useState<any>([]);

  const [openStep, setOpenStep] = useState<number | null>(0);
  const [pageDetails, setPageDetails] = useState<any>({
    pageNumber: 1,
    pageSize: 25,
  });

  //new
  const [inputValue, setInputValue] = useState<any>("");

  const [formStatus, setFormStatus] = useState<any>({
    vacanciesFeeds: false,
    jobBoards: false,
  });

  const [check, setCheck] = useState<any>(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [dataFilled, setDataFilled] = useState<boolean>(false);

  const [kpiSelected, setKpiSelected] = useState<boolean>(false);

  useEffect(() => {
    const selectedSegmentArray = selectedSegment?.map(
      (item: any) => item.value,
    );
    if (selectedSegmentArray.length > 0) {
      getVacanciesIdBySegmentsId(selectedSegmentArray);
    }
  }, [selectedSegment.length]);

  const { mutate: getVacanciesIdBySegmentsId } = useMutation(
    ["segmentVacanciesByIDS", selectedSegment?.map((item: any) => item.value)],
    (selectedSegmentArray: any) =>
      getVacanciesbySegments(
        clientId,
        selectedSegmentArray,
        campaignData.feedDays === null || campaignData.feedDays === ""
          ? ""
          : campaignData.feedDays,
      ),
    {
      onSuccess: (data: any) => {
        setVacanciesIds(data?.data?.allVacancyIds);
      },
    },
  );

  const { data: PlanningType, isLoading: fetchingPlanningType } = useQuery(
    ["budgetPlanningTypes"],
    getBudgetPlanningType,
    {
      onSuccess(data) {
        setCampaignData({
          ...campaignData,
          lmh: data?.data?.[0]?.typeName,
        });
      },
    },
  );

  const { data: SegmentsData, refetch: fetchSegments } = useQuery(
    ["segments", clientId, pageDetails?.pageNumber, pageDetails?.pageSize],
    () => getAllSegments(pageDetails?.pageNumber, pageDetails?.pageSize),
    {
      onSuccess(data) {
        const newArray = [];
        for (const segment of data.data.data) {
          const existingElement = segments.find(
            (element: any) => element?.value === segment?.id,
          );

          if (!existingElement) {
            newArray.push({
              value: segment?.id,
              label: segment?.name,
            });
          }
        }
        setSegments([...segments, ...newArray]);
      },
    },
  );

  const { refetch: fetchSegmentsByName } = useQuery(
    ["segmentName", inputValue],
    () => getSegmentByName(inputValue),
    {
      enabled: false,
      onSuccess(data: any) {
        const newArray = [];
        for (const segment of data.data) {
          const existingElement = segments.find(
            (element: any) => element?.value === segment?.id,
          );

          if (!existingElement) {
            newArray.push({
              value: segment?.id,
              label: segment?.name,
            });
          }
        }
        setSegments([...segments, ...newArray]);
      },
    },
  );

  const { data: PlannedJobBoardsData, refetch: fetchPlannedJobBoards } =
    useQuery(
      ["jobBoards", selectedYear, selectedMonth, clientId],
      () => getPlannedJobboards(selectedYear, selectedMonth, clientId),
      { enabled: false },
    );

  useEffect(() => {
    if (currentYear && selectedMonth) {
      fetchPlannedJobBoards();
    }
  }, [currentYear && selectedMonth]);

  const { refetch: fetchVacanciesCountByclientFeedDays } = useQuery(
    ["totNewVacancies", campaignData.feedDays],
    () =>
      getVacanciesCountByClientAndFeedDays(
        clientId,
        campaignData.feedDays === null || campaignData.feedDays === ""
          ? ""
          : campaignData.feedDays,
        campaignID,
      ),
    {
      enabled: false,
      onSuccess: (data) => {
        setTotNewVacancies({
          ...totNewVacancies,
          vacancyCount: data.data.vacancyCount,
        });
      },
    },
  );

  useEffect(() => {
    if (clientId) {
      fetchVacanciesCountByclientFeedDays();
    }
  }, [clientId, campaignData.feedDays, selectedSegment]);

  const { data: vacanciesData, refetch: fetchVacancies } = useQuery(
    ["segmentVacancies", selectedSegment?.value],
    () =>
      getVacanciesbySegment(
        clientId,
        selectedSegment.value,
        campaignData.feedDays === null || campaignData.feedDays === ""
          ? ""
          : campaignData.feedDays,
      ),
    { enabled: false },
  );

  useEffect(() => {
    setCurrentYear(new Date().getFullYear());
    setCurrentMonth(new Date().getMonth() + 1);
  }, []);

  const {
    mutate: createNewCampaign,
    data: CreateCampaignData,
    error: CreateCampaignError,
    isLoading: CreatingCampaign,
    reset: ResetCreateCampaign,
  } = useMutation((data: any) => createCampaign(data));

  useEffect(() => {
    if (!existingCampaignData) {
      if (selectedSegment?.value) {
        fetchVacancies();
      }
    }
  }, [selectedSegment, campaignData.feedDays]);

  useEffect(() => {
    if (vacancies?.jobVacancy?.length) {
      const vacancyIds = vacancies?.jobVacancy?.map(
        (vacancy: any) => vacancy?.id,
      );

      setCampaignData({
        ...campaignData,
        vacancyIds,
      });
    }
  }, [vacancies]);

  useEffect(() => {
    if (CreateCampaignData) {
      dispatch(
        openSnackbar({
          message: CreateCampaignData?.data?.message,
          type: "success",
        }),
      );
      ResetCreateCampaign();
      queryClient.refetchQueries("client");
      queryClient.refetchQueries("clientCampaigns");
      redirectToClientCampaigns();
      onClose();
    }
  }, [CreateCampaignData]);

  useEffect(() => {
    if (CreateCampaignError) {
      dispatch(
        openSnackbar({
          message:
            "Error while creating campaign. Make sure you've entered correct data",
          type: "error",
        }),
      );
    }
  }, [CreateCampaignError]);

  useEffect(() => {
    if (vacanciesData) {
      setVacancies(vacanciesData?.data);
    }
  }, [vacanciesData]);

  useEffect(() => {
    if (existingCampaignData?.clientFeedID) {
      setVacancies({
        vacancyCount: vacanciesCount,
        jobVacancy: [],
      });
      setCampaignData(existingCampaignData);
    }
  }, [existingCampaignData]);

  useEffect(() => {
    const boards = jobBoards.map((element: any) => {
      return {
        jobBoardID: element?.jobboardId,
        jobboardBudgetAmount: {
          budgetPlanningJobboardId: element?.id,
          name: element.jobboardName,
          year: currentYear,
          month: currentMonth,
        },
      };
    });
    setCampaignData({
      ...campaignData,
      campaignJobBoard: boards,
    });
  }, [currentYear, currentMonth, jobBoards]);

  useEffect(() => {
    if (pageDetails) {
      fetchSegments();
    }
  }, [pageDetails]);

  useEffect(() => {
    if (inputValue) {
      fetchSegmentsByName();
    }
  }, [inputValue]);

  useEffect(() => {
    let completeJobBoards = false;

    if (jobBoards?.length) {
      completeJobBoards = true;
    }

    setFormStatus({
      jobBoards: completeJobBoards,
    });
  }, [jobBoards]);

  const onInputChange = (e: any) => {
    e.preventDefault();
    setCampaignData({
      ...campaignData,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    setJobBoards([]);
  }, [selectedMonth, selectedYear]);

  useEffect(() => {
    const date = new Date(campaignData.date);
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    setSelectedMonth(month);
    setSelectedYear(year);
  }, [campaignData.date]);

  const getSelectedSegments = () => {
    return selectedSegment.map((segment: any) => {
      return {
        segmentId: segment.value,
        segment: {
          segmentConditions: [],
        },
      };
    });
  };

  const handleCreateCampaign = () => {
    setCheck(true);
    if (CreatingCampaign) {
      return;
    }
    const { valid, message } = validateCampaignForm();
    if (!valid) {
      displayErrorSnackbar(message);
      return;
    }

    createNewCampaign({
      ...campaignData,
      paused: false,
      campaignSegment: getSelectedSegments(),
      vacancyIds: vacanciesIds,
    });
  };

  const validateCampaignForm = () => {
    const { name, campaignJobBoard } = campaignData;
    if (!isValidInput(name) || !campaignJobBoard?.length) {
      return invalidInputMessage("All fields with * are required");
    }

    return { valid: true, message: "" };
  };

  const redirectToClientCampaigns = () => {
    navigate(`${routeNames.CLIENT}/${clientId}`);
  };

  const onOpenStep = (index: number) => {
    const step = openStep === index ? null : index;
    setOpenStep(step);
  };

  const handleBottom = () => {
    if (SegmentsData?.data?.pageNumber <= SegmentsData?.data?.totalPages) {
      setPageDetails({
        pageNumber: SegmentsData?.data?.pageNumber + 1,
        pageSize: SegmentsData?.data?.pageSize,
      });
    }
  };

  const handleChange = (newValue: any) => {
    setInputValue(newValue);
  };

  const addNonUsableSegments = (segment: any) => {
    const existingElement = segments.find(
      (element: any) => element?.value === segment?.id,
    );
    const newSegment = {
      value: segment?.id,
      label: segment?.name,
      ...segment,
    };

    if (!existingElement) {
      setNonReusableSegments([newSegment, ...nonReusableSegments]);
    }
  };

  const updateNonReusableSegment = (segment: any) => {
    const existingSegments = nonReusableSegments?.filter(
      (element: any) => element?.value !== segment?.id,
    );
    const updatedSegment = {
      value: segment?.id,
      label: segment?.name,
      ...segment,
    };

    setNonReusableSegments([updatedSegment, ...existingSegments]);
  };

  const errorColor = "#f00";
  const neutralColor = "#rgb(233 233 233)";

  const handleCheckDataSaved = (value: any) => {
    setDataFilled(value);
  };

  const handlePublish = (selected: any) => {
    setCampaignData({
      ...campaignData,
      publish: selected.value,
    });
  };

  return (
    <Dialog open={isOpen} onClose={onClose} className={""}>
      <DialogPanel
        className={`w-full max-w-4xl  overflow-y-auto bg-white p-6 shadow-xl transition-all fixed top-0 right-0   h-full
          ${!openVacanciesDetailModal && "z-[12]"}
        `}
      >
        <DialogTitle
          as="h3"
          className="font-semibold text-2xl flex justify-between items-center"
        >
          <span>Build a New Campaign</span>
          <button onClick={onClose}>
            <Icons.CloseIcon />
          </button>
        </DialogTitle>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-5 mt-8">
          <div className="flex flex-col">
            <span className="font-plus-jakarta text-sm font-semibold text-[#42526E]">
              Campaign Name
            </span>
            <input
              className=" border-2 rounded-[5px] h-10 bg-transparent focus:outline-none pl-2"
              name={"name"}
              type={"text"}
              value={campaignData?.name}
              onChange={onInputChange}
              style={{
                borderColor:
                  check && !campaignData.name ? errorColor : neutralColor,
              }}
            />
          </div>

          <div className="flex flex-col">
            <span className="font-plus-jakarta text-sm font-semibold text-[#42526E]">
              Publish Campaign
            </span>
            <Select
              styles={{
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  borderRadius: "5px",
                  // minWidth: "282px",
                  padding: "2.5px",
                }),
              }}
              theme={(theme) => ({
                ...theme,
                borderRadius: 0,
                colors: {
                  ...theme.colors,
                  primary25: "#CDCDCD",
                  primary: "#CDCDCD",
                },
              })}
              options={[
                { value: true, label: "yes" },
                { value: false, label: "No" },
              ]}
              value={{
                value: campaignData?.publish,
                label: campaignData?.publish ? "Yes" : "No",
              }}
              onChange={handlePublish}
            />
          </div>
        </div>
        <div className="flex flex-col mt-4">
          <span className="font-plus-jakarta text-sm font-semibold text-[#42526E]">
            Feed Days
          </span>
          <input
            className="w-full border-2 rounded-[5px] h-10 bg-transparent focus:outline-none pl-2"
            name={"feedDays"}
            type="number"
            value={campaignData?.feedDays}
            onChange={onInputChange}
          />
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 gap-5 mt-4">
          <div className="flex flex-col">
            <span className="font-plus-jakarta text-sm font-semibold text-[#42526E]">
              Campaign Start Date
            </span>
            <input
              className=" border-2 rounded-[5px]	 h-10 "
              type={"date"}
              name={"date"}
              value={formatDateForDateInput(campaignData?.date)}
              onChange={onInputChange}
              placeholder=""
              required
            />
          </div>

          <div className="flex flex-col">
            <span className="font-plus-jakarta text-sm font-semibold text-[#42526E]">
              Campaign End Date
            </span>
            <input
              className="border-2 rounded-[5px]  h-10 	"
              type={"date"}
              name={"endDate"}
              value={campaignData?.endDate}
              onChange={onInputChange}
              placeholder=""
              required
            />
          </div>
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 gap-5 mt-4">
          <div className="flex flex-col">
            <span className="font-plus-jakarta text-sm font-semibold text-[#42526E]">
              Type
            </span>
            <Select
              styles={{
                control: (baseStyles) => ({
                  ...baseStyles,
                  borderRadius: "5px",
                  // minWidth: "282px",
                  padding: "2.5px",
                }),
              }}
              theme={(theme) => ({
                ...theme,
                borderRadius: 0,
                colors: {
                  ...theme.colors,
                  primary25: "#CDCDCD",
                  primary: "#CDCDCD",
                },
              })}
              value={{
                value: campaignData?.type,
                label: campaignData?.type,
              }}
              options={[
                { value: "CPC", label: "CPC" },
                { value: "CPA", label: "CPA" },
                { value: "Organic", label: "Organic" },
                { value: "CPM", label: "CPM" },
              ]}
              onChange={(selected: any) =>
                setCampaignData({
                  ...campaignData,
                  type: selected?.value,
                })
              }
              placeholder={"Campaign type"}
            />
          </div>
          <div className="flex flex-col">
            <span className="font-plus-jakarta text-sm font-semibold text-[#42526E]">
              LMH
            </span>
            <Select
              styles={{
                control: (baseStyles) => ({
                  ...baseStyles,
                  borderRadius: "5px",
                  // minWidth: "282px",
                  padding: "2.5px",
                }),
              }}
              theme={(theme) => ({
                ...theme,
                borderRadius: 0,
                colors: {
                  ...theme.colors,
                  primary25: "#CDCDCD",
                  primary: "#CDCDCD",
                },
              })}
              isLoading={fetchingPlanningType}
              value={{
                value: campaignData?.lmh,
                label: campaignData?.lmh,
              }}
              options={PlanningType?.data?.map((type: any) => {
                return {
                  value: type?.typeName,
                  label: type?.typeName,
                };
              })}
              onChange={(selected: any) =>
                setCampaignData({
                  ...campaignData,
                  lmh: selected?.value,
                })
              }
              placeholder={"Budget type"}
            />
          </div>
        </div>

        {/* section Tracking */}
        <div className="campaignBodyContainer flex flex-col">
          <div className="camapignStep mt-8">
            <div className="border-solid border-2 h-11 opacity-50">
              <div className="stepTitleWrap flex justify-start items-center gap-[30px] pt-2 pl-2">
                {!formStatus?.tracking && <Icons.EllipseIcon />}

                {formStatus?.tracking && (
                  <Icons.CircleIcon height={20} width={20} color="gray" />
                )}

                <span>Tracking</span>
              </div>
            </div>
          </div>

          <div
            className="line rounded mx-4 my-1 bg-[#E1DFEC]"
            style={{
              height: "35px",
              width: "2px",
            }}
          ></div>

          {/* section Budget */}
          <div className="camapignStep">
            <div className="border-solid border-2 h-11 opacity-50">
              <div className="stepTitleWrap flex justify-start items-center gap-[30px] pt-2 pl-2">
                {!formStatus?.budget && <Icons.EllipseIcon />}

                {formStatus?.budget && (
                  <Icons.CircleIcon height={20} width={20} color="gray" />
                )}
                <span>Budget</span>
              </div>
            </div>
          </div>

          <div
            className="line rounded mx-4 my-1 bg-[#E1DFEC]"
            style={{
              height: "35px",
              width: "2px",
            }}
          ></div>

          {openStep === 1 && (
            <div className={styles.stepBody}>
              <div className={`${styles.inputRow} ${styles.inputRowBudget}`}>
                <div>
                  <BudgetCard />
                </div>
                <div>
                  <Icons.CirclePlusIcon width={50} height={50} color="gray" />
                </div>
              </div>
            </div>
          )}
        </div>
        {/* section feed */}
        <div className="campaignStep">
          <div
            className="stepHeader border-solid border-2 cursor-pointer "
            style={{ minHeight: "45px" }}
          >
            <button
              className="flex items-center flex-wrap w-full justify-between pt-2 pl-2 "
              onClick={() => onOpenStep(2)}
            >
              <div className="flex items-center gap-7">
                {formStatus?.vacanciesFeeds ? (
                  <Icons.EllipseIcon />
                ) : (
                  <Icons.CircleIcon color="gray" width={20} height={20} />
                )}
                <h2
                  className={
                    formStatus?.vacanciesFeeds && styles.completeSection
                  }
                >
                  Vacancies Feeds
                </h2>
              </div>
              <div className="mr-4">
                <b>
                  {selectedSegment.length > 0
                    ? vacanciesIds.length
                    : totNewVacancies?.vacancyCount || 0}
                </b>{" "}
                Vacancies In Selection
              </div>
            </button>

            {openStep === 2 && (
              <div className={styles.stepBody}>
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-5 my-8">
                  <div className="flex flex-col">
                    <label className="font-plus-jakarta text-sm font-semibold text-[#42526E]">
                      Segment
                    </label>
                    <Select
                      styles={{
                        control: (baseStyles) => ({
                          ...baseStyles,
                          borderRadius: "5px",
                          padding: "2.5px",
                        }),
                      }}
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: 0,
                        colors: {
                          ...theme.colors,
                          primary25: "#CDCDCD",
                          primary: "#CDCDCD",
                        },
                      })}
                      onMenuScrollToBottom={handleBottom}
                      isClearable
                      value={selectedSegment}
                      options={[...nonReusableSegments, ...segments]}
                      onChange={(selected: any) => {
                        setSelectedSegment(selected);
                      }}
                      isMulti
                      onInputChange={handleChange}
                      placeholder={"Select segment"}
                    />
                  </div>
                </div>

                <EditSegmentNew
                  feedDays={campaignData.feedDays}
                  selectedSegments={selectedSegment}
                  onEditSegment={(newSegment: any) => {
                    updateNonReusableSegment(newSegment);
                  }}
                  vacanciesIds={vacanciesIds}
                  openVacanciesDetailModal={openVacanciesDetailModal}
                  setOpenVacanciesDetailModal={setOpenVacanciesDetailModal}
                />

                <CreateSegmentNew
                  feedDays={campaignData.feedDays}
                  selectedSegments={selectedSegment}
                  onCreateSegment={(newSegment: any) => {
                    const oldSegmentIds = selectedSegment;
                    oldSegmentIds.push({
                      value: newSegment.id,
                      label: newSegment.name,
                    });
                    setSelectedSegment(oldSegmentIds);

                    addNonUsableSegments(newSegment);
                  }}
                  onDataFilledChange={handleCheckDataSaved}
                />
              </div>
            )}
          </div>
        </div>

        <div
          className={`line rounded mx-4 my-1 ${
            formStatus?.vacanciesFeeds ? "bg-[#9FA9FF]" : "bg-[#E1DFEC]"
          }`}
          style={{
            height: "35px",
            width: "2px",
          }}
        ></div>

        {/* section channel */}
        <div className="campaignStep ">
          <div
            className="stepHeader border-solid border-2 cursor-pointer"
            style={{ minHeight: "45px" }}
          >
            <button
              className="stepTitleWrap flex justify-start items-center gap-[30px] pt-2 pl-2"
              onClick={() => onOpenStep(3)}
            >
              {formStatus?.jobBoards && <Icons.EllipseIcon />}
              {!formStatus?.jobBoards && (
                <Icons.CircleIcon color="gray" width={20} height={20} />
              )}
              <span
                className={formStatus?.jobBoards ? styles.completeSection : ""}
              >
                Channels
              </span>
            </button>

            {openStep === 3 && (
              <div className={styles.stepBody}>
                <div className={styles.inputRow}>
                  <div className={styles.stepInputWrap}>
                    <label className="font-semibold text-sm">Existing *</label>
                    <div className={styles.menulist}>
                      <Select
                        styles={{
                          control: (baseStyles) => ({
                            ...baseStyles,
                            borderColor:
                              check && !campaignData?.campaignJobBoard?.length
                                ? errorColor
                                : neutralColor,
                            borderRadius: "5px",
                            minWidth: "382px",
                            padding: "2.5px",
                            height: "40px",
                          }),
                        }}
                        theme={(theme) => ({
                          ...theme,
                          borderRadius: 0,
                          colors: {
                            ...theme.colors,
                            primary25: "#CDCDCD",
                            primary: "#CDCDCD",
                          },
                        })}
                        value={jobBoards}
                        options={PlannedJobBoardsData?.data?.map(
                          (jobBoard: any) => {
                            return {
                              ...jobBoard,
                              value: jobBoard?.jobboardId,
                              label: jobBoard?.jobboardName,
                            };
                          },
                        )}
                        isMulti
                        onChange={(value) => setJobBoards(value)}
                        placeholder={"Select channels"}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>

        <div
          className={`line rounded mx-4 my-1 ${
            formStatus?.jobBoards ? "bg-[#9FA9FF]" : "bg-[#E1DFEC]"
          }`}
          style={{
            height: "35px",
            width: "2px",
          }}
        ></div>

        {/* section kpi */}
        <div className="campaignStep ">
          <div
            className="stepHeader border-solid border-2 cursor-pointer"
            style={{ minHeight: "45px" }}
          >
            <button
              className="stepTitleWrap flex justify-start items-center gap-[30px] pt-2 pl-2"
              onClick={() => onOpenStep(4)}
            >
              {kpiSelected && <Icons.EllipseIcon />}
              {!kpiSelected && (
                <Icons.CircleIcon color="gray" width={20} height={20} />
              )}

              <span className={kpiSelected ? styles.completeSection : ""}>
                KPI
              </span>
            </button>

            <div
              className={openStep === 4 ? styles.stepBody : styles.noDisplay}
            >
              <div>
                <KPISettingsNew sendParentKpiState={setKpiSelected} />
              </div>
            </div>
          </div>
        </div>

        <div className="mt-8">
          {!existingCampaignData && (
            <Button
              color="primary"
              size="large"
              fullWidth
              loading={CreatingCampaign}
              onClick={handleCreateCampaign}
            >
              {t("Save & Create Campaign")}
            </Button>
          )}
        </div>
      </DialogPanel>
    </Dialog>
  );
}
