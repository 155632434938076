import React from "react";

type PaginationProps = {
  page: number;
  pageSize: number;
  totalRecords: number;
  totalPages?: number;
  onChange: (newDetails: { pageSize: number; pageNumber: number }) => void;
  sendParentSelectedRow?: (row: any) => void;
  sendParentRowClicked?: (clicked: boolean) => void;
};

const Pagination = (props: Readonly<PaginationProps>) => {
  const {
    page = 1,
    pageSize = 25,
    totalRecords,
    onChange,
    sendParentSelectedRow,
    sendParentRowClicked,
  } = props;

  const totalPages = Math.max(1, Math.ceil(totalRecords / pageSize));
  const currentPageGroup = Math.ceil(page / 10);
  const startPage = (currentPageGroup - 1) * 10 + 1;
  const endPage = Math.min(currentPageGroup * 10, totalPages);
  const pages = [];

  for (let i = startPage; i <= endPage; i++) {
    pages.push(i);
  }

  const goToPage = (newPage: number) => {
    if (newPage < 1 || newPage > totalPages) return;

    onChange({
      pageSize,
      pageNumber: newPage,
    });

    if (typeof sendParentSelectedRow === "function") {
      sendParentSelectedRow(null);
    }
    if (typeof sendParentRowClicked === "function") {
      sendParentRowClicked(false);
    }
  };

  const startIndex = (page - 1) * pageSize + 1;
  const endIndex = Math.min(page * pageSize, totalRecords);

  return (
    <div>
      <nav
        className="flex items-center justify-between pt-4 flex-col sm:flex-row"
        aria-label="Table navigation"
      >
        <span className="text-sm font-normal text-gray-500 dark:text-gray-400 flex items-center gap-4">
          <span>Showing</span>
          <span
            className={`font-semibold text-gray-900 ${totalPages !== 0 ? "hidden" : ""}`}
          >
            <span>0</span>
            <span> - </span>
            <span>0</span>
          </span>
          <span
            className={`font-semibold text-gray-900 ${totalPages === 0 ? "hidden" : ""}`}
          >
            <span>{startIndex}</span>
            <span> -</span>
            <span>{endIndex}</span>
          </span>
          <span> of</span>
          <span className="font-semibold text-gray-900">{totalRecords}</span>
        </span>

        <ul className="inline-flex -space-x-px text-sm h-8">
          {/* Previous Button */}
          <button
            className="flex items-center justify-center px-3 h-8 ml-0 leading-tight text-gray-500 bg-white border-2 border-gray-300"
            disabled={page <= 1}
            onClick={() => goToPage(page - 1)}
          >
            {"<"}
          </button>

          {/* Page Buttons */}
          {pages.map((p, index) => (
            <button
              key={index}
              className={`flex items-center justify-center px-3 h-8 ml-0 leading-tight text-gray-500 ${
                p === page
                  ? "bg-red-200 border-2 border-solid border-[#CD335B]"
                  : "bg-white border-2 border-solid border-gray-300"
              }`}
              onClick={() => goToPage(p)}
            >
              {p}
            </button>
          ))}

          {/* Next Button */}
          <button
            className="flex items-center justify-center px-3 h-8 ml-0 leading-tight text-gray-500 bg-white border-2 border-gray-300"
            disabled={page >= totalPages}
            onClick={() => goToPage(page + 1)}
          >
            {">"}
          </button>
        </ul>
      </nav>
    </div>
  );
};

export default Pagination;
