import { useNavigate } from "react-router-dom";

import Icons from "../../assets/icons/Icons";

import ClientInfo from "./ClientInfo";

function CreateClient() {
  const navigate = useNavigate();

  return (
    <div className=" min-h-[50vh] flex flex-col">
      <div className=" flex justify-between mx-12 mt-24">
        <span className=" text-black text-xl font-semibold">Create Client</span>

        <button onClick={() => navigate(-1)}>
          <Icons.ModelCloseIcon />
        </button>
      </div>

      <div className=" bg-white h-full flex flex-col   mx-7 lg:m-auto mt-10 lg:w-1/2">
        <span className="text-black font-normal text-xl w-full text-center">
          Customer Brand
        </span>
        <ClientInfo />
      </div>
    </div>
  );
}

export default CreateClient;
