import { SVGProps } from "react";

interface TrashIconProps extends SVGProps<SVGSVGElement> {
  height?: string | number;
  width?: string | number;
  color?: string; // Add a color prop
}

function TrashIcon({
  height = "14",
  width = "14",
  color = "#737373",
  ...props
}: TrashIconProps) {
  return (
    <svg
      {...props}
      width={width}
      height={height}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.29961 1.3999C6.03447 1.3999 5.79209 1.5497 5.67351 1.78685L5.16699 2.7999H2.79961C2.41301 2.7999 2.09961 3.1133 2.09961 3.4999C2.09961 3.8865 2.41301 4.1999 2.79961 4.1999L2.79961 11.1999C2.79961 11.9731 3.42641 12.5999 4.19961 12.5999H9.79961C10.5728 12.5999 11.1996 11.9731 11.1996 11.1999V4.1999C11.5862 4.1999 11.8996 3.8865 11.8996 3.4999C11.8996 3.1133 11.5862 2.7999 11.1996 2.7999H8.83223L8.32571 1.78685C8.20713 1.5497 7.96475 1.3999 7.69961 1.3999H6.29961ZM4.89961 5.5999C4.89961 5.2133 5.21301 4.8999 5.59961 4.8999C5.98621 4.8999 6.29961 5.2133 6.29961 5.5999V9.7999C6.29961 10.1865 5.98621 10.4999 5.59961 10.4999C5.21301 10.4999 4.89961 10.1865 4.89961 9.7999V5.5999ZM8.39961 4.8999C8.01301 4.8999 7.69961 5.2133 7.69961 5.5999V9.7999C7.69961 10.1865 8.01301 10.4999 8.39961 10.4999C8.78621 10.4999 9.09961 10.1865 9.09961 9.7999V5.5999C9.09961 5.2133 8.78621 4.8999 8.39961 4.8999Z"
        fill={color} // Use the color prop for the fill
      />
    </svg>
  );
}

export default TrashIcon;
