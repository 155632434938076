import { SVGProps } from "react";

interface RefreshIconProps extends SVGProps<SVGSVGElement> {
  width?: string | number; // Optional width prop
  height?: string | number; // Optional height prop
  color?: string; // Optional color prop for stroke or fill
}

function RefreshIcon({
  width = "15px", // Default width
  height = "15px", // Default height
  color = "currentColor", // Default color for stroke or fill
  onClick, // Optional onClick prop for handling click events
  ...props // Spread remaining props
}: RefreshIconProps) {
  return (
    <svg
      viewBox="0 0 30.50039 30.5"
      width={width} // Use width prop
      height={height} // Use height prop
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick} // Handle click event
      fill={color} // Use color prop for fill
      {...props} // Spread any other props
    >
      <path d="M3.2312,10.5H9.25a1.25,1.25,0,0,0,0-2.5H5.36743A13.20678,13.20678,0,0,1,16.415,2.5a12.75654,12.75654,0,0,1,11.68945,7.65039,1.25027,1.25027,0,0,0,2.291-1.00195A15.25661,15.25661,0,0,0,16.415,0,15.47292,15.47292,0,0,0,2.5,7.93555V1.25a1.25,1.25,0,0,0-2.5,0v8A1.25085,1.25085,0,0,0,1.25,10.5Z" />
      <path d="M29.25,20h-8a1.25,1.25,0,0,0,0,2.5h3.94965A11.00927,11.00927,0,0,1,15.25,28,12.75654,12.75654,0,0,1,3.56055,20.34961a1.25027,1.25027,0,0,0-2.291,1.002A15.25661,15.25661,0,0,0,15.25,30.5,13.625,13.625,0,0,0,28,22.58423V29.25a1.25,1.25,0,0,0,2.5,0v-8A1.25022,1.25022,0,0,0,29.25,20Z" />
    </svg>
  );
}

export default RefreshIcon;
