import api from "./api";

export const getClientBudgetPlan = async (clientId: string) => {
  const response: any = await api.get(
    `BudgetPlanning/GetByClientId?clientId=${clientId}`,
  );
  return response;
};

export const getClientMonthlyBudget = async (
  year: number,
  month: number,
  clientId: any,
) => {
  const response: any = await api.get(
    `BudgetPlanning/GetByYearMonthAndClientId?year=${year}&month=${month}&clientId=${clientId}`,
  );
  return response;
};

export const getPlanByYearAndClientId = async (clientId: any, year: number) => {
  const response = await api.get(
    `BudgetPlanning/GetByYearAndClientId?year=${year}&clientId=${clientId}`,
  );
  return response;
};

export const updateClientBudgetPlan = async (data: any) => {
  const response: any = await api.post("BudgetPlanning", data);
  return response;
};

export const getActiveClients = async () => {
  const response: any = await api.get("BudgetPlanning/GetAllClient");
  return response;
};

export const getActiveClientID = async (clientID: any) => {
  const response: any = await api.get(`v2/Clients/${clientID}`);
  return response;
};

export const getCloneMonths = async (
  year: number,
  month: number,
  clientId: string,
) => {
  const response: any = await api.get(
    `BudgetPlanning/GetCloneMonths?year=${year}&month=${month}&clientId=${clientId}`,
  );
  return response;
};

export const getPlannedJoBoards = async (
  year: number,
  month: number,
  clientId: any,
  online: boolean | string,
) => {
  const response: any = await api.get(
    `BudgetPlanning/GetPlannedJobboards?year=${year}&month=${month}&clientId=${clientId}${online !== "all" ? "&online=" + online : ""}`,
  );
  return response;
};

export const getAvailableJoBoards = async (
  clientId: number,
  selectedClientYear: number | undefined,
  selectedClientMonth: number | undefined,
) => {
  const response: any = await api.get(
    `BudgetPlanning/GetAvailableJobboards?year=${selectedClientYear}&month=${selectedClientMonth}&clientId=${clientId}`,
  );
  return response;
};

export const createPlanningJobBoard = async (data: any) => {
  const response: any = await api.post(
    "BudgetPlanning/CreatePlanningJobBoards",
    data,
  );
  return response;
};

export const updatePlannedJobBoard = async (data: any) => {
  const response: any = await api.put(
    "BudgetPlanning/UpdatePlannedJobBoards",
    data,
  );
  return response;
};

export const updateClientRestBudgetDistribution = async (data: any) => {
  const response: any = await api.put(
    "BudgetPlanning/UpdateRestBudgetAllocation",
    data,
  );
  return response;
};

export const changeaddgeneralagreement = async (
  BudgetPlanningId: number,
  GeneralAgreement: string,
  year: number,
  month: number,
  clientID: any,
) => {
  if (BudgetPlanningId) {
    const response: any = await api.put(
      `BudgetPlanning/ChangeAddGeneralAgreement?id=${BudgetPlanningId}&generalAgreement=${GeneralAgreement}&year=${year}&clientId=${clientID}&month=${month}`,
      {},
    );
    return response;
  } else {
    const response: any = await api.put(
      `BudgetPlanning/ChangeAddGeneralAgreement?&generalAgreement=${GeneralAgreement}&year=${year}&clientId=${clientID}&month=${month}`,
      {},
    );
    return response;
  }
};

export const changeupdatebudgetdistribution = async (
  BudgetPlanningId: number,
  data: any,
) => {
  const response: any = await api.put(
    `BudgetPlanning/UpdatingBudgetDistribution?id=${BudgetPlanningId}`,
    data,
  );
  return response;
};

export const changeupdateclientSPamounts = async (
  BudgetPlanningId: number,
  data: any,
) => {
  const response: any = await api.put(
    `BudgetPlanning/UpdatingClientSpAmounts?id=${BudgetPlanningId}`,
    data,
  );
  return response;
};

export const cloneMonth = async (
  year: number,
  month: number,
  BudgetPlanningId: number,
) => {
  const response: any = await api.put(
    `BudgetPlanning/CloneMonth?year=${year}&month=${month}&BudgetPlanningId=${BudgetPlanningId}`,
    {},
  );
  return response;
};

export const getBudgetPlanningType = async () => {
  const response = await api.get("BudgetPlanning/GetBudgetPlanningType");
  return response;
};

export const getCampaignAvailableJobBoards = async (
  year: number,
  month: number,
  clientId: number | string,
  campaignId: number,
) => {
  const response: any = await api.get(
    `BudgetPlanning/GetCampaignAvailableJobboards?year=${year}&month=${month}&&clientId=${clientId}&campaignId=${campaignId}`,
  );
  return response;
};

export const spIsHigherThanFCWarning = async (
  info: string,
  clientId: any,
  channel: string,
  month: number,
  year: number,
  campaign: string,
) => {
  const response = await api.get(
    `BudgetPlanning/SPIsHigherThanFCWarning?info=${info}&clientId=${clientId}&channel=${channel}&month=${month}&year=${year}&campaign=${campaign}`,
  );
  return response;
};

export const MONTH_NAMES: any = {
  1: "Jan",
  2: "Feb",
  3: "Mar",
  4: "Apr",
  5: "May",
  6: "Jun",
  7: "Jul",
  8: "Aug",
  9: "Sep",
  10: "Oct",
  11: "Nov",
  12: "Dec",
};

export const DEFAULT_BOARDS_BUDGET = [
  {
    month: "Jan",
    FC: "",
    PL: "",
    SP: "",
    Rest: "",
  },
  {
    month: "Feb",
    FC: "",
    PL: "",
    SP: "",
    Rest: "",
  },
  {
    month: "Mar",
    FC: "",
    PL: "",
    SP: "",
    Rest: "",
  },
  {
    month: "Apr",
    FC: "",
    PL: "",
    SP: "",
    Rest: "",
  },
  {
    month: "May",
    FC: "",
    PL: "",
    SP: "",
    Rest: "",
  },
  {
    month: "June",
    FC: "",
    PL: "",
    SP: "",
    Rest: "",
  },
  {
    month: "July",
    FC: "",
    PL: "",
    SP: "",
    Rest: "",
  },
  {
    month: "Aug",
    FC: "",
    PL: "",
    SP: "",
    Rest: "",
  },
  {
    month: "Sep",
    FC: "",
    PL: "",
    SP: "",
    Rest: "",
  },
  {
    month: "Oct",
    FC: "",
    PL: "",
    SP: "",
    Rest: "",
  },
  {
    month: "Nov",
    FC: "",
    PL: "",
    SP: "",
    Rest: "",
  },
  {
    month: "Dec",
    FC: "",
    PL: "",
    SP: "",
    Rest: "",
  },
];
