import { AxiosError } from "axios";
import { ChangeEvent, FormEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { useDispatch } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";

import Icons from "../../assets/icons/Icons";
import { Button } from "../../components/Button/Button";
import { AuthInput } from "../../components/FormFields/FormFields";
import { useFieldError } from "../../hooks/useFieldError";
import useFormErrors from "../../hooks/useFormErrors";
import Page from "../../layouts/Page/Page";
import { recentLogin } from "../../redux/recentlogin";
import { openSnackbar } from "../../redux/snackbar";
import { setUser } from "../../redux/user";
import { routeNames } from "../../router/routeNames";
import { login, logout } from "../../services/auth";
import { jwtDecoder } from "../../utils";

import styles from "./login.module.css";

function Login() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const { setFormErrors, removeFormError } = useFormErrors();
  const { mutate, isLoading, isError, data, error } = useMutation(
    (data: any) => login(data),
    {
      onError: (e: AxiosError) => {
        setFormErrors(e.response?.data);
      },
    },
  );

  const { getFieldError } = useFieldError(error);

  useEffect(() => {
    if (!isLoading && data) {
      dispatch(recentLogin());
      if (!data?.data?.accessToken && data?.data?.message) {
        dispatch(
          openSnackbar({
            message: data?.data?.message,
            type: "success",
          }),
        );

        return;
      }
      const tokenObject: any = jwtDecoder(data?.data?.accessToken);
      dispatch(
        setUser({
          token: data?.data?.accessToken,
          role: tokenObject.role,
          userName: tokenObject.email,
        }),
      );
      navigate("/");
    }
  }, [data]);

  useEffect(() => {
    if (!isLoading && isError) {
      const loginError: any = error;
      if (loginError.response.statusText === "Unauthorized")
        dispatch(
          openSnackbar({
            message: loginError?.response?.statusText + ": Invalid credentials",
            type: "error",
          }),
        );
    }
  }, [error]);

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    const key = e.target.name;
    removeFormError(key);
    setFormData({
      ...formData,
      [key]: e.target.value,
    });
  };

  const handleLogin = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    mutate(formData);
  };

  useEffect(() => {
    logout();
  }, []);
  return (
    <Page>
      <div className={styles.authCardContainer}>
        <div className={styles.authCard}>
          <h2 className="text-3xl font-bold tracking-wide flex justify-center">{`${t(
            "login",
            "Login",
          )}`}</h2>

          <form
            onSubmit={handleLogin}
            className=" flex flex-col gap-y-4 mt-5 max-w-md"
          >
            <AuthInput
              icon={
                <Icons.UserCircleIcon width={23} height={23} color="black" />
              }
              name="email"
              handleChange={handleOnChange}
              placeholder="Email"
              type="email"
              value={formData?.email}
              error={getFieldError("email")}
            />

            <AuthInput
              icon={<Icons.LockIcon width={23} height={23} color="black" />}
              name="password"
              handleChange={handleOnChange}
              placeholder="Password"
              type={"password"}
              value={formData?.password}
              error={getFieldError("password")}
            />

            <div className="flex justify-end mb-4">
              <NavLink
                to={routeNames.FORGOT_PASSWORD}
                className="text-blue-600 underline "
              >
                Forgotten Password?
              </NavLink>
            </div>

            <Button
              type="submit"
              color="primary"
              fullWidth={true}
              size="large"
              disabled={isLoading}
              loading={isLoading}
            >
              {t("login", "Login")}
            </Button>
          </form>
        </div>
      </div>
    </Page>
  );
}

export default Login;
