import { SVGProps } from "react";
interface BudgetPlanningIconProps extends SVGProps<SVGSVGElement> {
  width?: number; // Optional width prop
  height?: number; // Optional height prop
  color?: string; // Optional color prop for stroke and fill
}

function BudgetPlanningIcon({
  width = 25, // Default width
  height = 25, // Default height
  color = "white", // Default color for stroke and fill
  ...props // Spread remaining props
}: BudgetPlanningIconProps) {
  return (
    <svg
      {...props}
      width={width} // Use width prop
      height={height} // Use height prop
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.94118 18.98L5.05116 15.09C4.33116 14.37 4.35117 13.19 5.09117 12.46L12.4812 5.06998C12.8912 4.65998 13.4512 4.45998 14.0112 4.50998L17.5612 4.84998C18.4212 4.92998 19.1012 5.60997 19.1812 6.46997L19.5212 10.02C19.5712 10.58 19.3712 11.14 18.9612 11.55L11.5712 18.94C10.8412 19.67 9.66118 19.69 8.94118 18.98Z"
        stroke={color} // Use color prop for stroke
        strokeWidth="1.52705"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.5008 7.53009C16.0808 7.11009 15.4108 7.11009 14.9908 7.53009C14.5708 7.95009 14.5708 8.6201 14.9908 9.0401C15.2008 9.2501 15.4708 9.3501 15.7408 9.3501C16.0108 9.3501 16.2908 9.2501 16.4908 9.0401C16.9208 8.6201 16.9208 7.94009 16.5008 7.53009Z"
        fill={color} // Use color prop for fill
      />
    </svg>
  );
}

export default BudgetPlanningIcon;
