import { Outlet } from "react-router-dom";

import Page from "../../layouts/Page/Page";

function Channels() {
  return (
    <Page>
      <div className=" p-2 sm:p-10 pt-20">
        <Outlet />
      </div>
    </Page>
  );
}

export default Channels;
