import api from "./api";

export const getVacanciesbySegment = async (
  ClientId: any,
  SegmentId: any,
  Days: number,
) => {
  const response: any = await api.get(
    `Campaigns/GetVacanciesBySegments?ClientId=${ClientId}&SegmentId=${SegmentId}&Days=${Days}`,
  );
  return response;
};

export const getVacanciesCountbySegment = async (
  ClientId: any,
  SegmentId: any,
  Days: number,
) => {
  const response: any = await api.get(
    `Campaigns/GetVacanciesBySegments?ClientId=${ClientId}&SegmentId=${SegmentId}&Days=${Days}`,
  );
  return response?.data.vacancyCount;
};

export const getVacanciesbySegments = async (
  ClientId: any,
  SegmentIds: any,
  Days: number,
) => {
  const response: any = await api.post(
    `Campaigns/GetVacancyIdsBySegmentIds?ClientId=${ClientId}&Days=${Days}`,
    SegmentIds,
  );
  return response;
};
