import React, { useState } from "react";

import { Button } from "../Button/Button";

type ValueType = string | boolean;

interface ButtonGroupProps {
  options: { label: string; value: ValueType }[];
  align?: "left" | "center" | "right";
  onChange: (value: ValueType) => void;
}

const ButtonGroup: React.FC<ButtonGroupProps> = ({
  options,
  align = "left",
  onChange,
}) => {
  const [value, setValue] = useState(options[0].value);
  const handleClick = (value: ValueType) => {
    setValue(value);
    if (onChange) {
      onChange(value);
    }
  };

  const alignmentClasses = {
    left: "justify-start",
    center: "justify-center",
    right: "justify-end",
  };

  return (
    <div className={`flex gap-2 ${alignmentClasses[align]}`}>
      {options.map((option) => (
        <ButtonGroupItem
          key={option.label}
          label={option.label}
          isActive={value === option.value}
          onClick={() => handleClick(option.value)}
        />
      ))}
    </div>
  );
};

interface ButtonGroupItemProps {
  label: string;
  isActive: boolean;
  onClick: () => void;
}

const ButtonGroupItem: React.FC<ButtonGroupItemProps> = ({
  label,
  isActive,
  onClick,
}) => {
  return (
    <Button color={isActive ? "tertiary" : "secondary"} onClick={onClick}>
      {label}
    </Button>
  );
};

export default ButtonGroup;
