import {
  Dialog,
  DialogPanel,
  Transition,
  TransitionChild,
} from "@headlessui/react";
import { Fragment } from "react/jsx-runtime";

import { Button } from "../Button/Button";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  handleCancelCall?: any;
  handleSubmitCall?: any;
  spWarning?: any;
  setSpWarning?: any;
  actualBudget?: any;
};

const BudgetSpModal = (props: Props) => {
  const {
    isOpen,
    onClose,
    handleCancelCall,
    handleSubmitCall,
    spWarning,
    setSpWarning,
    actualBudget,
  } = props;

  const handleCancel = () => {
    onClose();
    handleCancelCall();
  };

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <TransitionChild
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </TransitionChild>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <TransitionChild
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <DialogPanel className="w-full max-w-2xl transform overflow-hidden rounded-lg bg-white p-6 text-left align-middle shadow-xl transition-all">
                <div className="mt-6 flex flex-col items-center gap-3">
                  <h2 className="text-3xl font-bold  ">
                    More Information Required
                  </h2>
                  <p className="text-xl">Actual Spend = {actualBudget} </p>
                  <p className="text-xl">
                    You entered the value greater then fc budget
                  </p>
                  <input
                    type="text"
                    value={spWarning?.info}
                    onChange={(e) =>
                      setSpWarning({ ...spWarning, info: e.target.value })
                    }
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-80 p-2.5 "
                    placeholder="Enter Comments"
                    required
                  />
                </div>
                <div className="flex justify-end mt-20 gap-4">
                  <Button
                    color="tertiary"
                    variant="outline"
                    onClick={handleCancel}
                  >
                    Cancel
                  </Button>

                  <Button onClick={() => handleSubmitCall()} color="primary">
                    Submit
                  </Button>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default BudgetSpModal;
