import { SVGProps } from "react";

interface EyeCloseIconProps extends SVGProps<SVGSVGElement> {
  color?: string; // Optional color prop
  height?: string | number; // Optional height prop
  width?: string | number; // Optional width prop
}

function EyeCloseIcon({
  color = "currentColor",
  height = "20",
  width = "20",
  ...props
}: EyeCloseIconProps) {
  return (
    <svg
      {...props}
      height={height} // Use the height prop
      width={width} // Use the width prop
      fill={color} // Use the color prop
      viewBox="0 0 16 16"
    >
      <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
      <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z" />
    </svg>
  );
}

export default EyeCloseIcon;
