import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { useQuery } from "react-query";

import Icons from "../../assets/icons/Icons";
import { getVacanciesListForSegment } from "../../services/campaign";
import { VACANCIES_COLUMN } from "../../services/vacancies";
import Paginator from "../Paginator/Paginator";
import Spinner from "../Spinner/Spinner";
import Table from "../Table/Table";
import VacancyDetailsModal from "../Vacancies/VacancyDetailsModal/VacancyDetailsModal";

import styles from "./SegmentVacanciesModal.module.css";

const SegmentVacanciesModal = (props: any) => {
  const { onClose, segmentGroup, completeConditions, clientId } = props;

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      maxheight: "80vh",
      zIndex: 999,
    },
  };

  const [selectedVacancy, setSelectedVacancy] = useState<number | null>(null);

  const [pageDetails, setPageDetails] = useState({
    pageNumber: 1,
    pageSize: 25,
  });

  const {
    data: segmentVacanciesCountList,
    isLoading: segmentVacanciesCountListSpinner,
    isFetching: segmentVacanciesCountListFetching,
    refetch: getSegmentVacanciesCountResponse,
  } = useQuery(
    ["segmentVacanciesListForSegment", clientId, segmentGroup, props.feedDays],
    () =>
      getVacanciesListForSegment(
        pageDetails.pageNumber,
        pageDetails.pageSize,
        clientId,
        segmentGroup,
        props.feedDays === null || props.feedDays === "" ? "" : props.feedDays,
        completeConditions,
      ),
    {
      enabled: false,
    },
  );

  useEffect(() => {
    if (
      (completeConditions?.length && clientId,
      pageDetails.pageNumber,
      pageDetails.pageSize)
    ) {
      getSegmentVacanciesCountResponse();
    }
  }, [
    completeConditions,
    clientId,
    props.feedDays,
    segmentGroup,
    pageDetails.pageNumber,
    pageDetails.pageSize,
  ]);

  return (
    <Modal
      style={customStyles}
      isOpen={props?.isOpen}
      className={styles.afterOpen}
      ariaHideApp={false}
    >
      <section className={styles.modalContent}>
        <button className={styles.closeBtn} onClick={() => onClose()}>
          <Icons.ModelCloseIcon />
        </button>

        <h3> Vacancies</h3>

        <div className={styles.table}>
          {segmentVacanciesCountListSpinner ||
          segmentVacanciesCountListFetching ? (
            <div className="flex justify-center  items-center">
              <Spinner size={"30px"} />
            </div>
          ) : (
            <Table
              columns={VACANCIES_COLUMN}
              data={segmentVacanciesCountList?.data?.data}
              onRowClick={(item: any) => {
                setSelectedVacancy(item?.id);
              }}
              RowisClickable={true}
            />
          )}
        </div>

        {segmentVacanciesCountList?.data && (
          <Paginator
            pageNumber={segmentVacanciesCountList?.data?.pageNumber}
            pageSize={segmentVacanciesCountList?.data?.pageSize}
            totalPages={segmentVacanciesCountList?.data?.totalPages}
            totalRecords={segmentVacanciesCountList?.data?.totalRecords}
            onChange={(newDetails: any) => {
              setPageDetails(newDetails);
            }}
          />
        )}

        {selectedVacancy && (
          <VacancyDetailsModal
            selectedVacancy={selectedVacancy}
            isOpen={!!selectedVacancy}
            onClose={() => {
              setSelectedVacancy(null);
            }}
          />
        )}
      </section>
    </Modal>
  );
};
export default SegmentVacanciesModal;
