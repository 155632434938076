import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import Icons from "../../assets/icons/Icons";
import { openSnackbar } from "../../redux/snackbar";
import { routeNames } from "../../router/routeNames";
import {
  CAMPAIGN_COLUMNS,
  deleteCampaign,
  getClientCampaigns,
  switchCampaignsPriority,
  updatePlayPause,
} from "../../services/campaign";
import Breadcrumb from "../Breadcrumb";
import ButtonGroup from "../ButtonGroup/ButtonGroup";
import CampaignTable from "../CampaignTable/CampaignTable";
import Charts from "../Charts";
import ConfirmationModal from "../ConfirmationModal/ConfirmationModal";
import Spinner from "../Spinner/Spinner";

import styles from "./Campaigns.module.css";
import ViewCampaign from "./ViewCampaignModal/ViewCampaignModal";

function Campaigns() {
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const clientId = params.id;
  const [filter, setFilter] = useState<any>({
    from: "",
    to: "",
    branch: "",
  });

  const [openModal, setOpenModal] = useState<boolean>(false);
  const [deleteItem, setDeleteItem] = useState<any>({
    id: "",
  });
  const [openViewCampaignModal, setOpenViewCampaignModal] =
    useState<boolean>(false);
  const [campaignId, setCampaignId] = useState<any>();
  const [campaignStatus, setCampaignStatus] = useState<string | boolean>(
    "online",
  );

  const {
    data: CampaignsData,
    isLoading: loadingCampaigns,
    refetch: fetchClientCampaigns,
  } = useQuery(["clientCampaigns", clientId, filter], () =>
    getClientCampaigns(clientId, filter),
  );

  const {
    mutate: DeleteCampaign,
    isLoading: deletingCampaign,
    data: DeleteCampaignRes,
    reset: ResetDeleteCampaign,
  } = useMutation((id: string) => deleteCampaign(id), {
    onSuccess() {
      setOpenModal(false);
      setDeleteItem({ id: "" });
    },
  });
  const { isLoading: updatingPriority, mutate: updatePriority } = useMutation(
    (switchPriorityData: any) =>
      switchCampaignsPriority(
        switchPriorityData?.fromCampaignId,
        switchPriorityData?.toCampaignId,
      ),
    {
      onSuccess() {
        fetchClientCampaigns();
      },
    },
  );

  const { mutate, isError, isLoading, data, reset } = useMutation(
    (id: string) => updatePlayPause(id),
  );

  useEffect(() => {
    if (!isError && data) {
      const fetchData = async () => {
        await fetchClientCampaigns();
        dispatch(
          openSnackbar({
            message: "Status Updated",
          }),
        );
        reset();
      };

      fetchData();
    }
  }, [data]);

  useEffect(() => {
    if (!isLoading && isError) {
      dispatch(
        openSnackbar({
          message: "Error while updating status",
          type: "error",
        }),
      );
      reset();
    }
  }, [isError]);

  useEffect(() => {
    if (DeleteCampaignRes?.data) {
      dispatch(
        openSnackbar({
          message: "Campaign deleted",
          type: "success",
        }),
      );
      ResetDeleteCampaign();
      queryClient.refetchQueries("client");
      fetchClientCampaigns();
    }
  }, [DeleteCampaignRes]);

  useEffect(() => {
    fetchClientCampaigns();
  }, [filter]);

  const handlePriorityChange = (item: any, action: string) => {
    let currentIndex: number | null = null;
    CampaignsData?.data?.forEach((campaign: any, index: number) => {
      if (campaign?.id === item.id) {
        currentIndex = index;
      }
    });
    if (currentIndex === null) return;
    const priorityData = {
      fromCampaignId: item?.id,
      toCampaignId:
        action === "raise"
          ? CampaignsData?.data?.[currentIndex - 1]?.id
          : CampaignsData?.data?.[currentIndex + 1]?.id,
    };
    if (priorityData?.fromCampaignId && priorityData?.toCampaignId) {
      if (!updatingPriority) {
        updatePriority(priorityData);
      }
    }
  };

  const handleParentPlayPause = (id: any) => {
    mutate(id);
  };

  const filteredCampaigns =
    CampaignsData?.data?.filter((item: any) => {
      if (campaignStatus === "all") {
        return true;
      }
      if (
        campaignStatus === "online" &&
        item.status === true &&
        item.paused === false
      ) {
        return true;
      }
      if (
        campaignStatus === "offline" &&
        (item.status === false || item.paused === true)
      ) {
        return true;
      }
    }) || [];

  return (
    <>
      <div className="px-16 pb-4 pt-6 w-full bg-black">
        <Breadcrumb
          navigation={[
            { text: "Client Dashboard", url: `/client/dashboard/${params.id}` },
          ]}
          currentPage={{
            text: "Campaigns",
            url: `/client/${params.id}`,
          }}
        />

        <Charts />
      </div>
      <div className=" p-10">
        <div className="flex justify-start items-center my-[30px]">
          <h3 className=" text-2xl font-bold text-black">{t("campaigns")}</h3>
        </div>
        <div className={"filtersContainer"}>
          <div className={"dateFilterWrap"}>
            <div
              className={`${styles.campaignDate} w-[230px] border-2 rounded-[5px] h-10`}
            >
              <span>{t("from")}</span>
              <input
                className={styles.dateInput}
                type={"date"}
                name={"from"}
                value={filter?.from || ""}
                placeholder="dd-mm-yyyy"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  const greaterThanToFilter =
                    e.target.value > filter?.to || false;
                  setFilter({
                    ...filter,
                    ...(greaterThanToFilter && {
                      to: e.target.value,
                    }),
                    [e.target.name]: e.target.value,
                  });
                }}
              />
            </div>
            <div
              className={`${styles.campaignDate}  w-[230px] border-2 rounded-[5px]  h-10`}
            >
              <span>{t("to")}</span>
              <input
                className={styles.dateInput}
                type={"date"}
                name={"to"}
                value={filter?.to || ""}
                min={filter?.from}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setFilter({
                    ...filter,
                    [e.target.name]: e.target.value,
                  });
                }}
              />
            </div>
          </div>
          <div className={"statusFilter"}>
            {t("status")} |
            <Icons.StatusActiveIcon />
            <Icons.StatusUnactiveIcon />
          </div>
          <ButtonGroup
            onChange={(value) => setCampaignStatus(value)}
            align="center"
            options={[
              {
                label: "Online",
                value: "online",
              },
              {
                label: "Offline",
                value: "offline",
              },
              {
                label: "All",
                value: "all",
              },
            ]}
          />
        </div>
        {loadingCampaigns ? (
          <Spinner size={"24px"} />
        ) : (
          !!CampaignsData?.data && (
            <CampaignTable
              columns={CAMPAIGN_COLUMNS}
              data={filteredCampaigns}
              deleteable={true}
              sortable={true}
              order={false}
              clickableItems={["vacanciesCount"]}
              onDelete={(campaign: any) => {
                setOpenModal(true);
                setDeleteItem(campaign);
              }}
              onRowClick={(campaign: any) => {
                setOpenViewCampaignModal(true);
                setCampaignId(campaign?.id);
              }}
              onItemClick={({ accessKey, item }: any) => {
                if (accessKey !== "vacanciesCount") return;
                navigate(
                  `${routeNames.CLIENT}/${clientId}/vacancies?campaignId=${item?.id}`,
                );
              }}
              allowOrderUpdate={true}
              onMoveUp={(item: any) => handlePriorityChange(item, "raise")}
              moveUpTooltip={"Raise priority"}
              onMoveDown={(item: any) => handlePriorityChange(item, "lower")}
              updatingPriority={updatingPriority}
              handleParentPlayPause={handleParentPlayPause}
              editable={true}
            />
          )
        )}

        {openViewCampaignModal && (
          <ViewCampaign
            isOpen={openViewCampaignModal}
            onClose={() => setOpenViewCampaignModal(false)}
            campaignID={campaignId}
            clientId={clientId}
          />
        )}
        {openModal && (
          <ConfirmationModal
            heading={"Delete Campaign"}
            messageContent={"Are you sure you want to delete this campaign?"}
            isOpen={openModal}
            onClose={() => {
              setOpenModal(false);
            }}
            handleClick={() => {
              if (deleteItem) {
                DeleteCampaign(deleteItem?.id);
              }
            }}
            isLoading={deletingCampaign}
            modalType="delete"
          />
        )}
      </div>
    </>
  );
}
export default Campaigns;
