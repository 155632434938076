import { useEffect, useRef, useState } from "react";

import Icons from "../../assets/icons/Icons";

import styles from "./Dropdown.module.css";

type DropdownPropsType = {
  value?: any;
  options?: any;
  handleOnChange?: any;
  label?: any;
  accessor?: any;
  propStyles?: any;
  btnStyles?: any;
};

function Dropdown(props: Readonly<DropdownPropsType>) {
  const {
    value,
    options = [],
    handleOnChange = () => {
      // Place holder comment
    },
    label = "label",
    accessor = "value",
    propStyles = {
      minWidth: "maxContent",
    },
    btnStyles = {},
  } = props;

  const [isOpen, setIsOpen] = useState(false);
  const [dropdownLabel, setDropdownLabel] = useState<any>();
  const dropdownRef = useRef<any>(null);

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [isOpen]);

  useEffect(() => {
    const selectedOption = options?.filter(
      (option: any) => option?.[accessor] === value,
    )[0];
    if (selectedOption?.[label]) {
      setDropdownLabel(selectedOption[label]);
    }
  }, [value]);

  const handleClickOutside = (event: any) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  const updateValue = (value: any) => {
    setIsOpen(false);
    const selectedOption = options?.filter(
      (option: any) => option?.[accessor] === value,
    )[0];
    if (selectedOption?.[label]) {
      setDropdownLabel(selectedOption[label]);
    }
    handleOnChange(value);
  };

  return (
    <div
      className={styles.dropdown}
      ref={dropdownRef}
      style={{
        ...propStyles,
      }}
    >
      <button
        className={styles.dropdownBtn}
        onClick={() => setIsOpen(!isOpen)}
        style={{
          ...btnStyles,
        }}
      >
        <span>{dropdownLabel}</span>
        <Icons.UpDownArrowIcon />
        {isOpen && (
          <div className={styles.dropdownContent}>
            {options?.map((option: any, index: number) => (
              <li key={`${"" + index}`}>
                <button
                  className={styles.dropdownItem}
                  onClick={() => updateValue(option?.[accessor])}
                >
                  {option?.[label] || "(Unnamed)"}
                </button>
              </li>
            ))}
          </div>
        )}
      </button>
    </div>
  );
}

export default Dropdown;
