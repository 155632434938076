import api from "./api";

export const fetchTenantId = async () => {
  try {
    const response = await api.get("v2/Tenants");
    const tenants = response.data?.data || [];

    const onlineResultsTenant = tenants.find(
      (tenant: any) => tenant.name === "OnlineResults",
    );
    localStorage.setItem("tenantId", onlineResultsTenant.id);
    return onlineResultsTenant?.id || null;
  } catch (error) {
    console.error("Error fetching tenant ID:", error);
    return null;
  }
};
