import React, { useEffect, useState } from "react";

import Icons from "../../assets/icons/Icons";
import CurrrencyInput from "../CurrencyInput/CurrrencyInput";

import styles from "./BudgetCard.module.css";

import "./BudgetCard.css";

const BudgetCard = (props: any) => {
  const {
    campaignBoardBudget,
    onAmountChange,
    onDelete,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    allowDelete = true,
  } = props;
  const currency = "€";
  const [dailyLimitError, setDailyLimitError] = useState<boolean>(false);

  useEffect(() => {
    const budget = parseFloat(
      campaignBoardBudget?.jobboardBudgetAmount?.plannedAmountPerDay || 0,
    );
    const add = parseFloat(
      campaignBoardBudget?.jobboardBudgetAmount?.amountPerDay || 0,
    );

    setDailyLimitError(add > budget);
  }, [campaignBoardBudget?.jobboardBudgetAmount?.amountPerDay]);

  return (
    <div className={styles.parentCard}>
      <div className={styles.cardArea}>
        <div className={styles.head}>
          <div className={styles.headList}>
            <span className={styles.heading}>
              {campaignBoardBudget?.jobBoardName}
            </span>
            {parseFloat(
              campaignBoardBudget?.jobboardBudgetAmount?.amount || 0,
            ) <= 0 && (
              <button onClick={() => onDelete()} className=" mb-4">
                <Icons.TrashIcon height={20} width={20} color="black" />
              </button>
            )}
          </div>
        </div>

        <div className={styles.cardContent}>
          <div className={styles.rowContainer}>
            <label>Budget</label>
            <span>
              {currency}{" "}
              {isNaN(
                parseFloat(
                  campaignBoardBudget?.jobboardBudgetAmount
                    ?.plannedAmountPerDay,
                ),
              )
                ? 0
                : parseFloat(
                    campaignBoardBudget?.jobboardBudgetAmount
                      ?.plannedAmountPerDay,
                  ).toFixed(2)}{" "}
              p/d
            </span>
          </div>
          <div className={styles.rowContainer}>
            <label>Add</label>
            <CurrrencyInput
              name="amountPerDay"
              disabled={
                !campaignBoardBudget?.jobboardBudgetAmount?.plannedAmountPerDay
              }
              value={campaignBoardBudget?.jobboardBudgetAmount?.amountPerDay}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                onAmountChange(e)
              }
              propStyles={{
                border: dailyLimitError ? "1.5px solid red" : "",
              }}
            />
          </div>
          <span className={styles.limitError}>
            {dailyLimitError &&
              "Added value should not be greater than planned budget"}
          </span>
        </div>

        <div className={styles.month}>
          <span>
            Month {currency}{" "}
            {campaignBoardBudget?.jobboardBudgetAmount?.amount || 0} p/m
          </span>
        </div>
      </div>
    </div>
  );
};

export default BudgetCard;
