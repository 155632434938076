import { SVGProps } from "react";

interface AddIconProps extends SVGProps<SVGSVGElement> {
  width?: string | number; // Optional width prop
  height?: string | number; // Optional height prop
  color?: string; // Optional color prop for stroke
}

function AddIcon({
  width = "25px",
  height = "25px",
  color = "white",
  ...props
}: AddIconProps) {
  return (
    <svg
      {...props}
      width={typeof width === "number" ? `${width}px` : width} // Use width prop
      height={typeof height === "number" ? `${height}px` : height} // Use height prop
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke={color}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M12 4.5v15m7.5-7.5h-15"
      />
    </svg>
  );
}

export default AddIcon;
