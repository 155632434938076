const english = {
  translation: {
    // Navbar
    jobBoards: "JobBoards",
    mappings: "Mappings",
    categories: "Categories",
    rapport: "Rapport",
    settings: "Settings",
    notifications: "Notifications",
    registerUser: "Register User",

    // Dashboard
    searchClients: "Search Clients",

    // Client card
    vacancies: "Vacancies",
    inCampaign: "In Campaign",
    open: "Open",

    // Auth
    login: "Login",
    email: "Email",
    password: "Password",
    verifyCode: "Verify Code",
    verificationCode: "Verification Code",
    verify: "Verify",
    backToLogin: "Back to login?",
    back: "Back",

    // User Roles
    selectUserRole: "Select User Role",
    user: "User",
    admin: "Admin",
    superAdmin: "Super Admin",
    clientLead: "Client Lead",
    channelLead: "Channel Lead",
    channelSpecialist: "Channel Specialist",

    // Create Client
    createClient: "Create Client",
    customerBrand: "Customer Brand",
    name: "Name",
    active: "Active",
    inactive: "Inactive",
    invoiceOnlineResult: "Invoice Online Result",
    yes: "Yes",
    no: "No",
    prepaid: "Prepaid",
    postpaid: "Postpaid",
    for: "For",
    Parent: "Parent",
    branch: "Branch",
    clientContactPersons: "Contact person(s) on client side",
    primary: "Primary",
    phone: "Phone",
    Role: "Role",
    onlineResultsManager: "Online Results Manager",
    save: "Save",
    feedName: "Feed Name",
    feedUrl: "Feed URL",
    addFeed: "Add Feed",

    // Client
    performance: "Performance",
    channels: "Channels",
    campaigns: "Campaigns",
    feeds: "Feeds",
    deleteClient: "Delete Client",

    // Campaigns
    createNewCampaign: "Create New Campaign",
    from: "From",
    to: "To",
    status: "Status",
    // Campaigns table
    campaign: "Campaign",
    type: "Type",
    createDate: "CreateDate",
    budget: "Budget",
    startDate: "Start-date",
    endDate: "End-date",
    vacanciesIncluded: "Vacancies Included",

    // Create Campaign
    showAllCampaigns: "Show all Campaigns",
    buildCampaign: "Build a New Campaign",
    buildCampaignPrecedingMonths: "Build a New Campaign in Preceding Months",
    tracking: "Tracking",
    vacanciesFeeds: "Vacancies Feeds",
    vacanciesInSelection: "Vacancies In Selection",
    feed: "Feed",
    selectFeed: "Select Feed",
    segment: "Segment",
    theseAreSaveSegments: "These are saved segments",
    existing: "Existing",
    selectChannel: "Select Channel",

    // Create Segment
    buildNewSegment: "Build new Segment",
    segmentName: "Segment Name",
    segmentWillFollow: "Segment will follow",
    followingConditions: "of the following conditions",
    selectVariable: "Select Variable",
    is: "Is",
    isNot: "IsNot",
    addNewVariable: "Add new Variable",
    cancel: "Cancel",

    // Create Jobboard
    createNewJobboard: "Create JobBoard",
    jobBoard: "JobBoard",
    feedSort: "Feed Sort",
    perClient: "Per Client",
    perJobBoard: "Per JobBoard",
    jobBoardContactPersons: "Contact Person(s) with JobBoard",

    // Edit Client
    editClient: "Edit Client",
    "Logo URL": "Logo URL",

    //Feed
    Feeds: "Feeds",
    "Add Feed": "Add Feed",
    "Show all feeds": "Show all feeds",

    //CreateFeed
    "Add new Feeds": "Add new Feeds",

    //Vacancies table
    "Job Title": "Job Title",
    Area: "Area",
    Location: "Location",
    "Publication Date": "Publication Date",
    "Functinal Group": "Functinal Group",

    //Feeds table
    Type: "Type",
    Vacancies: "Vacancies",

    //Client jobBoards
    "Client JobBoards": "Client JobBoards",

    //Client jobBoars table
    Name: "Name",

    //Vacancy Mapping
    "Data Mapping": "Data Mapping",

    //Mapping Vacancy Modal
    "Related Vacancies": "Related Vacancies",

    //Vacancy Mapping Table
    "Field Name": "Field Name",
    Category: "Category",
    "Field Mapping": "Field Mapping",
    "Position Name": "Position Name",
    "Position Mapping": "Position Mapping",

    //Categories
    "Job Categories": "Job Categories",
    "Add Category": "Add Category",

    //Categories Table
    Categories: "Categories",
    "Vacancies in category": "Vacancies in category",

    // Budget
    "Budget Distribution": "Budget Distribution",
    "Boards Budget Planning": "Boards Budget Planning",

    //Budget Planning
    "General Agreements": "General Agreements",
    FC: "FC",
    PL: "PL",
    SP: "SP",
    Discount: "Discount budget",
    Rest: "Rest",
    "Are you sure you want to leave without saving changes?":
      "Are you sure you want to leave without saving changes?",

    errors: {
      required: "This field is required",
      requiredField: "{{fieldName}} is required",
      invalid_email: "Please enter a valid email address",
      invalid_credentials: "Invalid email or password",
      duplicate_email: "A user with this email address already exists",
      account_locked:
        "Your account has been locked due to too many failed attempts",
      field_min: "{{fieldName}} must be at least 2 characters long",
      field_max: "{{fieldName}} must not exceed 32 characters",
      email_max: "Email Address",
      email_invalid: "The Email field is not a valid e-mail address",
      invalid_token: "Invalid token",
      must_match: "The ConfirmPassword and Password fields do not match",
      invalid_client: "Invalid client ID",
      pageNotFound: "404 - Page Not Found",
      unauthorized: "403 - Not Authorized",
      invalidAuditLog: "Invalid audit log",
      below_min_length: "Password must be at least 8 characters long",
      uppercase_required:
        "Password must contain at least one uppercase character",
      lowercase_required:
        "Password must contain at least one lowercase character",
      digit_required: "Password must contain at least one digit",
      special_character_required:
        "Password must contain at least one special character",
      must_be_unique: "{{fieldName}} must be unique",
      invalid_length:
        "{{fieldName}} must be between {{minLength}} and {{maxLength}} characters long",
      invalid_url: "Please enter a valid URL",
      invalid_apikey: "Please enter a valid api-key",
      invalid_feed: "Please enter a valid feed",
      invalidVacancy: "Invalid vacancy",
      field_min_max: "{{fieldName}} requires 2 - 32 characters",
      field_valid: "{{fieldName}} must be valid",
      invalidAuditlogs: "Invalid Audit Logs",
      exceeds_max_length: "Exceeds max length for {{fieldName}}",
      invalid_format: "Invalid format for {{fieldName}}",
      invalid_phone_number: "Invalid phone number must be numeric",
    },
  },
};

export default english;
