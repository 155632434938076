import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { closeSidebar } from "../../redux/drawer";
import { Button } from "../Button/Button";

const Drawer = () => {
  const isOpen = useSelector((state: any) => state.drawer.isOpen);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const goTo = (endpoint: string) => {
    dispatch(closeSidebar());
    navigate(endpoint);
  };

  if (isOpen)
    return (
      <div className="w-screen h-screen absolute z-50 flex flex-col   bg-[#0A192F] gap-24 items-center text-gray-50 font-semibold text-24 p-24">
        <div className="flex justify-end w-full text-gray-300">
          <Button onClick={() => dispatch(closeSidebar())} color="secondary">
            X
          </Button>
        </div>
        <div className="w-full flex flex-col gap-6">
          <Button onClick={() => goTo("/job-boards")} color="primary">
            Job Boards
          </Button>

          <Button onClick={() => goTo("/field-mapping")} color="primary">
            Mappings
          </Button>
        </div>
      </div>
    );

  return <></>;
};

export default Drawer;
