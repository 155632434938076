import { SVGProps } from "react";

// Define the props for MenuIcon, extending SVG props
interface MenuIconProps extends SVGProps<SVGSVGElement> {
  color?: string; // Optional color prop
  height?: string | number; // Optional height prop
  width?: string | number; // Optional width prop
}

// Define the MenuIcon functional component
function MenuIcon({
  color = "#e5e7eb", // Default color
  height = "28", // Default height
  width = "28", // Default width
  ...props // Spread additional props
}: MenuIconProps) {
  return (
    <svg
      {...props} // Spread additional SVG props
      width={width} // Set width from props
      height={height} // Set height from props
      viewBox="0 0 20 20" // Define viewBox for SVG scaling
      xmlns="http://www.w3.org/2000/svg" // XML namespace
      fill={color} // Set fill color
      stroke={color} // Set stroke color
    >
      <path
        fill={color || "#e5e7eb"}
        fillRule="evenodd"
        d="M19 4a1 1 0 01-1 1H2a1 1 0 010-2h16a1 1 0 011 1zm0 6a1 1 0 01-1 1H2a1 1 0 110-2h16a1 1 0 011 1zm-1 7a1 1 0 100-2H2a1 1 0 100 2h16z"
      />
    </svg>
  );
}

export default MenuIcon;
