import { createContext } from "react";

import { GetTenantsResponse } from "../../types/client/response/GetTenantsResponse";

export type TenantContextType = {
  tenants: GetTenantsResponse | undefined;
  tenantsFetching: boolean;
  tenantsLoading: boolean;
};

export const TenantContext = createContext<TenantContextType | null>(null);
