import { SVGProps } from "react";

interface LinkApplyIconProps extends SVGProps<SVGSVGElement> {
  color?: string; // Optional color prop
  height?: string | number; // Height can be a string or number
  width?: string | number; // Width can be a string or number
}

function LinkApplyIcon({
  color = "#000000",
  height = "800px",
  width = "800px",
  ...props
}: LinkApplyIconProps) {
  return (
    <svg
      {...props}
      fill={color} // Use the color prop
      xmlns="http://www.w3.org/2000/svg"
      height={height} // Use the height prop
      width={width} // Use the width prop
      viewBox="0 0 52 52"
      enableBackground="new 0 0 52 52"
      xmlSpace="preserve"
    >
      <g>
        <path d="M27.2,41.7c-0.7-0.1-1.4-0.2-2.1-0.3s-1.4-0.3-2.1-0.6c-0.4-0.1-0.9,0-1.2,0.3l-0.5,0.5 c-2.9,2.9-7.6,3.2-10.6,0.6c-3.4-2.9-3.5-8.1-0.4-11.2l7.6-7.6c1-1,2.2-1.6,3.4-2c1.6-0.4,3.3-0.3,4.8,0.3c0.9,0.4,1.8,0.9,2.6,1.7 c0.4,0.4,0.7,0.8,1,1.3c0.4,0.7,1.3,0.8,1.8,0.2c0.9-0.9,2.1-2.1,2.8-2.8c0.4-0.4,0.4-1,0.1-1.5C34,20,33.5,19.5,33,19 c-0.7-0.7-1.5-1.4-2.4-1.9c-1.4-0.9-3-1.5-4.7-1.8c-3.1-0.6-6.5-0.1-9.3,1.4c-1.1,0.6-2.2,1.4-3.1,2.3l-7.3,7.3 c-5.3,5.3-5.7,13.9-0.6,19.3c5.3,5.8,14.3,5.9,19.8,0.4l2.5-2.5C28.6,43,28.1,41.8,27.2,41.7z" />
        <path d="M45.6,5.8c-5.5-5.1-14.1-4.7-19.3,0.6L24,8.6c-0.7,0.7-0.2,1.9,0.7,2c1.4,0.1,2.8,0.4,4.2,0.8 c0.4,0.1,0.9,0,1.2-0.3l0.5-0.5c2.9-2.9,7.6-3.2,10.6-0.6c3.4,2.9,3.5,8.1,0.4,11.2L34,28.8c-1,1-2.2,1.6-3.4,2 c-1.6,0.4-3.3,0.3-4.8-0.3c-0.9-0.4-1.8-0.9-2.6-1.7c-0.4-0.4-0.7-0.8-1-1.3c-0.4-0.7-1.3-0.8-1.8-0.2l-2.8,2.8 c-0.4,0.4-0.4,1-0.1,1.5c0.4,0.6,0.9,1.1,1.4,1.6c0.7,0.7,1.6,1.4,2.4,1.9c1.4,0.9,3,1.5,4.6,1.8c3.1,0.6,6.5,0.1,9.3-1.4 c1.1-0.6,2.2-1.4,3.1-2.3l7.6-7.6C51.5,20.1,51.3,11.1,45.6,5.8z" />
      </g>
    </svg>
  );
}

export default LinkApplyIcon;
