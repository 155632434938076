import {
  Dialog,
  DialogPanel,
  DialogTitle,
  Transition,
  TransitionChild,
} from "@headlessui/react";
import { Fragment } from "react";

import Icons from "../../assets/icons/Icons";
import { Button } from "../Button/Button";
import Spinner from "../Spinner/Spinner";

import styles from "./WarningModalDesign.module.css";
// import Icons from "../../assets/icons/Icons";

type Props = {
  cancelText?: string;
  closable?: boolean;
  deleteText?: string;
  handleClick?: any;
  heading: string;
  isLoading?: boolean;
  isOpen: boolean;
  modalType: "delete" | "info";
  onClose: any;
  proceedText?: string;
  messageContent: string | React.ReactNode;
};

export default function ConfirmationModal(props: Readonly<Props>) {
  const {
    handleClick = () => {
      // Place Holder Comment
    },
    closable = true,
    onClose = () => {
      // Place Holder Comment
    },
    heading = undefined,
    messageContent = undefined,
    isLoading = false,
    isOpen = false,
    modalType = "delete", //Type of the modal either delete or info
    cancelText = "Cancel",
    proceedText = "Continue",
    deleteText = "Delete",
  } = props;

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-20" onClose={onClose}>
        <TransitionChild
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </TransitionChild>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <TransitionChild
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <DialogPanel className="w-full max-w-xl transform rounded-lg bg-white p-6 text-left align-middle shadow-xl transition-all">
                {isLoading ? (
                  <div className={styles.spinner}>
                    <Spinner size={"50px"} />
                  </div>
                ) : (
                  <>
                    <div className="flex flex-col">
                      <div className="flex justify-between">
                        <div className="flex items-center gap-1">
                          {modalType === "delete" ? (
                            <Icons.WarningIcon />
                          ) : (
                            <Icons.InfoIcon />
                          )}
                          <DialogTitle
                            as="h3"
                            className="font-medium text-lg text-gray-900"
                          >
                            {heading}
                          </DialogTitle>
                        </div>
                        {closable && (
                          <button onClick={onClose}>
                            <Icons.ModelCloseIcon />
                          </button>
                        )}
                      </div>
                      <p className="text-sm font-normal text-gray-500 mt-2 mb-4 whitespace-normal">
                        {messageContent}
                      </p>
                    </div>
                    <div className="flex justify-end gap-2 mt-4">
                      <Button
                        color="tertiary"
                        variant="outline"
                        onClick={onClose}
                      >
                        {cancelText}
                      </Button>
                      {modalType === "delete" ? (
                        <Button color="secondary" onClick={handleClick}>
                          {deleteText}
                        </Button>
                      ) : (
                        <Button color="primary" onClick={handleClick}>
                          {proceedText}
                        </Button>
                      )}
                    </div>
                  </>
                )}
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
