import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { BsMeta } from "react-icons/bs";
import { FaMoneyCheckDollar } from "react-icons/fa6";
import { FcGoogle } from "react-icons/fc";
import { SiIndeed } from "react-icons/si";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";

import Icons from "../../assets/icons/Icons";
import ActiveCampaignsBudget from "../../components/ActiveCampaign/ActiveCampaign";
import Breadcrumb from "../../components/Breadcrumb";
import BudgetDistributionCard from "../../components/BudgetDistributionCard/BudgetDistributionCard";
import BudgetSpModal from "../../components/BudgetSpModal/BudgetSpModal";
import ButtonGroup from "../../components/ButtonGroup/ButtonGroup";
import CloneMonthsModal from "../../components/CloneMonthsModal/CloneMonthsModal";
import CurrencyDisplay from "../../components/CurrencyDisplay/CurrencyDisplay";
import CurrrencyInput from "../../components/CurrencyInput/CurrrencyInput";
import GeneralAgreements from "../../components/GeneralAgreements/GeneralAgreements";
import Spinner from "../../components/Spinner/Spinner";
import Tooltip from "../../components/Tooltip/Tooltip";
import ToolTipNew from "../../components/ToolTipNew/ToolTipNew";
import YearChange from "../../components/YearChange/YearChange";
import {
  getActiveClientID,
  getClientMonthlyBudget,
  getPlanByYearAndClientId,
  MONTH_NAMES,
  updateClientBudgetPlan,
  updatePlannedJobBoard,
  getPlannedJoBoards,
  spIsHigherThanFCWarning,
  updateClientRestBudgetDistribution,
  changeaddgeneralagreement,
  changeupdatebudgetdistribution,
  changeupdateclientSPamounts,
} from "../../services/budget";
import {
  displayErrorSnackbar,
  displaySuccessSnackbar,
  getPointValue,
  invalidInputMessage,
} from "../../services/common";

import styles from "./budgetPlan.module.css";

const initialBudgetState = {
  budgetAgreement: 0,
  plThisMonth: 0,
  restBudget: 0,
  googleBudget: 0,
  facebookBudget: 0,
  linkedinBudget: 0,
  indeedbudget: 0,
  boardsBudget: 0,
  actionBudget: 0,
};

function BudgetPlan() {
  const { t } = useTranslation();
  const params = useParams<any>();
  const clientId = params?.id;
  const queryClient = useQueryClient();
  const getSelectedMonth = (clientId: any) => {
    return localStorage.getItem(`selectedMonth_${clientId}`);
  };
  const [currentPeriod, setCurrentPeriod] = useState<{
    month: any;
    year: number;
  }>(() => {
    const savedMonth = getSelectedMonth(clientId);
    return {
      month: savedMonth ? parseInt(savedMonth, 10) : 0,
      year: new Date().getFullYear(),
    };
  });

  const BoardsBudgetWarningMessage =
    "Sum of this row should be less than or Equal to the Budget Agreement";
  const [isChangeInClientSp, setIsChangeInClientSp] = useState(false);
  const RestBudgetWarningMessage =
    "Sum of this row should be less than the RestBudget of this month";

  const [cloneMonth, setCloneMonth] = useState<number>(0);

  const [selectedClient, setSelectedClient] = useState<any>(null);
  const [yearlyBudget, setYearlyBudget] = useState<any>([]);
  const [maxAllowed, setMaxAllowed] = useState<number>(0);
  const [fcWithOverspend, setFcWithOverspend] = useState<number>(0);
  const [spBudgetGreater, setSpBudgetGreater] = useState<boolean>(false);
  const [limitCheck, setLimitCheck] = useState<any>({
    error: false,
    message: "Increase the total budget to accomodate budget distribution",
  });

  const [restbudgetlimitCheck, setRestbudgetlimitCheck] = useState<any>({
    error: false,
    message: "Increase the rest budget to accomodate rest budget distribution",
  });

  const [budget, setBudget] = useState<any>();
  const [activeCampaigns, setActiveCampaigns] = useState<any>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [fcLimitError, setFcLimitError] = useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isBlocking, setIsBlocking] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [totalSp, setTotalSp] = useState<number>(0);
  const [totalPl, setTotalPl] = useState<number>(0);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [totalPlannedBudget, setTotalPlannedBudget] = useState<number>(0);
  const [isFocused, setIsFocused] = useState(false);
  const [isModified, setIsModified] = useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [spError, setSpError] = useState<boolean>(false);
  const [isTyped, setIsTyped] = useState<boolean>(false);
  const [callPlannedJobBoard, setCallPlannedJobBoard] = useState(false);
  const [openBudgetSpWarningModal, setOpenBudgetSpWarningModal] =
    useState<boolean>(false);
  const [actualSpendEnter, setActualSpendEnter] = useState<number>(0);

  const [activeButton, setActiveButton] = useState<boolean | string>(true);

  const { isLoading: jobBoardsLoading, refetch } = useQuery(
    [
      "plannedJobBoards",
      currentPeriod.year,
      currentPeriod.month,
      clientId,
      activeButton,
    ],
    () =>
      getPlannedJoBoards(
        currentPeriod.year,
        currentPeriod.month,
        clientId,
        activeButton,
      ),
    {
      onSettled(data, error) {
        if (!error) {
          setActiveCampaigns(data?.data);
        }
      },
    },
  );

  const [spWarning, setSpWarning] = useState<any>({
    info: "",
    channel: "",
    campaign: "",
  });

  const [spWarningModalCondition, setSpWarningModalCondition] = useState<any>({
    budget: "",
    spbudget: "",
  });

  useEffect(() => {
    setSpBudgetGreater(false);
  }, []);

  useEffect(() => {
    if (currentPeriod.year && currentPeriod.month && clientId) {
      setActiveCampaigns([]);
      refetch();
    }
  }, [currentPeriod.year, currentPeriod.month, clientId, activeButton]);

  useQuery(["activeClientByID"], () => getActiveClientID(params.id), {
    onSuccess(data) {
      const client = data?.data;
      setSelectedClient({
        ...client,
        label: client?.name,
        valaue: client?.id,
      });
    },

    onError() {
      displayErrorSnackbar("Error getting Client");
    },
  });

  const { refetch: fetchClientBudget, data: budgetData } = useQuery(
    ["clientBudget", currentPeriod?.year, currentPeriod?.month, params?.id],
    () => {
      return (
        currentPeriod?.year &&
        getClientMonthlyBudget(
          currentPeriod?.year,
          currentPeriod?.month,
          params?.id,
        )
      );
    },
    {
      enabled: false,
      onSuccess(data) {
        setBudget(data?.data || initialBudgetState);
        setLoading(false);
      },
    },
  );

  const {
    refetch: fetchClientYearlyBudget,
    isLoading: loadingClientYearlyBudget,
    data: yearlyBudgetData,
  } = useQuery(
    ["yearlyBudget", currentPeriod.year, params?.id],
    () =>
      currentPeriod.year &&
      selectedClient?.id &&
      getPlanByYearAndClientId(params?.id, currentPeriod.year),
    {
      enabled: false,
      onSuccess(data) {
        setYearlyBudget(data?.data);
      },
    },
  );

  const { mutate: updateClientBudget } = useMutation(
    (data: any) => updateClientBudgetPlan(data),
    {
      onSuccess() {
        displaySuccessSnackbar("Client budget updated");
        fetchClientBudget();
        fetchClientYearlyBudget();
        setIsBlocking(false);
        refetch();
      },
    },
  );

  const { mutate: updateClientRestBudgetDistributionMutate } = useMutation(
    (data: any) => updateClientRestBudgetDistribution(data),
    {
      onSuccess() {
        displaySuccessSnackbar("Client rest budget distribution is updated");
        fetchClientBudget();
        fetchClientYearlyBudget();
        setIsBlocking(false);
        refetch();
      },
    },
  );

  const { mutate: ChangeUpdateClientBudgetDistribution } = useMutation(
    (data: any) => changeupdatebudgetdistribution(data.id, data),
    {
      onSuccess() {
        displaySuccessSnackbar("Client Budget Distribution is updated");
        fetchClientBudget();
        fetchClientYearlyBudget();
        setIsBlocking(false);
        refetch();
      },
    },
  );

  const { mutate: ChangeUpdateClientSPAmounts } = useMutation(
    (data: any) => changeupdateclientSPamounts(data.id, data),
    {
      onSuccess() {
        displaySuccessSnackbar("Client Spend Amount is updated");
        fetchClientBudget();
        fetchClientYearlyBudget();
        setIsBlocking(false);
        refetch();
      },
    },
  );

  const { mutate: clientupdategeneralagreement } = useMutation(
    (data: any) =>
      changeaddgeneralagreement(
        data.budgetPlanningId,
        data.GeneralAgreement,
        currentPeriod.year,
        currentPeriod.month,
        clientId,
      ),
    {
      onSuccess() {
        displaySuccessSnackbar("Client General Agreement is updated");
        fetchClientBudget();
        fetchClientYearlyBudget();
        setIsBlocking(false);
        refetch();
      },
    },
  );

  const { mutate } = useMutation((data: any) => updatePlannedJobBoard(data), {
    onSuccess() {
      queryClient?.refetchQueries([
        "plannedJobBoards",
        currentPeriod.year,
        currentPeriod.month,
        selectedClient?.id,
      ]);
      fetchClientBudget();
      fetchClientYearlyBudget();
      setIsBlocking(false);
      refetch();
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onError(error) {
      displayErrorSnackbar(
        "Failed to update planned job board. Please try again.",
      );
      fetchClientBudget();
      fetchClientYearlyBudget();
      setIsBlocking(false);
      refetch();
    },
  });

  const { mutate: sendSpBudgetWarning } = useMutation(
    () =>
      spIsHigherThanFCWarning(
        spWarning?.info,
        clientId,
        spWarning?.channel,
        currentPeriod?.month,
        currentPeriod?.year,
        spWarning?.campaign,
      ),
    {
      onSuccess(data: any) {
        displaySuccessSnackbar(data?.data?.message);
        setSpWarning({
          info: "",
          clientId: clientId,
          channel: "",
          month: "",
          year: "",
          campaign: "",
        });
        setSpWarningModalCondition({
          budget: "",
          spbudget: "",
        });
        setOpenBudgetSpWarningModal(false);
        setActualSpendEnter(0);
      },
    },
  );

  const saveSelectedMonth = (clientId: any, month: any) => {
    localStorage.setItem(`selectedMonth_${clientId}`, month);
  };

  useEffect(() => {
    const year = new Date().getFullYear();
    const savedMonth = localStorage.getItem(`selectedMonth_${clientId}`);
    const month = savedMonth
      ? parseInt(savedMonth, 10)
      : new Date().getMonth() + 1;

    setCurrentPeriod({ month, year });
  }, [clientId]);

  useEffect(() => {
    if (localStorage.getItem(`selectedMonth_${clientId}`) === "0")
      localStorage.setItem(`selectedMonth_${clientId}`, "1");
  }, []);

  useEffect(() => {
    if (selectedClient?.id) {
      fetchClientBudget();
    }
  }, [selectedClient?.id]);

  useEffect(() => {
    if (selectedClient?.id && currentPeriod.year) {
      fetchClientYearlyBudget();
    }
  }, [selectedClient?.id, currentPeriod.year]);

  useEffect(() => {
    if (currentPeriod && selectedClient?.id) {
      fetchClientBudget();
    }
  }, [currentPeriod, selectedClient?.id]);

  useEffect(() => {
    if (
      parseFloat(budget?.googleBudget || 0) +
        parseFloat(budget?.facebookBudget || 0) +
        parseFloat(budget?.linkedinBudget || 0) +
        parseFloat(budget?.indeedbudget || 0) +
        parseFloat(budget?.boardsBudget || 0) +
        parseFloat(budget?.displayBudget || 0) >
      parseFloat(budget?.budgetAgreement || 0)
    ) {
      setLimitCheck({
        ...limitCheck,
        error: true,
      });
    } else {
      setLimitCheck({
        ...limitCheck,
        error: false,
      });
    }
  }, [
    budget?.googleBudget,
    budget?.facebookBudget,
    budget?.linkedinBudget,
    budget?.indeedbudget,
    budget?.boardsBudget,
    budget?.budgetAgreement,
    budget?.actionBudget,
    budget?.displayBudget,
  ]);

  useEffect(() => {
    const googleRest =
      budget?.googleRest !== null && budget?.googleRest !== ""
        ? parseFloat(budget?.googleRest)
        : 0;

    const linkedinRest =
      budget?.linkedinRest !== null && budget?.linkedinRest !== ""
        ? parseFloat(budget?.linkedinRest)
        : 0;

    const facebookRest =
      budget?.facebookRest !== null && budget?.facebookRest !== ""
        ? parseFloat(budget?.facebookRest)
        : 0;

    const indeedRest =
      budget?.indeedRest !== null && budget?.indeedRest !== ""
        ? parseFloat(budget?.indeedRest)
        : 0;

    const boardsRest =
      budget?.boardsRest !== null && budget?.boardsRest !== ""
        ? parseFloat(budget?.boardsRest)
        : 0;

    const displayRest =
      budget?.displayRest !== null && budget?.displayRest !== ""
        ? parseFloat(budget?.displayRest)
        : 0;

    const total =
      googleRest +
      linkedinRest +
      facebookRest +
      indeedRest +
      boardsRest +
      displayRest;

    if (total > parseFloat(budget?.restBudget) || 0) {
      setRestbudgetlimitCheck({
        ...restbudgetlimitCheck,
        error: true,
      });
    } else {
      setRestbudgetlimitCheck({
        ...restbudgetlimitCheck,
        error: false,
      });
    }
  }, [
    budget?.googleRest,
    budget?.linkedinRest,
    budget?.facebookRest,
    budget?.indeedRest,
    budget?.boardsRest,
    budget?.displayRest,
  ]);

  useEffect(() => {
    const boardsBudget = parseFloat(budget?.boardsBudget || 0);
    const boardsRest = parseFloat(budget?.boardsRest || 0);
    const overspend = parseFloat(selectedClient?.percentage || 0);
    const overSpendAmount = selectedClient?.overspendAllowed
      ? (boardsBudget * overspend) / 100
      : 0;
    const boardsAction = parseFloat(budget?.boardsAction || 0);
    setMaxAllowed(boardsBudget + boardsAction + overSpendAmount + boardsRest);
  }, [
    budget?.boardsBudget,
    budget?.boardsAction,
    selectedClient,
    budget?.fc,
    budget?.boardsRest,
  ]);

  useEffect(() => {
    const fc = parseFloat(budget?.fc || 0);
    setFcWithOverspend(fc);
  }, [selectedClient, budget?.fc]);

  useEffect(() => {
    setIsModified(false);
  }, []);

  const handleChangeBudgetYear = (direction: string) => {
    setCurrentPeriod({
      month:
        currentPeriod.year + 1 === new Date().getFullYear() ||
        currentPeriod.year - 1 === new Date().getFullYear()
          ? new Date().getMonth() + 1
          : currentPeriod.month,
      year:
        direction === "next" ? currentPeriod.year + 1 : currentPeriod.year - 1,
    });
  };

  useEffect(() => {
    const googleBudget =
      budget?.googleBudget !== null && budget?.googleBudget !== ""
        ? parseFloat(budget?.googleBudget)
        : 0;

    const linkedinBudget =
      budget?.linkedinBudget !== null && budget?.linkedinBudget !== ""
        ? parseFloat(budget?.linkedinBudget)
        : 0;

    const facebookBudget =
      budget?.facebookBudget !== null && budget?.facebookBudget !== ""
        ? parseFloat(budget?.facebookBudget)
        : 0;

    const indeedbudget =
      budget?.indeedbudget !== null && budget?.indeedbudget !== ""
        ? parseFloat(budget?.indeedbudget)
        : 0;

    const displayBudget =
      budget?.displayBudget !== null && budget?.displayBudget !== ""
        ? parseFloat(budget?.displayBudget)
        : 0;

    const total =
      totalPl +
      (isNaN(googleBudget) ? 0 : googleBudget) +
      (isNaN(linkedinBudget) ? 0 : linkedinBudget) +
      (isNaN(facebookBudget) ? 0 : facebookBudget) +
      (isNaN(indeedbudget) ? 0 : indeedbudget) +
      (isNaN(displayBudget) ? 0 : displayBudget);

    setTotalPlannedBudget(total);
  }, [
    budget?.googleBudget,
    budget?.facebookBudget,
    budget?.linkedinBudget,
    budget?.indeedbudget,
    budget?.displayBudget,
    totalPl,
  ]);

  const handleBudgetChange = (e: any) => {
    setBudget({
      ...budget,
      [`${e.target.name}`]: e.target.value,
    });
    setIsBlocking(true);
    setIsTyped(true);
  };

  const handleCancelCall = () => {
    fetchClientBudget();
    fetchClientYearlyBudget();
    setIsBlocking(false);
    refetch();
    setSpWarning({
      info: "",
      channel: "",
      campaign: "",
    });
    setSpWarningModalCondition({
      budget: "",
      spbudget: "",
    });
  };

  const handleSubmitCall = () => {
    sendSpBudgetWarning();
  };

  useEffect(() => {
    setIsChangeInClientSp(true);
  }, [
    budget?.googleSP,
    budget?.linkedinSP,
    budget?.facebookSP,
    budget?.indeedSP,
    budget?.displayBudgetSP,
  ]);

  const validateClientBudgetDistributionSp = (name: string) => {
    if (name === "googleSP" && budget?.googleSP > budget?.googleBudget) {
      setSpWarning({ ...spWarning, channel: "Google" });
      setOpenBudgetSpWarningModal(true);
      setActualSpendEnter(budget?.googleSP);
    } else if (
      name === "linkedinSP" &&
      budget?.linkedinSP > budget?.linkedinBudget
    ) {
      setSpWarning({ ...spWarning, channel: "Linkedin" });
      setOpenBudgetSpWarningModal(true);
      setActualSpendEnter(budget?.linkedinSP);
      return;
    } else if (
      name === "facebookSP" &&
      budget?.facebookSP > budget?.facebookBudget
    ) {
      setSpWarning({ ...spWarning, channel: "Meta" });
      setOpenBudgetSpWarningModal(true);
      setActualSpendEnter(budget?.facebookSP);
      return;
    } else if (name === "indeedSP" && budget?.indeedSP > budget?.indeedbudget) {
      setSpWarning({ ...spWarning, channel: "Indeed" });
      setOpenBudgetSpWarningModal(true);
      setActualSpendEnter(budget?.indeedSP);
      return;
    } else if (
      name === "displayBudgetSP" &&
      budget?.displayBudgetSP > budget?.displayBudget
    ) {
      setSpWarning({ ...spWarning, channel: "Display Budget" });
      setOpenBudgetSpWarningModal(true);
      setActualSpendEnter(budget?.displayBudgetSP);
      return;
    }
  };

  const handleUpdateClientRestBudgetDistribution = (
    e?: any,
    checkRestBudgetLimit?: boolean,
  ) => {
    setIsModified(false);
    setIsTyped(false);
    if (checkRestBudgetLimit && restbudgetlimitCheck?.error) {
      displayErrorSnackbar(restbudgetlimitCheck?.message);
      fetchClientBudget();
      fetchClientYearlyBudget();
      setIsBlocking(false);
      refetch();
      return;
    }
    const payload = {
      budgetPlanningId: budget?.id,
      googleRest: budget?.googleRest,
      facebookRest: budget?.facebookRest,
      linkedinRest: budget?.linkedinRest,
      indeedRest: budget?.indeedRest,
      boardsRest: budget?.boardsRest,
      displayRest: budget?.displayRest,
    };
    updateClientRestBudgetDistributionMutate(payload);
    setIsChangeInClientSp(false);
  };

  const handleUpdateClientGenerlAgreement = () => {
    setIsModified(false);
    setIsTyped(false);
    const payload = {
      budgetPlanningId: budget?.id,
      GeneralAgreement: budget?.budgetAgreement,
    };
    clientupdategeneralagreement(payload);
    setIsChangeInClientSp(false);
  };

  const handleUpdateClientBudgetDistribution = () => {
    setIsModified(false);
    setIsTyped(false);
    const payload = {
      id: budget?.id,
      plThisMonth: budget?.plThisMonth,
      googleBudget: budget?.googleBudget,
      facebookBudget: budget?.facebookBudget,
      linkedinBudget: budget?.linkedinBudget,
      indeedbudget: budget?.indeedbudget,
      boardsBudget: budget?.boardsBudget,
      displayBudget: budget?.displayBudget,
      googleAction: budget?.googleAction,
      facebookAction: budget?.facebookAction,
      linkedinAction: budget?.linkedinAction,
      indeedAction: budget?.indeedAction,
      boardsAction: budget?.boardsAction,
      displayAction: budget?.displayAction,
      fc: budget?.fc,
    };
    ChangeUpdateClientBudgetDistribution(payload);
    setIsChangeInClientSp(false);
  };

  const handleUpdateClientSPAmounts = () => {
    setIsModified(false);
    setIsTyped(false);
    const payload = {
      id: budget?.id,
      googleSP: budget?.googleSP === undefined ? null : budget?.googleSP,
      facebookSP: budget?.facebookSP === undefined ? null : budget?.facebookSP,
      linkedinSP: budget?.linkedinSP === undefined ? null : budget?.linkedinSP,
      indeedSP: budget?.indeedSP === undefined ? null : budget?.indeedSP,
      // boardsSP: totalSp,
      boardsSP: budget?.sp,
      displayBudgetSP:
        budget?.displayBudgetSP === undefined ? null : budget?.displayBudgetSP,
    };
    ChangeUpdateClientSPAmounts(payload);
    setIsChangeInClientSp(false);
  };

  const handleUpdateClientBudget = (
    e?: any,
    checkRestBudgetLimit?: boolean,
  ) => {
    const name = e?.target?.name;
    setIsModified(false);
    setIsTyped(false);
    setCallPlannedJobBoard(!callPlannedJobBoard);

    if (spBudgetGreater) {
      displayErrorSnackbar("SP value can not be greater then budget value");
      return;
    }
    validateBudgetData();

    if (checkRestBudgetLimit && restbudgetlimitCheck?.error) {
      displayErrorSnackbar(restbudgetlimitCheck?.message);
      fetchClientBudget();
      fetchClientYearlyBudget();
      setIsBlocking(false);
      refetch();
      return;
    }

    const {
      valid: activeCampaignsValid,
      message: activeCampaignsValidateError,
    } = validateBoardsBudgetData();

    if (!activeCampaignsValid) {
      displayErrorSnackbar(activeCampaignsValidateError);
      return;
    }

    if (isChangeInClientSp === true) {
      validateClientBudgetDistributionSp(name);
    }
    if (
      parseFloat(spWarningModalCondition.spbudget) >
      parseFloat(spWarningModalCondition.budget)
    ) {
      setOpenBudgetSpWarningModal(true);
      setActualSpendEnter(spWarningModalCondition.spbudget);
    }

    mutate(activeCampaigns);
    updateClientBudget({
      ...budget,
      month: currentPeriod?.month,
      year: currentPeriod?.year,
      clientId: params?.id,
      // sp: totalSp,
      // pl: totalPl,
      // plThisMonth: totalPlannedBudget,
      sp: budget?.sp,
      pl: budget?.pl,
      plThisMonth: budget?.plThisMonth,
    });
    setIsChangeInClientSp(false);
  };

  const validateBudgetData = () => {
    if (limitCheck?.error) {
      return invalidInputMessage(
        "Increase total budget to accomodate budget distribution",
      );
    }
    if (restbudgetlimitCheck?.error) {
      return invalidInputMessage(
        "Increase rest budget to accomodate rest budget distribution",
      );
    }
    if (parseFloat(budget?.fc || 0) > maxAllowed) {
      return invalidInputMessage(
        "FC value cannot be greater than Maximum FC value",
      );
    }
    return { valid: true, message: "" };
  };

  const validateBoardsBudgetData = () => {
    for (const board of activeCampaigns) {
      const campaignsSum = board?.campaigns?.reduce(
        (sum: number, campaign: any) => {
          const current = parseFloat(campaign?.["budget"]) || 0;
          return sum + current;
        },
        0,
      );
      if (campaignsSum > parseFloat(board?.fc || 0)) {
        fetchClientBudget();
        fetchClientYearlyBudget();
        setIsBlocking(false);
        refetch();
        return invalidInputMessage(
          "Sum of campaigns budget in jobboard must not be greater than its FC value",
        );
      }
    }
    return { valid: true, message: "" };
  };

  const budgetColumns = [
    { label: "month", accessor: (col: any) => col?.month, accessKey: "month" },
    {
      label: t("FC"),
      accessor: (col: any) => col?.fc,
      accessKey: "fc",
      type: "input",
    },
    { label: t("PL"), accessor: (col: any) => col?.pl, accessKey: "pl" },

    { label: t("Rest"), accessor: (col: any) => col?.rest, accessKey: "rest" },
  ];

  const handleChangeDate = (month: any) => {
    setLoading(true);
    setCurrentPeriod({ month, year: currentPeriod?.year });
    setIsModified(false);
    saveSelectedMonth(clientId, month);
  };

  return (
    <div className=" w-full">
      <div className=" bg-[#1F1F1F] w-full pl-5">
        <div className=" ml-8">
          <Breadcrumb
            navigation={[
              {
                text: "Client Dashboard",
                url: `/client/dashboard/${params.id}`,
              },
            ]}
            currentPage={{
              text: "Budget Plan",
              url: `/budget-planning/${params.id}`,
            }}
          />
        </div>
        <GeneralAgreements
          handleBudgetChange={handleBudgetChange}
          handleUpdateClientGenerlAgreement={handleUpdateClientGenerlAgreement}
          isTyped={isTyped}
          budget={budget}
        />

        <div className=" mt-5 pl-8 pr-6 pt-4">
          <label className=" text-white text-sm sm:text-lg  font-semibold text-left">
            {t("Budget Distribution")}
          </label>

          <div className="heloo">
            <div className=" flex flex-wrap gap-2 mt-6">
              {/**
               * First Row Start
               *
               */}
              <BudgetDistributionCard
                icon={<Icons.GoogleIcon />}
                error={limitCheck?.error}
                toolTipMessage={BoardsBudgetWarningMessage}
                handleChange={handleBudgetChange}
                isTyped={isTyped}
                saveChanges={handleUpdateClientBudgetDistribution}
                title="Google"
                name={"googleBudget"}
                value={budget?.googleBudget}
                rowNumber={1}
              />
              <BudgetDistributionCard
                icon={<Icons.LinkedInIcon />}
                error={limitCheck?.error}
                toolTipMessage={BoardsBudgetWarningMessage}
                handleChange={handleBudgetChange}
                isTyped={isTyped}
                saveChanges={handleUpdateClientBudgetDistribution}
                title="Linkedin"
                name={"linkedinBudget"}
                value={budget?.linkedinBudget}
                rowNumber={1}
              />

              <BudgetDistributionCard
                icon={<Icons.MetaIcon />}
                error={limitCheck?.error}
                toolTipMessage={BoardsBudgetWarningMessage}
                handleChange={handleBudgetChange}
                isTyped={isTyped}
                saveChanges={handleUpdateClientBudgetDistribution}
                title="Meta"
                name={"facebookBudget"}
                value={budget?.facebookBudget}
                rowNumber={1}
              />

              <BudgetDistributionCard
                icon={<SiIndeed size={25} color="#245ED2" />}
                error={limitCheck?.error}
                toolTipMessage={BoardsBudgetWarningMessage}
                handleChange={handleBudgetChange}
                isTyped={isTyped}
                saveChanges={handleUpdateClientBudgetDistribution}
                title="Indeed"
                name={"indeedbudget"}
                value={budget?.indeedbudget}
                rowNumber={1}
              />

              <BudgetDistributionCard
                icon={<FaMoneyCheckDollar size={25} color="#245ED2" />}
                error={limitCheck?.error}
                toolTipMessage={BoardsBudgetWarningMessage}
                handleChange={handleBudgetChange}
                isTyped={isTyped}
                saveChanges={handleUpdateClientBudgetDistribution}
                title="Boards"
                name={"boardsBudget"}
                value={budget?.boardsBudget}
                rowNumber={1}
              />

              <BudgetDistributionCard
                icon={<Icons.BudgetPlanningIcon />}
                error={limitCheck?.error}
                toolTipMessage={BoardsBudgetWarningMessage}
                handleChange={handleBudgetChange}
                isTyped={isTyped}
                saveChanges={handleUpdateClientBudgetDistribution}
                title=" Display Budget"
                name={"displayBudget"}
                value={budget?.displayBudget}
                rowNumber={1}
              />
              {/**
               * First Row Ends
               *
               */}
            </div>

            <div className=" flex flex-wrap gap-2 mt-6">
              {/**
               * Second Row Start
               *
               */}
              <BudgetDistributionCard
                icon={<Icons.GoogleIcon />}
                handleChange={handleBudgetChange}
                isTyped={isTyped}
                saveChanges={handleUpdateClientBudgetDistribution}
                title="Google Action Budget"
                name={"googleAction"}
                value={budget?.googleAction}
                rowNumber={2}
              />

              <BudgetDistributionCard
                icon={<Icons.LinkedInIcon />}
                handleChange={handleBudgetChange}
                isTyped={isTyped}
                saveChanges={handleUpdateClientBudgetDistribution}
                title="Linkedin Action Budget"
                name={"linkedinAction"}
                value={budget?.linkedinAction}
                rowNumber={2}
              />

              <BudgetDistributionCard
                icon={<Icons.MetaIcon />}
                handleChange={handleBudgetChange}
                isTyped={isTyped}
                saveChanges={handleUpdateClientBudgetDistribution}
                title="Meta Action Budget"
                name={"facebookAction"}
                value={budget?.facebookAction}
                rowNumber={2}
              />

              <BudgetDistributionCard
                icon={<SiIndeed size={25} color="#245ED2" />}
                handleChange={handleBudgetChange}
                isTyped={isTyped}
                saveChanges={handleUpdateClientBudgetDistribution}
                title="Indeed Action Budget"
                name={"indeedAction"}
                value={budget?.indeedAction}
                rowNumber={2}
              />

              <BudgetDistributionCard
                icon={<FaMoneyCheckDollar size={25} color="#245ED2" />}
                handleChange={handleBudgetChange}
                isTyped={isTyped}
                saveChanges={handleUpdateClientBudgetDistribution}
                title="Boards Action Budget"
                name={"boardsAction"}
                value={budget?.boardsAction}
                rowNumber={2}
              />

              <BudgetDistributionCard
                icon={<Icons.BudgetPlanningIcon />}
                handleChange={handleBudgetChange}
                isTyped={isTyped}
                saveChanges={handleUpdateClientBudgetDistribution}
                title="Display Action Budget"
                name={"displayAction"}
                value={budget?.displayAction}
                rowNumber={2}
              />
              {/**
               * Second Row End
               *
               */}
            </div>
            <div className=" flex flex-wrap gap-2 mt-6">
              {/**
               * 3rd Row Start
               *
               */}

              <BudgetDistributionCard
                icon={<Icons.GoogleIcon />}
                handleChange={handleBudgetChange}
                isTyped={isTyped}
                saveChanges={handleUpdateClientSPAmounts}
                title="Google SP"
                name={"googleSP"}
                value={budget?.googleSP}
                rowNumber={3}
              />

              <BudgetDistributionCard
                icon={<Icons.LinkedInIcon />}
                handleChange={handleBudgetChange}
                isTyped={isTyped}
                saveChanges={handleUpdateClientSPAmounts}
                title="Linkedin SP"
                name={"linkedinSP"}
                value={budget?.linkedinSP}
                rowNumber={3}
              />

              <BudgetDistributionCard
                icon={<Icons.MetaIcon />}
                handleChange={handleBudgetChange}
                isTyped={isTyped}
                saveChanges={handleUpdateClientSPAmounts}
                title="Meta SP"
                name={"facebookSP"}
                value={budget?.facebookSP}
                rowNumber={3}
              />

              <BudgetDistributionCard
                icon={<SiIndeed size={25} color="#245ED2" />}
                handleChange={handleBudgetChange}
                isTyped={isTyped}
                saveChanges={handleUpdateClientSPAmounts}
                title="Indeed SP"
                name={"indeedSP"}
                value={budget?.indeedSP}
                rowNumber={3}
              />

              <BudgetDistributionCard
                icon={<FaMoneyCheckDollar size={25} color="#245ED2" />}
                handleChange={handleBudgetChange}
                isTyped={isTyped}
                saveChanges={handleUpdateClientSPAmounts}
                title="Boards SP"
                name={"indeedSP"}
                value={budget?.sp}
                rowNumber={3}
                disableInput={true}
              />

              <BudgetDistributionCard
                icon={<Icons.BudgetPlanningIcon />}
                handleChange={handleBudgetChange}
                isTyped={isTyped}
                saveChanges={handleUpdateClientSPAmounts}
                title="Display Budget SP"
                name={"displayBudgetSP"}
                value={budget?.displayBudgetSP}
                rowNumber={3}
              />
              {/**
               * 3rd Row End
               *
               */}
            </div>
            <div className=" flex flex-wrap gap-2 mt-6">
              {/**
               * 4th Row Start
               *
               */}

              <div className="flex items-center bg-[#2A2A2A] w-full sm:w-52  h-20 relative group">
                <div className="w-1/3 h-full  flex justify-center items-center">
                  <FcGoogle size={25} />
                </div>
                <div className=" h-full  flex flex-col justify-center">
                  {restbudgetlimitCheck?.error && (
                    <ToolTipNew message={RestBudgetWarningMessage} />
                  )}
                  <CurrrencyInput
                    value={budget?.googleRest || ""}
                    name={"googleRest"}
                    onChange={handleBudgetChange}
                    propStyles={{
                      border: restbudgetlimitCheck?.error
                        ? "1px solid red"
                        : "",
                      color: "white",
                      fontSize: "14px",
                    }}
                    onBlur={(e: any) => {
                      isTyped &&
                        handleUpdateClientRestBudgetDistribution(e, true);
                    }}
                    disabled={
                      parseFloat(budget?.restBudget) === 0 ||
                      budget?.restBudget === null ||
                      budget?.restBudget === "" ||
                      budget?.restBudget === "0" ||
                      parseFloat(budget?.restBudget) < 0
                    }
                  />

                  <p className="text-white text-xs font-normal ml-2 -mt-0 mb-2">
                    Google Rest Budget
                  </p>
                </div>
              </div>

              <div className="flex  items-center bg-[#2A2A2A] w-full sm:w-52  h-20 relative group">
                <div className="w-1/3 h-full flex justify-center items-center">
                  {<Icons.LinkedInIcon />}
                </div>

                <div className="h-full  flex flex-col justify-center">
                  {restbudgetlimitCheck?.error && (
                    <ToolTipNew message={RestBudgetWarningMessage} />
                  )}
                  <CurrrencyInput
                    value={budget?.linkedinRest || ""}
                    name={"linkedinRest"}
                    onChange={handleBudgetChange}
                    propStyles={{
                      border: restbudgetlimitCheck?.error
                        ? "1px solid red"
                        : "",
                      color: "white",
                      fontSize: "14px",
                    }}
                    onBlur={(e: any) => {
                      isTyped &&
                        handleUpdateClientRestBudgetDistribution(e, true);
                    }}
                    disabled={
                      parseFloat(budget?.restBudget) === 0 ||
                      budget?.restBudget === null ||
                      budget?.restBudget === "" ||
                      budget?.restBudget === "0" ||
                      parseFloat(budget?.restBudget) < 0
                    }
                  />
                  <p className="text-white text-xs font-normal ml-2 -mt-0 mb-2">
                    Linkedin Rest Budget
                  </p>
                </div>
              </div>

              <div className="flex  items-center bg-[#2A2A2A] w-full sm:w-52 h-20 relative group">
                <div className="w-1/3 h-full flex justify-center items-center">
                  <BsMeta size={25} color="#245ED2" />
                </div>
                <div className=" h-full  flex flex-col justify-center">
                  {restbudgetlimitCheck?.error && (
                    <ToolTipNew message={RestBudgetWarningMessage} />
                  )}
                  <CurrrencyInput
                    value={budget?.facebookRest || ""}
                    name={"facebookRest"}
                    onChange={handleBudgetChange}
                    propStyles={{
                      border: restbudgetlimitCheck?.error
                        ? "1px solid red"
                        : "",
                      color: "white",
                      fontSize: "14px",
                      gap: "2px",
                    }}
                    onBlur={(e: any) => {
                      isTyped &&
                        handleUpdateClientRestBudgetDistribution(e, true);
                    }}
                    disabled={
                      parseFloat(budget?.restBudget) === 0 ||
                      budget?.restBudget === null ||
                      budget?.restBudget === "" ||
                      budget?.restBudget === "0" ||
                      parseFloat(budget?.restBudget) < 0
                    }
                  />
                  <p className="text-white text-xs font-normal ml-2 -mt-0 mb-2">
                    Meta Rest Budget
                  </p>
                </div>
              </div>

              <div className="flex  items-center bg-[#2A2A2A] w-full sm:w-52  h-20 relative group">
                <div className="w-1/3 h-full flex justify-center items-center">
                  <SiIndeed size={25} color="#245ED2" />
                </div>
                <div className=" h-full  flex flex-col justify-center">
                  {restbudgetlimitCheck?.error && (
                    <ToolTipNew message={RestBudgetWarningMessage} />
                  )}
                  <CurrrencyInput
                    value={budget?.indeedRest || ""}
                    name={"indeedRest"}
                    onChange={handleBudgetChange}
                    propStyles={{
                      border: restbudgetlimitCheck?.error
                        ? "1px solid red"
                        : "",
                      color: "white",
                      fontSize: "14px",
                      gap: "2px",
                    }}
                    onBlur={(e: any) => {
                      isTyped &&
                        handleUpdateClientRestBudgetDistribution(e, true);
                    }}
                    disabled={
                      parseFloat(budget?.restBudget) === 0 ||
                      budget?.restBudget === null ||
                      budget?.restBudget === "" ||
                      budget?.restBudget === "0" ||
                      parseFloat(budget?.restBudget) < 0
                    }
                  />
                  <p className="text-white text-xs font-normal ml-2 -mt-0 mb-2">
                    Indeed Rest Budget
                  </p>
                </div>
              </div>

              <div className="flex  items-center bg-[#2A2A2A] w-full sm:w-52  h-20 relative group">
                <div className="w-1/3 h-full  flex justify-center items-center">
                  <FaMoneyCheckDollar size={25} color="#245ED2" />
                </div>
                <div className=" h-full  flex flex-col justify-center">
                  {restbudgetlimitCheck?.error && (
                    <ToolTipNew message={RestBudgetWarningMessage} />
                  )}
                  <CurrrencyInput
                    value={budget?.boardsRest || ""}
                    name={"boardsRest"}
                    onChange={handleBudgetChange}
                    propStyles={{
                      border: restbudgetlimitCheck?.error
                        ? "1px solid red"
                        : "",
                      color: "white",
                      fontSize: "14px",
                      gap: "2px",
                    }}
                    onBlur={(e: any) => {
                      isTyped &&
                        handleUpdateClientRestBudgetDistribution(e, true);
                    }}
                    disabled={
                      parseFloat(budget?.restBudget) === 0 ||
                      budget?.restBudget === null ||
                      budget?.restBudget === "" ||
                      budget?.restBudget === "0" ||
                      parseFloat(budget?.restBudget) < 0
                    }
                  />
                  <p className="text-white text-xs font-normal ml-2 -mt-0 mb-2">
                    Boards Rest Budget
                  </p>
                </div>
              </div>

              <div className="flex  items-center bg-[#2A2A2A] w-full sm:w-52  h-20 relative group">
                <div className="w-1/3 h-full flex justify-center items-center">
                  {<Icons.BudgetPlanningIcon />}
                </div>
                <div className=" h-full  flex flex-col justify-center">
                  {restbudgetlimitCheck?.error && (
                    <ToolTipNew message={RestBudgetWarningMessage} />
                  )}
                  <CurrrencyInput
                    value={budget?.displayRest || ""}
                    name={"displayRest"}
                    onChange={handleBudgetChange}
                    propStyles={{
                      border: restbudgetlimitCheck?.error
                        ? "1px solid red"
                        : "",
                      color: "white",
                      fontSize: "14px",
                      gap: "2px",
                    }}
                    onBlur={(e: any) => {
                      isTyped &&
                        handleUpdateClientRestBudgetDistribution(e, true);
                    }}
                    disabled={
                      parseFloat(budget?.restBudget) === 0 ||
                      budget?.restBudget === null ||
                      budget?.restBudget === "" ||
                      budget?.restBudget === "0" ||
                      parseFloat(budget?.restBudget) < 0
                    }
                  />
                  <p className="text-white text-xs font-normal ml-2 -mt-0 mb-2">
                    Display Rest Budget
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className=" flex items-center gap-4 mt-5 pl-8 pr-6 pt-4">
          <label className=" text-white text-sm sm:text-lg  font-semibold text-left">
            Board Budget Planning
          </label>
          <YearChange
            handleChangeBudgetYear={handleChangeBudgetYear}
            currentYear={currentPeriod.year}
            theme="black"
          />
        </div>

        {!yearlyBudgetData || loadingClientYearlyBudget || loading ? (
          <div className={styles.spinnerWrapCenter}>
            <Spinner />
          </div>
        ) : (
          <div className=" flex pb-5 mt-3 ml-7 overflow-x-auto w-[95%] board-budget-container">
            <div className={styles.budgetColumns}>
              {budgetColumns?.map((col: any, index: number) => (
                <div
                  key={`${"" + index}`}
                  className={`border border-[#545454] border-collapse bg-[#2E2E2E] text-white text-xs font-medium w-28 h-12 justify-center items-center flex  ${
                    index % 2 === 0 && col?.label !== "month" ? styles.grey : ""
                  }  ${
                    index === 0 &&
                    "bg-[#545454] text-[#C3C7CC] text-xs font-semibold border border-[#545454]"
                  }`}
                >
                  {col?.label !== "month" ? col?.label : "Type"}
                </div>
              ))}
            </div>

            {yearlyBudget?.map((data: any, dataKey: number) => {
              return (
                <div key={`${"" + dataKey}`} className={styles.budgetColumns}>
                  <BudgetColumns
                    budget={budget}
                    budgetColumns={budgetColumns}
                    currentPeriod={currentPeriod}
                    currentYear={currentPeriod.year}
                    data={data}
                    handleChangeDate={handleChangeDate}
                    handleUpdateClientBudget={handleUpdateClientBudget}
                    handleUpdateClientBudgetDistribution={
                      handleUpdateClientBudgetDistribution
                    }
                    isFocused={isFocused}
                    isModified={isModified}
                    isTyped={isTyped}
                    maxAllowed={maxAllowed}
                    setBudget={setBudget}
                    setCloneMonth={setCloneMonth}
                    setIsFocused={setIsFocused}
                    setIsModified={setIsModified}
                    setIsTyped={setIsTyped}
                    key={`{""+index}`}
                  />
                </div>
              );
            })}
          </div>
        )}
      </div>
      <section className="mt-5  p-4 ml-8">
        <div className=" flex items-center justify-between max-w-[78vw] flex-wrap gap-y-3">
          <div className=" flex items-center gap-4">
            <label className=" text-sm sm:text-lg  font-semibold text-left">
              Active Campaigns
            </label>
            <YearChange
              handleChangeBudgetYear={handleChangeBudgetYear}
              currentYear={currentPeriod.year}
              theme="white"
            />
            <div className=" flex items-center border  bg-[#909ea729] rounded-3xl px-10 py-2 gap-2">
              <span className=" text-[#909EA7] text-sm">
                {
                  MONTH_NAMES[
                    currentPeriod.month === 0
                      ? currentPeriod.month + 1
                      : currentPeriod.month
                  ]
                }
              </span>
            </div>
          </div>
          <ButtonGroup
            options={[
              {
                label: "Online",
                value: true,
              },
              {
                label: "Offline",
                value: false,
              },
              {
                label: "All",
                value: "all",
              },
            ]}
            onChange={(value) => setActiveButton(value)}
          />
        </div>
        <ActiveCampaignsBudget
          year={currentPeriod?.year}
          month={currentPeriod?.month}
          clientId={params?.id}
          budgetPlanningId={budgetData?.data?.id}
          totalFc={fcWithOverspend || 0}
          setParentData={(data: any) => setActiveCampaigns(data)}
          setParentFCError={(fcError: boolean) => setFcLimitError(fcError)}
          sendSpState={setTotalSp}
          setParentSPError={(spError: boolean) => setSpError(spError)}
          sendPlState={setTotalPl}
          handleUpdateClientBudget={handleUpdateClientBudget}
          isParentTyped={isTyped}
          setIsParentTyped={setIsTyped}
          callPlannedJobBoard={callPlannedJobBoard}
          activeCampaigns={activeCampaigns}
          setActiveCampaigns={setActiveCampaigns}
          isLoading={jobBoardsLoading}
          setSpWarning={setSpWarning}
          setSpWarningModalCondition={setSpWarningModalCondition}
          activeButton={activeButton}
        />
      </section>

      {!!cloneMonth && (
        <CloneMonthsModal
          cloneParams={{
            year: currentPeriod.year,
            month: cloneMonth,
            clientId: params?.id,
          }}
          isOpen={!!cloneMonth}
          onClose={() => setCloneMonth(0)}
          onCloneComplete={({ year, month }: any) => {
            setCurrentPeriod({ year, month });
            setCloneMonth(0);
            fetchClientBudget();
            fetchClientYearlyBudget();
          }}
        />
      )}

      {openBudgetSpWarningModal && (
        <BudgetSpModal
          isOpen={!!openBudgetSpWarningModal}
          onClose={() => {
            setOpenBudgetSpWarningModal(false);
            setActualSpendEnter(0);
          }}
          handleCancelCall={handleCancelCall}
          handleSubmitCall={handleSubmitCall}
          spWarning={spWarning}
          setSpWarning={setSpWarning}
          actualBudget={actualSpendEnter}
        />
      )}
    </div>
  );
}

export default BudgetPlan;

type BudgetColumnsProps = {
  budgetColumns: any;
  data: any;
  currentPeriod: any;
  isFocused: any;
  budget: any;
  setBudget: any;
  setIsModified: any;
  maxAllowed: any;
  setIsTyped: any;
  setIsFocused: any;
  isTyped: any;
  handleUpdateClientBudget: any;
  handleUpdateClientBudgetDistribution: any;
  currentYear: any;
  setCloneMonth: any;
  isModified: any;
  handleChangeDate: any;
};

const BudgetColumns = (props: Readonly<BudgetColumnsProps>) => {
  const {
    budgetColumns,
    data,
    currentPeriod,
    isFocused,
    budget,
    setBudget,
    setIsModified,
    maxAllowed,
    setIsTyped,
    setIsFocused,
    isTyped,
    handleUpdateClientBudget,
    handleUpdateClientBudgetDistribution,
    currentYear,
    setCloneMonth,
    isModified,
    handleChangeDate,
  } = props;

  return budgetColumns?.map((col: any, index: number) => {
    const date = new Date();
    const currentMonth = date?.getMonth() + 1;
    const year = date?.getFullYear();
    const isEditable =
      data?.month === currentPeriod?.month &&
      data?.year === currentPeriod?.year; // Check if input field is editable based on selected month
    if (col?.type === "input") {
      return (
        <BudgetColumnInput
          budget={budget}
          col={col}
          data={data}
          handleUpdateClientBudget={handleUpdateClientBudget}
          handleUpdateClientBudgetDistribution={
            handleUpdateClientBudgetDistribution
          }
          index={index}
          isEditable={isEditable}
          isFocused={isFocused}
          isTyped={isTyped}
          maxAllowed={maxAllowed}
          setBudget={setBudget}
          setIsFocused={setIsFocused}
          setIsModified={setIsModified}
          setIsTyped={setIsTyped}
          key={`${"" + index}`}
        />
      );
    } else {
      return (
        <BudgetColumnButton
          col={col}
          currentMonth={currentMonth}
          currentPeriod={currentPeriod}
          currentYear={currentYear}
          data={data}
          handleChangeDate={handleChangeDate}
          index={index}
          isModified={isModified}
          setCloneMonth={setCloneMonth}
          year={year}
          key={`${"" + index}`}
        />
      );
    }
  });
};

type BudgetColumnInputProps = {
  isEditable: any;
  isFocused: any;
  index: any;
  col: any;
  budget: any;
  maxAllowed: any;
  setBudget: any;
  setIsModified: any;
  setIsTyped: any;
  setIsFocused: any;
  isTyped: any;
  handleUpdateClientBudget: any;
  handleUpdateClientBudgetDistribution: any;
  data: any;
};

const BudgetColumnInput = (props: Readonly<BudgetColumnInputProps>) => {
  const {
    isEditable,
    isFocused,
    index,
    col,
    budget,
    maxAllowed,
    setBudget,
    setIsModified,
    setIsTyped,
    setIsFocused,
    isTyped,
    handleUpdateClientBudgetDistribution,
    data,
  } = props;

  const getToolText = () => {
    if (getPointValue(budget?.fc) > getPointValue(maxAllowed)) {
      return "FC shold less then Boards Budget";
    } else {
      return "";
    }
  };

  if (isEditable) {
    return (
      <div
        className={`border  border-collapse  text-white text-xs font-medium w-28 h-12 ${
          isFocused && "  border-[#245ED2] bg-[#245ed23d]"
        }  ${!isFocused && " border-[#545454] bg-[#2E2E2E]"}`}
        key={`${"" + index}`}
      >
        <Tooltip
          tooltipText={getToolText()}
          isToolTipEnabled={
            getPointValue(budget?.fc) > getPointValue(maxAllowed)
          }
        >
          <CurrrencyInput
            key={`${"" + index}`}
            name={col?.accessKey}
            value={budget?.fc || ""}
            propStyles={{
              width: "100%",
              height: "100%",
              borderRadius: "0px",
              backgroundColor: "transparent",
              color: "white",
              border:
                getPointValue(budget?.fc) > getPointValue(maxAllowed) &&
                "1.5px solid red",
            }}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setBudget({
                ...budget,
                fc: e?.target?.value,
              });
              setIsModified(true);
              setIsTyped(true);
            }}
            onFocus={() => {
              setIsFocused(true);
            }}
            onBlur={() => {
              setIsFocused(false);
              isTyped && handleUpdateClientBudgetDistribution();
            }}
          />
        </Tooltip>
      </div>
    );
  }
  return (
    <div
      className={`border border-[#545454] border-collapse bg-[#2E2E2E] text-white text-xs font-medium w-28 h-12 `}
      key={`${"" + index}`}
    >
      <CurrrencyInput
        key={`${"" + index}`}
        disabled={true}
        name={col?.accessKey}
        value={col?.accessor(data) || ""}
        propStyles={{
          width: "100%",
          height: "100%",
          color: "white",
        }}
      />
    </div>
  );
};

type BudgetColumnButtonProps = {
  currentMonth: any;
  data: any;
  currentYear: any;
  year: any;
  col: any;
  index: any;
  setCloneMonth: any;
  currentPeriod: any;
  isModified: any;
  handleChangeDate: any;
};

const BudgetColumnButton = (props: Readonly<BudgetColumnButtonProps>) => {
  const {
    currentMonth,
    data,
    currentYear,
    year,
    col,
    index,
    setCloneMonth,
    currentPeriod,
    isModified,
    handleChangeDate,
  } = props;

  let monthCondition = false;

  if (currentMonth === 12) {
    monthCondition =
      (data?.month === currentMonth && data?.year === currentYear) ||
      (data?.month === 1 && data?.year === year + 1);
  } else {
    monthCondition =
      (data?.month === currentMonth &&
        data?.year === currentYear &&
        new Date().getFullYear() === data?.year) ||
      (data?.month === currentMonth + 1 &&
        data?.year === year &&
        new Date().getFullYear() === data?.year);
  }
  const displayCloneBtn =
    col?.accessKey === "pl" && monthCondition && data.id === 0;

  if (displayCloneBtn) {
    return (
      <div
        key={`${"" + index}`}
        className={`${styles.cloneBtnWrap} ${styles.grey}`}
      >
        <button
          onClick={() => {
            setCloneMonth(data?.month);
          }}
          className={`button primaryButton ${styles.cloneBtn}`}
        >
          CLONE
        </button>
      </div>
    );
  }

  return (
    <button
      key={`${"" + index}`}
      className={` border border-[#545454] border-collapse bg-[#2E2E2E] text-white text-xs font-medium w-28 flex justify-center items-center h-12  ${
        col?.accessKey === "month"
          ? "bg-[#545454] text-[#C3C7CC] text-xs  font-semibold border border-[#545454] cursor-pointer"
          : ""
      }  ${index % 2 === 0 && col?.label !== "month" && styles.grey} ${
        data?.month === currentPeriod?.month &&
        data?.year === currentPeriod?.year &&
        col?.accessKey === "month" &&
        styles.main
      }  `}
      onClick={() => {
        if (col?.accessKey === "month") {
          if (isModified) {
            const confirmMessage =
              "You have unsaved changes. Are you sure you want to switch the month?";
            const shouldSwitchMonth = window.confirm(confirmMessage);
            if (shouldSwitchMonth) {
              handleChangeDate(col?.accessor(data));
            }
          } else {
            handleChangeDate(col?.accessor(data));
          }
        }
      }}
    >
      {col?.accessKey !== "month" && (
        <CurrencyDisplay
          value={col?.accessor(data)}
          propStyles={{
            fontSize: "14px",
            fontWeight: "400",
          }}
        />
      )}
      {col?.accessKey === "month" && (
        <span className={` text-[#C3C7CC] ${index === 11 && "text-red-500"}`}>
          {MONTH_NAMES[`${col?.accessor(data)}`]}
        </span>
      )}
    </button>
  );
};
